import { Component } from '@angular/core';

import { BlogsService } from '../blogs/blogs.service';
import { WorkExperiencesService } from './work-experiences.service';
import { Blog } from '../blogs/blog.model';
import { WorkExperience } from './work-experience.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.scss']
})
export class WorkExperienceComponent {

  isLoaded: boolean = false;
  blogsLoaded: boolean = false;
  workExperiencesLoaded: boolean = false;

  statement: string;
  blogs = new Array<Blog>();
  workExperiences = new Array<WorkExperience>();

  constructor(
    private notificationService: NotificationService,
    private blogsService: BlogsService,
    private workExperiencesService: WorkExperiencesService) {
    this.statement = 'These are some of the great companies that I have had the opportunity to work at over the past few years. Some have continued as great businesses and enterprises, while others have faded into history.';

    this.blogsService.getAll().subscribe({
      next: (data) => {
        this.blogs = data;
        this.blogsLoaded = true;
        this.checkLoadStatus();
      },
      error: (_) => {
        this.notificationService.showError('An error occurred loading the latest blogs.', 'Blog Service');
    }});

    this.workExperiencesService.getAll().subscribe({
      next: (data) => {
        this.workExperiences = data;
        this.workExperiencesLoaded = true;
        this.checkLoadStatus();
      },
      error: (_) => {
        this.notificationService.showError('An error occurred loading the latest work references.', 'Work Experience Service');
    }});
  }

  checkLoadStatus() {
    this.isLoaded = this.blogsLoaded && this.workExperiencesLoaded;
  }
}
