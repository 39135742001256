export class ClientSessionInfo {
  id: string = '';
  expiresOn: Date = new Date(Date.now());

  isValid(): boolean {
    return this.id.length !== 0;
  }

  static key(id: string): string {
    return id.toUpperCase().replace(/-/g, '');
  }

  static init(): ClientSessionInfo {
    return new ClientSessionInfo();
  }
}
