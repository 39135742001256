<ng-container *ngIf="userSignedIn()">
  <div class="row">

    <div class="col-md-2">
      <select title="LibrarySelector" id="librarySelector" class="form-select form-control" [(ngModel)]="selectedLibrary"
        (change)="refreshLibrary()">
        <option disabled default [ngValue]="undefined">Select a library</option>
        <option *ngFor="let library of libraries()" [ngValue]="library"><span>[{{library.location}}]
          </span><span>{{library.name}}</span>
        </option>
      </select>
    </div>

    <div class="col-md-1">
      <ng-container *ngIf="!userLibrarySelected()">
        <div class="dropdown">
          <button title="New library" class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <i class="bi bi-bookshelf"></i>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" [routerLink]="" (click)="requestNewUserLibrary()">New library</a></li>
          </ul>
        </div>
      </ng-container>

      <ng-container *ngIf="userLibrarySelected()">
        <div class="dropdown">
          <button title="Library context menu" class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-bookshelf"></i>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" [routerLink]="" (click)="requestNewUserLibrary()">New library</a></li>
            <li><a class="dropdown-item" (click)="requestEditUserLibrary()">Edit library</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" [routerLink]="" (click)="requestDeleteUserLibrary()">Delete library</a></li>
          </ul>
        </div>
      </ng-container>
    </div>

  </div>

</ng-container>

<ng-container *ngIf="userLibrarySelected()">
  <app-user-library></app-user-library>
</ng-container>

<ng-container *ngIf="newUserLibraryRequested()">
  <app-library-creator></app-library-creator>
</ng-container>
