import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";

import { Hobby } from "./hobby.model";

@Injectable()
export class HobbyService {

  hobbies: Observable<Array<Hobby>> | null;
  constructor() {
    this.hobbies = null;
  }

  getAll(): Observable<Array<Hobby>> {
    if (this.hobbies != null) {
      return this.hobbies;
    }

    return of(this.getDefaultHobbies());
  }

  private getDefaultHobbies(): Array<Hobby> {
    let hobbies = new Array<Hobby>();
    hobbies.push(new Hobby('Music Performance', 'One of my most rewarding passions is performing on stage. After a life-long stretch of playing classical piano, I branched out into live performance with the latest keyboard sounds and gear. Not the typical hobby of a software developer, but I know I\'m not alone.'));
    hobbies.push(new Hobby('Keyboard Lessons', 'Given the high standard of living here in Switzerland, the cost of piano lessons even for a young child is quite steep. Despite my reluctance to teach, I now find myself teaching my kids and am constantly getting blown away by their progress. Keep it up guys!'));
    hobbies.push(new Hobby('Sport Activities', 'When not at work, practicing for the next gig, or spending time with my family; I enjoy roller blading, biking and the occasional trip to the Badminton hall.'));

    return hobbies;
  }
}
