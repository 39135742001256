<fieldset class="d-flex flex-column">
  <legend>Update User Library</legend>
  <form [formGroup]="libraryForm" (ngSubmit)="updateLibrary()">
    <div class="row">
      <div class="col-sm-6">
        <label for="location">Location: </label>
        <input class="fixWidthMedium" type="input" placeholder="Location" tabindex="1" formControlName="location"
          autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label for="name">Name: </label>
        <input #userNameInput class="fixWidthMedium" type="input" placeholder="Name" tabindex="1"
          formControlName="name" autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label for="description">Description: </label>
        <textarea class="fixWidthMedium" type="input" cols="120" rows="3" placeholder="Description" tabindex="2"
          formControlName="description" autocomplete="off"></textarea>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-sm-3">
        <button class="btn btn-sm btn-outline-success" aria-label="Save library" type="submit"
          [disabled]="!libraryForm.valid || !libraryForm.dirty" tabindex="3">
          <i class="bi bi-save2"></i>
        </button>
      </div>
      <div class="col-sm-3">
        <button class="btn btn-sm btn-outline-warning" aria-label="Cancel" type="button" (click)="cancelEdit()" tabindex="4">
          <i class="bi bi-x-square-fill"></i>
        </button>
      </div>
    </div>

  </form>
</fieldset>
