import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

import { HttpClientErrorHandler } from './http-client-error-handler.service';
import { UserLibrarySecurityService } from '../../../security/user-library-security.service';

import { RecordIdInfo } from '../../models/record-id-info.model';
import { UpdateRecordIdInfo } from '../../models/record-id-Info-update.model';
import { LibraryUserDto } from '../interfaces/library-user-dto.interface';
import { LibraryUserNumberDto } from '../interfaces/library-user-number-dto.interface';
import {
  LibraryUserRevertedDto,
  NewLibraryUserRevertedDto,
  UpdateLibraryUserRevertedDto
} from '../interfaces/library-user-reverted-dto.interface';

const defaultDtoUser = {} as LibraryUserDto;

@Injectable()
export class LibraryUserHttpClientService {

  constructor(
    private httpClient: HttpClient,
    private httpClientErrorHandlerService: HttpClientErrorHandler,
    private librarySecurityService: UserLibrarySecurityService) {
  }

  getNextUserNumber(csi: string): Observable<number> {
    if (csi.length === 0) return of(0);

    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${csi}` })
    };

    return this.httpClient.get<LibraryUserDto>(`${environment.apiUrl}/Lib/LibraryUsers/Number`, httpOptions).pipe(
      map((userNumber: LibraryUserNumberDto): number => userNumber?.number ?? 0),
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryUser Service', error))
    );
  }

  getLibraryUser(csi: string, userName: string): Observable<LibraryUserDto> {
    if (csi.length === 0 || userName.length === 0) return of(defaultDtoUser);

    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${csi}` })
        .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
    };

    return this.httpClient.get<LibraryUserDto>(`${environment.apiUrl}/Lib/LibraryUsers`, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryUser Service', error))
    );
  }

  addNewUser(userName: string, libraryUser: NewLibraryUserRevertedDto): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
    };

    return this.httpClient.post(`${environment.apiUrl}/Lib/LibraryUsers/New`, libraryUser, httpOptions).pipe(
      map((response: any): any => { return response; }),
      map((userIdInfo: RecordIdInfo): string => { return this.librarySecurityService.DecryptValue(userIdInfo.idInfo); }),
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryUser Service', error))
    );
  }

  resetUser(userName: string, userId: string, libraryUser: LibraryUserRevertedDto): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/LibraryUsers/Reset`, libraryUser, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryUser Service', error))
    );
  }

  updateUser(userId: string, libraryUser: UpdateLibraryUserRevertedDto): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/LibraryUsers/Update`, libraryUser, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryUser Service', error))
    );
  }

  updateUserInfo(userId: string, userIdInfoUpdate: UpdateRecordIdInfo): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/LibraryUsers/Info`, userIdInfoUpdate, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryUser Service', error))
    );
  }

  closeUserAccount(userId: string, userName: string): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/LibraryUsers/CloseAccount`, null, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryUser Service', error))
    );
  }
}
