import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ContactEmailModel } from 'src/app/contact/contact-email.model';
import { HttpClientErrorHandler } from '../library/services/http-client-services/http-client-error-handler.service';
import { UserLibrarySecurityService } from '../security/user-library-security.service';
import { LibraryClientSessionHttpClientService } from '../library/services/http-client-services/library-client-session.http-client-service';
import { ClientSessionInfo } from '../library/models/client-session.model';

@Injectable()
export class ContactMailService {
  private _clientSessionInfo = signal<ClientSessionInfo>(ClientSessionInfo.init());
  public hasClientSessionInfo = signal<boolean>(false);

  constructor(private httpClient: HttpClient,
    libraryClientService: LibraryClientSessionHttpClientService,
    private httpClientErrorHandlerService: HttpClientErrorHandler,
    private librarySecurityService: UserLibrarySecurityService) {
      //Call, for now, once per browser session
      libraryClientService.createClientSession(this.librarySecurityService.CreateClientSessionId()).subscribe({
        next: (sessionInfo: ClientSessionInfo) => {
          this._clientSessionInfo.set(sessionInfo);
          this.librarySecurityService.UpdateClientSessionInfo(sessionInfo);
  
  
          this.hasClientSessionInfo.set(true);
        },
        error: _ => {
          //notificationService.showError('The client session could not be started.\r\nIf this issue persists, contact the web administrator.', 'Fatal Error')
        }
      });
   }

  sendMailRequest(message: ContactEmailModel): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
      .set('sid', `${this.librarySecurityService.GetMailServiceId()}`)
    };

    return this.httpClient.post(`${environment.apiUrl}/Messaging/Send`, message, httpOptions).pipe(
      map(
        (_: any): any => {
        return 'OK';
      }),
      catchError(error => this.httpClientErrorHandlerService.handleError('ContactMail Service', error))
    );
  }
}
