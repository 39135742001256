import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Project } from 'src/app/projects/project.model';

@Injectable()
export class ProjectsService {

  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<Project[]> {
    return this.httpClient.get(`${environment.apiUrl}/Projects`).pipe(
      map((response: any): any => { return response; }),
      map((projects: Project[]): Project[] => {
        projects.sort((a, b) => a.name > b.name ? 1 : -1)
        return projects;
      }),);
  }
}
