import { Component, EventEmitter, Output, WritableSignal, computed } from '@angular/core';

import { LibraryService } from '../services/library.service';
import { UserLibrary } from '../models/user-library.model';

@Component({
  selector: 'app-user-library-control',
  templateUrl: './user-library-control.component.html',
  styleUrls: ['./user-library-control.component.scss']
})
export class UserLibraryControlComponent {

  constructor(private libraryService: LibraryService) {
    this.userLibrary = this.libraryService.selectedUserLibrary;
    this.isLibrarySelected = this.libraryService.userLibrarySelected;
    this.bookGenres = this.libraryService.selectedLibraryGenres;
    this.newLibraryBookRequested = this.libraryService.newLibraryBookRequested;

    this.libraryIsDefined = computed(() => this.userLibrary().isValid())();
    this.isFiltered = this.libraryService.filterLibraryBooksRequested;
  }

  @Output() onUpdateEvent = new EventEmitter();

  searchText: string = '';
  genreText: string = '';

  libraryIsDefined: boolean = false;

  isFiltered: WritableSignal<boolean>;
  newLibraryBookRequested: WritableSignal<boolean>;
  isLibrarySelected: WritableSignal<boolean>;
  userLibrary: WritableSignal<UserLibrary>;
  bookGenres: WritableSignal<Array<string>>;

  toggleShowNewBook(): void {
    var newBookRequested = this.newLibraryBookRequested();
    if (newBookRequested) {
      this.libraryService.cancelNewLibraryBook();
    }
    else {
      this.libraryService.requestNewLibraryBook();
    }
  }

  filterBookGenre(): void {
    this.libraryService.setBookGenreFilter(this.genreText);
  }

  filterBookshelf(): void {
    this.libraryService.setBookTextFilter(this.searchText);
  }

  createNewBook($event: any) {
    let book = $event;
    book.index = this.libraryService.nextBookIndex;

    // console.log('[UserLibraryControl].CreateNewBook() --> ', book);
    this.libraryService.saveNewLibraryBook(book);
  }

  cancelNewBook(): void {
    this.libraryService.cancelNewLibraryBook();
  }
}
