import { Injectable } from "@angular/core";

import { v4 as uuidv4 } from 'uuid';

import { ClientSessionInfo } from "../library/models/client-session.model";
import { encryptContentFunc, decryptContentFunc, encryptEmailConfirmationCodeFunc } from './crypto';

@Injectable({
  providedIn: 'root',
})
export class UserLibrarySecurityService {
  private clientSessionId: string;
  private serverSessionId: string;
  private mailServiceId = 'D158BFBF-3F84-4A79-A84D-9A6FE5430730';
  
  constructor() {
    this.clientSessionId = '';
    this.serverSessionId = '';
  }

  public GetMailServiceId(): string {
    return this.EncryptValue(this.mailServiceId);
  }

  public CreateClientSessionId(): string {
    this.clientSessionId = uuidv4().toUpperCase().replace(/-/g, '');
    return this.clientSessionId;
  }

  public UpdateClientSessionInfo(clientSessionInfo: ClientSessionInfo): void {
    this.serverSessionId = ClientSessionInfo.key(clientSessionInfo.id);
  }

  public GetClientSessionId(): string {
    return this.clientSessionId;
  }

  public EncryptValue(value: string): string {
    return encryptContentFunc(value, this.GetKey(), this.GetIv());
  }

  public DecryptValue(value: string): string {
    return decryptContentFunc(value, this.GetKey(), this.GetIv());
  }

  public CreateConfirmationCode(salt: string): string {
    return encryptEmailConfirmationCodeFunc(salt + '|This-is-a-generic-confirmation-code-to-be-encrypted');
  }

  private GetKey(): string {
    return this.serverSessionId;
  }

  private GetIv(): string {
    return this.clientSessionId.substring(0, 16);
  }
}
