<ng-container *ngIf="book">
  <div class="list-group-item list-group-item-action container"
    [class]="(book.isNewBook()) ? 'new-book': (index % 2) ? 'even-book' : 'odd-book'">

    <!-- Row 1 -->
    <app-book-list-item [book]="book" (onDelete)="deleteBook()"></app-book-list-item>

    <!-- Row 2 -->
    <app-book-editor *ngIf="book.isExpanded" [book]="book" (onChanged)="updateBook()" (onEditorsChanged)="updateBookEditors()"></app-book-editor>

  </div>
</ng-container>
