import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LibraryBook } from '../../models/library-book.model';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-book-list-item',
  templateUrl: './book-list-item.component.html',
  styleUrls: ['./book-list-item.component.scss']
})
export class BookListItemComponent {
  _book: LibraryBook = LibraryBook.init();

  constructor() { }

  get book(): LibraryBook {
    return this._book;
  }
  @Input() set book(book: LibraryBook) {
    this._book = book;
    this.setPasswordLastUpdateStatus();
  }

  @Output() onDelete = new EventEmitter();

  isFresh: boolean = false;
  isStale: boolean = false;
  isCritical: boolean = false;

  toggleBookExpansion(): void {
    // NOTE: always exist edit mode when collapsing
    if (this.book.isEditable && this.book.isExpanded) {
      this.book.isEditable = false;
    }

    this.book.isExpanded = !this.book.isExpanded;
  }

  setPasswordLastUpdateStatus(): void {
    this.isFresh = Util.isPasswordFresh(this.book.publishedOn);
    this.isStale = Util.isPasswordStale(this.book.publishedOn);
    this.isCritical = Util.isPasswordCritical(this.book.publishedOn);
  }

  deleteBook(): void {
    this.onDelete.emit();
  }
}
