<fieldset class="border rounded-3 p-3">
  <legend class="legend-sm float-none w-auto px-3">Update User Library</legend>
  <form [formGroup]="libraryForm">

    <div class="row">
      <label class="col-sm-3 col-form-label" for="location">Library update confirmed: </label>
      <div class="col-sm-9">
        <input id="isConfirmed" title="isConfirmed" type="checkbox" tabindex="1" formControlName="isConfirmed" (click)="confirmUpdateLibrary()" />
      </div>
    </div>

    <div class="row">
      <label class="col-sm-3 col-form-label" for="location">Location: </label>
      <div class="col-sm-9">
        <input id="location" type="input" placeholder="Location" formControlName="location" disable
          readonly autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <label class="col-sm-3 col-form-label" for="name">Name: </label>
      <div class="col-sm-9">
        <input id="name" type="input" placeholder="Name" disable readonly formControlName="name"
          autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <label class="col-sm-3 col-form-label" class="" for="description">Description: </label>
      <div class="col-sm-9">
        <textarea id="description" type="input" cols="50" rows="6" placeholder="Description" disable
          readonly formControlName="description" autocomplete="off"></textarea>
      </div>
    </div>

  </form>
</fieldset>
