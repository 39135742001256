import { Component, WritableSignal } from '@angular/core';
import { TempLibraryService } from '../../services/temp-library.service';
import { TempLibraryUser } from '../../models/temp-library-user.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-temp-library-user',
  templateUrl: './temp-library-user.component.html',
  styleUrls: ['./temp-library-user.component.scss']
})
export class TempLibraryUserComponent {

  libraryUserForm = new UntypedFormGroup({
    userName: new UntypedFormControl(''),
    firstName: new UntypedFormControl(''),
    lastName: new UntypedFormControl(''),
    email: new UntypedFormControl('')
  });

  constructor(tempLibraryService: TempLibraryService) {
    this.tempUser = tempLibraryService.tempLibraryUser;

    this.libraryUserForm.controls["userName"].setValue(this.tempUser().userName);
    this.libraryUserForm.controls["firstName"].setValue(this.tempUser().firstName);
    this.libraryUserForm.controls["lastName"].setValue(this.tempUser().lastName);
    this.libraryUserForm.controls["email"].setValue(this.tempUser().email);
  }

  tempUser : WritableSignal<TempLibraryUser>;
}
