import { Util } from "src/app/utils/utils";

export class TempUserLibrary {
  id: string = Util.emptyGuid;
  sourceId: string = Util.emptyGuid;

  location: number = -1;
  name: string = '';
  description: string = '';

  hasValidationCode = false;

  isConfirmed: boolean = false;

  isValid(): boolean {
    return (this.id != "" && this.id != Util.emptyGuid)
      && this.location > 0
      && this.name.length > 0
      && this.description.length > 0
      && this.hasValidationCode;
  }

  static init(): TempUserLibrary {
    return this.create(-1);
  }

  private static create(location: number): TempUserLibrary {
    let tempLibrary = new TempUserLibrary();

    tempLibrary.location = location;
    return tempLibrary;
  }
}
