import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from '../project.model';
import { ProjectsService } from '../projects.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  private id: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectsService: ProjectsService) {
    this.project = new Project();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (paramsId) => {
        this.id = paramsId['id'];

        this.projectsService.getAll().subscribe({
          next: (projects) => {
            const project = projects.find(p => p.id === this.id);
            if (project !== undefined) {
              project.projectItems.sort((a, b) => {
                return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
              });
              this.project = project;
            }
          },
          error: (_) => { }
        })
      },
      error: (_) => { }
    });
  }

  public project: Project;
}
