import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SecurityPhrases } from 'src/app/security/security-phrases/security-phrases.model';

@Component({
  selector: 'app-user-signin',
  templateUrl: './user-signin.component.html',
  styleUrls: ['./user-signin.component.scss']
})
export class UserSigninComponent {

  private _userName: string = '';
  private _securityPhrases: SecurityPhrases = SecurityPhrases.init();

  constructor() {
  }

  get userName(): string {
    return this._userName;
  }
  @Input() set userName(userName: string) {
    this._userName = userName;
  }

  get securityPhrases(): SecurityPhrases {
    return this._securityPhrases;
  }
  @Input() set securityPhrases(securityPhrases: SecurityPhrases) {
    this._securityPhrases = securityPhrases;
  }

  @Output() onConfirmation = new EventEmitter<{userName: string, securityPhrases: SecurityPhrases}>();
  @Output() onCancellation = new EventEmitter();

  private _userNameInputElement!: ElementRef<HTMLInputElement>;
  @ViewChild('userNameInput') set inputElRef(elRef: ElementRef<HTMLInputElement>) {
    if (elRef) {
      this._userNameInputElement = elRef;
      this._userNameInputElement.nativeElement.focus();
    }
  }

  hasValidUserName: boolean = false;
  hasValidSecurityPhrases: boolean = false;

  libraryUserNameForm = new UntypedFormGroup({
    userName: new UntypedFormControl('', [Validators.required, Validators.minLength(4)])
  });

  setUserName(): void {
    this._userName = this.libraryUserNameForm.controls['userName'].value;
    this.hasValidUserName = true;
  }

  signInUser(): void {
    this.onConfirmation.emit({userName: this._userName, securityPhrases: this._securityPhrases});
    this.resetAll();
  }

  signInCancel(): void {
    this.onCancellation.emit();
    this.resetAll();
  }

  onSecurityPhrasesChange(securityPhrases: SecurityPhrases): any {
    this._securityPhrases = securityPhrases;
    this.hasValidSecurityPhrases = securityPhrases.isValid();
  }

  private resetAll(): void {
    this.hasValidUserName = false;
    this.hasValidSecurityPhrases = false;

    this.userName = '';
    this.securityPhrases = SecurityPhrases.init();
  }
}
