import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Blog } from 'src/app/blogs/blog.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class BlogsService {

  blogs: Observable<Array<Blog>> | null;
  constructor(private httpClient: HttpClient) {
    this.blogs = null;
  }

  getAll(): Observable<Blog[]> {
    if (this.blogs != null) {
      return this.blogs;
    }

    return this.httpClient.get(`${environment.apiUrl}/Blogs`).pipe(
      map((response: any): any => { return response; }),
      map((blogs: Blog[]): Blog[] => {
        blogs.sort((a, b) => { return a.publishedOn < b.publishedOn ? 1 : -1 })

        this.blogs = of(blogs);
        return blogs;
      }),);
  }
}
