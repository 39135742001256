<ng-container>
  <div class="list-group-item list-group-item-action container" [class]="(index % 2) ? 'even-book' : 'odd-book'">

    <form [formGroup]="libraryBookForm">

      <div class="row">

        <label class="col-sm-2 col-form-label" for="bookTitle"><span>[{{bookIndex}}] </span><span>Title</span></label>
        <div class="col-sm-4">
          <input title="Title" id="bookTitle" type="text" formControlName="title" disable readonly />
        </div>

        <label class="col-sm-1 col-form-label" for="bookGenre">Genre</label>
        <div class="col-sm-5">
          <input title="Genre" id="bookGenre" type="text" formControlName="genre" disable readonly />
        </div>

      </div>

      <div class="row">

        <label class="col-sm-1 col-form-label" for="bookAuthor">Author</label>
        <div class="col-sm-5">
            <input title="Author" id="bookAuthor" type="text" formControlName="author" disable readonly />
        </div>

        <label class="col-sm-1 col-form-label" for="bookPublisher">Publisher</label>
        <div class="col-sm-5">
            <input title="Publisher" id="bookPublisher" formControlName="publisher" disable readonly />
        </div>

      </div>

      <div class="row">

        <label class="col-sm-1 col-form-label multiLine align-top" for="bookEditors">Editors</label>
        <div class="col-sm-5">
          <textarea title="Editors" id="bookEditors" formControlName="editors" cols="40" rows="4" disable readonly></textarea>
        </div>

        <label class="col-sm-1 col-form-label multiLine align-top" for="bookReviews">Reviews</label>
        <div class="col-sm-5">
          <textarea title="Reviews" id="bookReviews" formControlName="reviews" cols="40" rows="4" disable readonly></textarea>
        </div>

      </div>

      <div class="row">

        <div class="col-sm-4">
          <span class="float-left text-muted"><small>created on: {{book.createdOn | date: 'yyyy.MM.dd HH:mm'}}</small></span>
        </div>

        <div class="col-sm-4">
          <span class="float-right text-muted"><small>published on: {{book.publishedOn | date: 'yyyy.MM.dd HH:mm'}}</small></span>
        </div>

        <div class="col-sm-4">
          <span class="float-right text-muted"><small>modified on: {{book.updatedOn | date: 'yyyy.MM.dd HH:mm'}}</small></span>
        </div>
      </div>
    </form>

  </div>
</ng-container>
