import { Component, Input, Output, EventEmitter } from '@angular/core';

import { LibraryBook } from '../models/library-book.model';

@Component({
  selector: 'app-library-book',
  templateUrl: './library-book.component.html',
  styleUrls: ['./library-book.component.scss']
})
export class LibraryBookComponent {
  _index: number = -1;
  _book: LibraryBook = LibraryBook.init();

  constructor() { }

  get index(): number {
    return this._index;
  }
  @Input() set index(value: number) {
    this._index = value;
  }

  get book(): LibraryBook {
    return this._book;
  }
  @Input() set book(book: LibraryBook) {
    this._book = book;
  }

  @Output() onChanged = new EventEmitter<LibraryBook>();

  @Output() onEditorsChanged = new EventEmitter<LibraryBook>();
  @Output() onDelete = new EventEmitter<LibraryBook>();

  deleteBook(): void {
    this.onDelete.emit(this._book);
  }

  updateBook(): void {
    this.onChanged.emit(this.book);
  }

  updateBookEditors(): void {
    this.onEditorsChanged.emit(this.book);
  }
}
