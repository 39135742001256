"use strict";

//READ THIS: https://www.npmjs.com/package/aes-js  (do not use ECB mode in production!!!!)
//NOTE: This is intended for bi-directional encrypted communication between this app and the API service
//      Need to define the `secret_key` / `your_secret_iv` details somehow (not in source code/git-hub)
import { enc, AES, mode as _mode, pad } from "crypto-js";

export const encryptContentFunc = encryptContent;
export const decryptContentFunc = decryptContent;
export const encryptEmailConfirmationCodeFunc = encryptEmailConfirmationCode;

function encryptContent(content: string, secret_key: string, secret_iv: string): string {
  return aesEncryptCBC(content, secret_key, secret_iv);
}

function decryptContent(content: string, secret_key: string, secret_iv: string): string {
  return aesDecryptCBC(content, secret_key, secret_iv);
}

function encryptEmailConfirmationCode(content: string): string {
  return aesEncryptNoSaltCBC(content, defaultKey(), defaultIv())
}

function aesEncryptCBC(content: string, secret_key: string, secret_iv: string): string {
  const parsedkey = enc.Utf8.parse(secret_key);
  const iv = enc.Utf8.parse(secret_iv);

  const saltedContent = addSaltToData(content);
  const encrypted = AES.encrypt(saltedContent, parsedkey, { iv: iv, mode: _mode.CBC, padding: pad.Pkcs7 });

  return encrypted.toString();
};

function aesEncryptNoSaltCBC(content: string, secret_key: string, secret_iv: string): string {
  const parsedkey = enc.Utf8.parse(secret_key);
  const iv = enc.Utf8.parse(secret_iv);

  const encrypted = AES.encrypt(content, parsedkey, { iv: iv, mode: _mode.CBC, padding: pad.Pkcs7 });

  return encrypted.toString();
};

function aesDecryptCBC(content: string, secret_key: string, secret_iv: string): string {
  const keys = enc.Utf8.parse(secret_key);
  const iv = enc.Utf8.parse(secret_iv);

  const base64 = enc.Base64.parse(content);
  const src = enc.Base64.stringify(base64);

  const decrypt = AES.decrypt(src, keys, { iv: iv, mode: _mode.CBC, padding: pad.Pkcs7 });
  const descryptedContent = decrypt.toString(enc.Utf8);

  return extractSaltedData(descryptedContent);
};

function addSaltToData(value: string): string {
  return getRandomInt(1000, 9999) + '|' + value;
}

function extractSaltedData(decryptedValue: string): string {
  let parts = decryptedValue.split('|');
  if (parts.length != 2) return decryptedValue;

  return parts[1];
}

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);

  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min) + min);
};

function defaultKey(): string {
  return 'B09B6EA562E04EE3AF4F08DB1C4CACD0';
}

function defaultIv(): string {
  return 'AF8E9577E6E44BC0';
}
