<div class="container">

  <div class="prj-desc">
    <h2>{{project.name}}</h2>
    <p>{{project.description}}</p>
  </div>

  <div class="flex-container">

    <ng-container *ngFor="let projectItem of project.projectItems">

      <div class="prj-item">

        <ng-container *ngIf="projectItem.readMoreLink.length > 0">
          <a href="{{projectItem.readMoreLink}}" target="_blank">
            <h3><span>{{projectItem.name}}</span></h3>
          </a>
        </ng-container>

        <ng-container *ngIf="projectItem.readMoreLink.length == 0">
          <h4>
            <span>{{projectItem.name}}</span>
          </h4>
        </ng-container>

        <p>{{projectItem.description}}</p>
      </div>
    </ng-container>

  </div>

</div>
