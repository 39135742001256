export class Util {
  static isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === 'undefined' || obj === null;
  }

  static countMonths(date1: Date, date2: Date): number {
    //in case the params are not truly Dates, we create them here to prevent exceptions
    let startDate = new Date(date1);
    let endDate = new Date(date2);

    let stepDate = new Date();
    stepDate.setTime(startDate.getTime());
    let monthCount = 0;

    while (stepDate.getTime() <= endDate.getTime()) {
      stepDate.setMonth(stepDate.getMonth() + 1);
      monthCount += 1;
    }

    if (stepDate != endDate) {
      monthCount -= 1;
    }

    return monthCount;
  }

  static isValidLibraryFieldValue(value: string): boolean {
    return (value !== undefined && value !== null && value.length > 0 && value !== '---??---')
  }

  static togglePasswordFieldDisplay(currentDisplay: string): string {
    return (currentDisplay === 'password')
      ? 'text'
      : 'password';
  }

  static isPasswordFresh(publishedOn: Date): boolean {
    let elapsedMonths = Util.countMonths(publishedOn, new Date());

    return elapsedMonths < 3;
  }

  static isPasswordStale(publishedOn: Date): boolean {
    let elapsedMonths = Util.countMonths(publishedOn, new Date());

    return elapsedMonths >= 3 && elapsedMonths < 6;
  }

  static isPasswordCritical(publishedOn: Date): boolean {
    let elapsedMonths = Util.countMonths(publishedOn, new Date());

    return elapsedMonths >= 6;
  }

  static emptyGuid: string = '00000000-0000-0000-0000-000000000000';

  static untranslatableValue: string = '---??---';

  static encryptHeader: string = 'U2FsdGVkX1';
}
