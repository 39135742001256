import { Injectable, signal } from "@angular/core";

import { NotificationService } from "src/app/services/notification.service";
import { LibraryService } from "./library.service";
import { LibraryReversionService } from "./library-reversion.service";
import { LibraryTranslationService } from "./library-translation.service";
import { UserLibrarySecurityService } from "src/app/security/user-library-security.service";

import { LibraryClientSessionHttpClientService } from "./http-client-services/library-client-session.http-client-service";
import { LibraryBookHttpClientService } from "./http-client-services/library-book.http-client-service";
import { TempLibraryUpdateSecurityPhrasesHttpClientService } from "./http-client-services/temp-library-update-security-phrases.http-client-service";

import { ClientSessionInfo } from "../models/client-session.model";
import { SecurityPhrases } from "src/app/security/security-phrases/security-phrases.model";

import { UserLibrary } from "../models/user-library.model";
import { LibraryBookDto } from "./interfaces/library-book-dto.interface";

import { TempLibraryUser } from "../models/temp-library-user.model";
import { TempUserLibrary } from "../models/temp-user-library.model";
import { TempLibraryBook } from "../models/temp-library-book.model";

@Injectable({
  providedIn: 'root'
})
export class TempLibraryService {
  private _clientSessionInfo = signal<ClientSessionInfo>(ClientSessionInfo.init());
  private _reversionService = new LibraryReversionService();
  private _translationService = new LibraryTranslationService();

  constructor(
    libraryClientService: LibraryClientSessionHttpClientService,
    private libraryService: LibraryService,
    private libraryBookService: LibraryBookHttpClientService,
    private librarySecurityService: UserLibrarySecurityService,
    private notificationService: NotificationService,
    private tempLibraryUpdateService: TempLibraryUpdateSecurityPhrasesHttpClientService) {
      //Call, for now, once per browser session
      libraryClientService.createClientSession(this.librarySecurityService.CreateClientSessionId()).subscribe({
        next: (sessionInfo: ClientSessionInfo) => {
          this._clientSessionInfo.set(sessionInfo);
          this.librarySecurityService.UpdateClientSessionInfo(sessionInfo);

          this.hasTempClientSessionInfo.set(true);
        },
        error: _ => {
          notificationService.showError('The temp client session could not be started.\r\nIf this issue persists, contact the web administrator.', 'Fatal Error')
    }});
  }

  public hasTempClientSessionInfo = signal<boolean>(false);
  public hasAcceptUpdateCompleted = signal<boolean>(false);
  public hasCancelUpdateCompleted = signal<boolean>(false);

  public hasValidTempSecurityPhrases = signal<boolean>(false);
  public userSecurityPhrases = signal<SecurityPhrases>(SecurityPhrases.init());

  public hasTempUser = signal<boolean>(false);
  public tempUserConfirmed = signal<boolean>(false);
  public tempLibraryUser = signal<TempLibraryUser>(TempLibraryUser.init());

  public hasTempLibraries = signal<boolean>(false);
  public allTempLibrariesConfirmed = signal<boolean>(false);
  public tempUserLibraries = signal<Array<TempUserLibrary>>([]);

  public hasCurrentTempLibrary = signal<boolean>(false);
  public currentTempLibrary = signal<TempUserLibrary>(TempUserLibrary.init());

  public hasTempLibraryBooks = signal<boolean>(false);
  public tempLibraryBooks = signal<Array<TempLibraryBook>>([]);

  public SetSecurityPhrases(securityPhrases: SecurityPhrases): void {
    this.userSecurityPhrases.set(securityPhrases);

    if (securityPhrases.isValid())
      this.hasValidTempSecurityPhrases.set(true);
  }

  public SetCurrentTempUserLibrary(tempLibrary: TempUserLibrary): void {
    this.currentTempLibrary.set(tempLibrary);
    this.hasCurrentTempLibrary.set(true);

    this.SetTempLibraryBooks(tempLibrary);
  }

  public confirmCurrentLibrary(): void {
    this.currentTempLibrary.mutate(l => l.isConfirmed = true);
    this.setLibrariesConfirmed();
  }

  public SetTempLibraryBooks(tempLibrary: TempUserLibrary): void {
    const currentUser = this.libraryService.signedInUser();
    const currentLibrary = this.libraryService.userLibraries().find(x => x.location === tempLibrary.location) ?? UserLibrary.init();
    if (!currentLibrary.isValid()) return;

    this.libraryBookService.getActiveBooks(this.librarySecurityService.GetClientSessionId(), currentUser.id, currentLibrary.id).subscribe({
      next: (libraryBooksDto: Array<LibraryBookDto>) => {
        libraryBooksDto.sort((a, b) => a.index > b.index ? 1 : -1);

        let tempUser = this.tempLibraryUser();
        let tempLibrary = this.currentTempLibrary();

        const currentLibraryBooks = new Array<TempLibraryBook>();
        const originalSecurityPhrases = this.libraryService.userSecurityPhrases();

        libraryBooksDto.forEach((dtoBook) => {
          const book = this._translationService.translateLibraryBookDto(dtoBook, originalSecurityPhrases);
          const tempBook = this._translationService.translateTempLibraryBook(book);

          currentLibraryBooks.push(tempBook);
          this.setTempLibraryBook(tempUser, tempLibrary, tempBook);
        });

        this.tempLibraryBooks.set(currentLibraryBooks);
        this.hasTempLibraryBooks.set(currentLibraryBooks.length > 0);
      },
      error: (error) => {
        console.log('[TempLibraryService].SetTempLibraryBooks: (failed):', error);
      }});
  }

  public StartUpdate(): void
  {
    this.AddOrUpdateTempUserData();
  }

  public AcceptUpdate(): void {
    const messageTitle = "Accept Temp Library User update";
    const errorMessage = "An error occurred preventing accepting the temp library update. \r\nIf this error persist, contact the web administrator.";

    const currentUser = this.libraryService.signedInUser();
    const tempUser = this.tempLibraryUser();

    this.tempLibraryUpdateService.acceptTempUpdates(currentUser.id, currentUser.userName, tempUser.id).subscribe({
      next: (_: any) => {
        this.hasAcceptUpdateCompleted.set(true);
       },
      error: (_: any) => {
        this.notificationService.showError(errorMessage, messageTitle);
        this.hasAcceptUpdateCompleted.set(false);
      }
    });
  }

  public CancelUpdate(): void {
    const messageTitle = "Cancel Temp Library User update";
    const errorMessage = "An error occurred preventing cancelling the temp library update. \r\nIf this error persist, contact the web administrator.";

    const currentUser = this.libraryService.signedInUser();
    const tempUser = this.tempLibraryUser();

    this.tempLibraryUpdateService.cancelTempUpdates(currentUser.id, currentUser.userName, tempUser.id).subscribe({
      next: (_: any) => {
        this.hasCancelUpdateCompleted.set(true);
       },
      error: (_: any) => {
        this.notificationService.showError(errorMessage, messageTitle);
        this.hasCancelUpdateCompleted.set(false);
      }
    });
  }

  private AddOrUpdateTempUserData(): void {
    const currentUser = this.libraryService.signedInUser();
    const tempUser = this._translationService.translateTempLibraryUser(currentUser);

    this.setTempLibraryUser(tempUser);
  }

  private setTempLibraryUser(tempUser: TempLibraryUser): void {
    const messageTitle = "Add|Update Temp Library User";
    const errorMessage = "An error occurred preventing adding/updating the temp library user. \r\nIf this error persist, contact the web administrator.";

    this.tempLibraryUser.set(tempUser);

    const user = this.libraryService.signedInUser();
    const tempUserRerverted = this._reversionService.revertTempLibraryUser(tempUser, this.userSecurityPhrases());
    this.tempLibraryUpdateService.setTempUser(user.id, user.userName, tempUser.id, tempUserRerverted).subscribe({
      next: (_: any) => {
        this.hasTempUser.set(true);
        this.tempUserConfirmed.set(true);

        this.AddOrUpdateTempUserLibrariesData();
       },
      error: (_: any) => {
        this.notificationService.showError(errorMessage, messageTitle); }
    });
  }

  private AddOrUpdateTempUserLibrariesData(): void {
    const libraries = this.libraryService.userLibraries();

    let allLibrariesConfirmed = true;
    let tempLibraries = Array<TempUserLibrary>();
    const tempUser = this.tempLibraryUser();

    libraries.forEach((lib: UserLibrary) =>
    {
      const tempLibrary = this._translationService.translateTempUserLibrary(lib);
      if (!tempLibrary.isConfirmed) allLibrariesConfirmed = false;

      tempLibraries.push(tempLibrary);

      this.setTempUserLibrary(tempUser, tempLibrary);
    });

    this.tempUserLibraries.set(tempLibraries);
    this.hasTempLibraries.set(true);
    this.allTempLibrariesConfirmed.set(allLibrariesConfirmed);
  }

  private setTempUserLibrary(tempUser: TempLibraryUser, tempLibrary: TempUserLibrary): void {
    const messageTitle = "Add|Update Temp User Library";
    const errorMessage = "An error occurred preventing adding/updating the temp user library. \r\nIf this error persist, contact the web administrator.";

    const tempLibraryReverted = this._reversionService.revertTempUserLibrary(tempLibrary, this.userSecurityPhrases());
    this.tempLibraryUpdateService.setTempLibrary(tempUser.sourceId, tempUser.userName, tempLibrary.sourceId, tempUser.id, tempLibrary.id, tempLibraryReverted).subscribe({
      next: (_: any) => { },
      error: (_) => { this.notificationService.showError(errorMessage, messageTitle); }
    });
  }

  private setTempLibraryBook(tempUser: TempLibraryUser, tempLibrary: TempUserLibrary, tempBook: TempLibraryBook): void {
    const messageTitle = "Add|Update Temp User Library";
    const errorMessage = "An error occurred preventing adding/updating the temp user library. \r\nIf this error persist, contact the web administrator.";

    const userSecurityPhrases = this.userSecurityPhrases();
    const tempBookReverted = this._reversionService.revertTempLibraryBook(tempBook, userSecurityPhrases);

    this.tempLibraryUpdateService.setTempBook(tempUser.sourceId, tempUser.userName, tempLibrary.sourceId, tempBook.sourceId, tempUser.id, tempLibrary.id, tempBook.id, tempBookReverted).subscribe({
      next: (_: any) => {
      },
      error: (_: any) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  private setLibrariesConfirmed() {
    let isConfirmed = true;
    let libraries = this.tempUserLibraries();
    libraries.forEach((lib: TempUserLibrary) =>
    {
      if (!lib.isConfirmed) isConfirmed = false;
    });

    this.allTempLibrariesConfirmed.set(isConfirmed);
  }
}
