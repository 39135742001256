import { Component, OnInit, WritableSignal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ReCaptchaV3Service } from 'ng-recaptcha';

import { BlogsService } from '../blogs/blogs.service';
import { Blog } from '../blogs/blog.model';
import { ContactEmailModel } from './contact-email.model';
import { ContactMailService } from './contact-mail.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: UntypedFormGroup = new UntypedFormGroup({});
  submitted = false;
  eMail: ContactEmailModel = new ContactEmailModel();

  requestUrl: string;
  isLoaded: boolean = false;
  title: string;
  status: string;
  blogs = new Array<Blog>();

  constructor(
    private notificationService: NotificationService,
    private contactService: ContactMailService,
    private blogsService: BlogsService,
    private formBuilder: UntypedFormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service) {
    this.status = '';
    this.title = "Contact Me";
    this.requestUrl = window.location.href;
    this.hasClientSessionInfo = this.contactService.hasClientSessionInfo;
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      sender: ['', [Validators.required, Validators.maxLength(200), Validators.email]],
      subject: ['', [Validators.required, Validators.maxLength(200)]],
      message: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(2000)]],
    });

    this.blogsService.getAll().subscribe({
      next: (data) => {
        this.blogs = data;
        this.isLoaded = true;
      },
      error: (_) => {
        this.notificationService.showError('An error occurred loading the latest blogs.', 'Blogs Service');
      }
    });
  }

  hasClientSessionInfo: WritableSignal<boolean>;
  
  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  requestSend(): void {
    this.recaptchaV3Service.execute('emailContactRequest').subscribe(
      (token: string) => {
        this.onSubmit(token);
      },
      (error) => {
        console.log('Errors: ', error)
      });
  }

  onSubmit(token: string): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    const mail = new ContactEmailModel();

    mail.subject = this.f['subject'].value;
    mail.sender = this.f['sender'].value;
    mail.message = this.formatMessage(this.f['message'].value);
    mail.securityResponse = token;

    this.contactService.sendMailRequest(mail).subscribe({
      next: (_) => {
        this.notificationService.showSuccess('Successfully sent message.', 'Contact Us');
      },
      error: (_) => {
        this.notificationService.showError('Could not send message.  \r\nPlease try again later.', 'Contact Us');
      }
    })
      .add(() => {
        this.submitted = false;
        this.contactForm.reset();
      });
  }

  private formatMessage(messageBody: string): string {
    var sourceInfo = 'Source: ' + this.requestUrl + '\r\n';
    var lineBreak = '----------------------------------------\r\n';

    return sourceInfo + lineBreak + messageBody;
  }
}
