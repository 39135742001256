import { Util } from "src/app/utils/utils";

export class LibraryUser {
  id: string = Util.emptyGuid;

  userName: string = '';
  number: number = 0;
  firstName: string = '';
  lastName: string = '';
  hasValidationCode: boolean = false;
  email: string = '';

  createdOn: Date = new Date(Date.now());
  updatedOn: Date = new Date(Date.now());

  isValid(): boolean {
    return (this.id != "" && this.id != Util.emptyGuid)
      && Util.isValidLibraryFieldValue(this.userName)
      && Util.isValidLibraryFieldValue(this.firstName)
      && Util.isValidLibraryFieldValue(this.lastName)
      && Util.isValidLibraryFieldValue(this.email)
      && this.hasValidationCode;
  }

  static init(): LibraryUser {
    return LibraryUser.create(-1);
  }

  private static create(number: number): LibraryUser {
    const libraryUser = new LibraryUser();
    libraryUser.number = number;

    return libraryUser;
  }
}
