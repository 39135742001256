import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { HttpClientErrorHandler } from './http-client-error-handler.service';

import { ClientSessionInfo } from '../../models/client-session.model';

@Injectable()
export class LibraryClientSessionHttpClientService {
  constructor(
    private httpClient: HttpClient,
    private httpClientErrorHandlerService: HttpClientErrorHandler) {
  }

  createClientSession(clientId: string): Observable<ClientSessionInfo> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${clientId}` })
    };

    return this.httpClient.get(`${environment.apiUrl}/Lib/LibraryClientSessions`, httpOptions).pipe(
      map((response: any): any => { return response; }),
      map((clientSessionInfo: ClientSessionInfo): ClientSessionInfo => {
        return clientSessionInfo;
      }),
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryClientSession Service', error)));
  }
}
