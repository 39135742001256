import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Util } from 'src/app/utils/utils';
import { decryptContentFunc, encryptContentFunc } from 'src/app/security/crypto';

import { LibraryTranslationService } from 'src/app/library/services/library-translation.service';
import { LibraryReversionService } from 'src/app/library/services/library-reversion.service';

import { SecurityPhrases } from 'src/app/security/security-phrases/security-phrases.model';

import { LibraryUser } from 'src/app/library/models/library-user.model';
import { LibraryUserDto } from 'src/app/library/services/interfaces/library-user-dto.interface';
import { UserLibrary } from 'src/app/library/models/user-library.model';
import { UserLibraryDto } from 'src/app/library/services/interfaces/user-library-dto.interface';
import { LibraryBook } from 'src/app/library/models/library-book.model';
import { LibraryBookDto } from "src/app/library/services/interfaces/library-book-dto.interface";

import { LibraryUserRevertedDto } from 'src/app/library/services/interfaces/library-user-reverted-dto.interface';
import { UserLibraryRevertedDto } from 'src/app/library/services/interfaces/user-library-reverted-dto.interface';
import { LibraryBookRevertedDto } from "src/app/library/services/interfaces/library-book-reverted-dto.interface";

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent {
  private headerLength = Util.encryptHeader.length;
  private _translationService = new LibraryTranslationService();
  private _reversionService = new LibraryReversionService();

  constructor() {
    this.status = '';
    this.testCase = '';

    this.libraryBooksTranslationForm.controls['books'].valueChanges.subscribe({
      next: (value) => {
      let jsonData = JSON.parse(value ?? '');
      let books = jsonData.books;

      let libraryBooks = new Array<LibraryBook>();
      for (let i = 0; i < books.length; i++) {
        let book = books[i];
        const libraryBook = {} as LibraryBookDto;
        libraryBook.index = book.Index;
        libraryBook.title = this.trimHeader(book.P1);
        libraryBook.genre = this.trimHeader(book.P2);
        libraryBook.author = this.trimHeader(book.P3);
        libraryBook.publisher = this.trimHeader(book.P4);
        libraryBook.reviews = this.trimHeader(book.P5);

        let translatedBook = this._translationService.translateLibraryBookDto(libraryBook, this.securityPhrases);
        translatedBook.id = book.Id;

        libraryBooks.push(translatedBook);
      };
    }})
  }

  headerValue = Util.encryptHeader;
  headerValueLength = this.headerValue.length;
  status: string = '';
  testCase: string = '';
  encryptedText: string = '';
  decryptedText: string = '';
  textToTranslate: string = '';
  textTranslated: string = '';

  securityPhrases = SecurityPhrases.create('', '', '');

  testForm = new UntypedFormGroup({
    textToEncrypt: new UntypedFormControl('This is a simple test!'),
    textToDecrypt: new UntypedFormControl(''),
    //NOTE: Set these to an existing record in the test-repo and update that records expire date
    cryptoKey: new UntypedFormControl('B09B6EA562E04EE3AF4F08DB1C4CACD0'),
    cryptoIv: new UntypedFormControl('AF8E9577E6E44BC0'),
  });

  userTranslationForm = new UntypedFormGroup({
    userInfo: new UntypedFormControl('0DEE2526-100F-4754-8578-686272AEA819'),
    number: new UntypedFormControl('219763459'),
    userName: new UntypedFormControl('mTrilby'),
    firstName: new UntypedFormControl('first-name'),
    lastName: new UntypedFormControl('last-name'),
    email: new UntypedFormControl('eMail@company.com'),
    validationCode: new UntypedFormControl('')
  });

  libraryTranslationForm = new UntypedFormGroup({
    libraryInfo: new UntypedFormControl('C4141AB4-C085-4CA3-B495-25AA46B236F8'),
    location: new UntypedFormControl('521'),
    name: new UntypedFormControl('new-library'),
    description: new UntypedFormControl('basic-description'),
    validationCode: new UntypedFormControl('')
  });

  libraryBooksTranslationForm = new UntypedFormGroup({
    books: new UntypedFormControl('')
  });

  bookTranslationForm = new UntypedFormGroup({
    bookInfo: new UntypedFormControl('648AD3C3-5BA4-4703-9931-D865F3E2CB1A'),
    index: new UntypedFormControl('365'),
    title: new UntypedFormControl('book-title'),
    genre: new UntypedFormControl('genre'),
    author: new UntypedFormControl('author'),
    publisher: new UntypedFormControl('publisher'),
    editors: new UntypedFormControl('editors-listing'),
    reviews: new UntypedFormControl('reviews-listing'),
  });

  encrypt() {
    let key = this.testForm.controls['cryptoKey']!.value ?? '';
    let iv = this.testForm.controls['cryptoIv']!.value ?? '';

    let tobeEncrypted = this.testForm.controls['textToEncrypt']!.value ?? '';
    let tobeDecrypted = this.testForm.controls['textToDecrypt']!.value ?? '';

    let encryptedValue = encryptContentFunc(tobeEncrypted, key, iv);
    let decryptedValue = decryptContentFunc(encryptedValue, key, iv);

    this.encryptedText = encryptedValue + ' | (' + decryptedValue + ')';
    this.decryptedText = decryptContentFunc(tobeDecrypted, key, iv);
  }

  onSecurityPhrasesChange(phrases: SecurityPhrases): void {
    this.securityPhrases = phrases;
  }

  translateLibraryBooks(): void {
    console.log('Translating library-books is not implemented yet.');
  }

  revertLibraryBooks(): void {
    console.log('Reverting library-books is not implemented yet.');
  }

  translateUser(): void {
    // Translates a LibraryUserDto record
    let libraryUserDto = this.ReadLibraryUserDtoInfo();
    let userName = this.userTranslationForm.controls['userName'].value ?? '';

    let libraryUser = this._translationService.translateLibraryUserDto(userName, libraryUserDto, this.securityPhrases);
    this.SetLibraryUserInfo(libraryUser);
  }

  revertUser(): void {
    // Reverts a LibraryUser
    let libraryUser = this.ReadLibraryUserInfo();
    const revertedUser = this._reversionService.revertLibraryUser(libraryUser, this.securityPhrases);

    this.SetRevertedLibraryUserInfo(revertedUser);
  }

  translateLibrary(): void {
    // Translates a UserLibraryDto record
    let userLibrary = this.ReadUserLibraryDtoInfo();
    let translatedUserLibrary = this._translationService.translateUserLibraryDto(userLibrary, this.securityPhrases);

    this.SetUserLibraryInfo(translatedUserLibrary);
  }

  revertLibrary(): void {
    // Reverts a UserLibrary
    let userLibrary = this.ReadUserLibraryInfo();
    let revertedUserLibrary = this._reversionService.revertUserLibrary(userLibrary, this.securityPhrases);

    this.SetRevertedUserLibraryInfo(revertedUserLibrary);
  }

  translateBook(): void {
    // Translates a LibraryBookDto record
    let libraryBook = this.ReadLibraryBookDtoInfo();
    let translatedLibraryBook = this._translationService.translateLibraryBookDto(libraryBook, this.securityPhrases);

    this.SetLibraryBookInfo(translatedLibraryBook);
  }

  revertBook(): void {
    // Reverts a LibraryBook
    let libraryBook = this.ReadLibraryBookInfo();
    let revertedLibraryBook = this._reversionService.revertLibraryBook(libraryBook, this.securityPhrases);

    this.SetRevertedLibraryBookInfo(revertedLibraryBook);
  }

  private SetUserLibraryInfo(userLibrary: UserLibrary): void {
    this.libraryTranslationForm.controls['libraryInfo'].setValue(userLibrary.id);
    this.libraryTranslationForm.controls['location'].setValue(userLibrary.location.toString());
    this.libraryTranslationForm.controls['name'].setValue(userLibrary.name);
    this.libraryTranslationForm.controls['description'].setValue(userLibrary.description);
  }

  private SetRevertedUserLibraryInfo(userLibrary: UserLibraryRevertedDto): void {
    this.libraryTranslationForm.controls['libraryInfo'].setValue(userLibrary.info);
    this.libraryTranslationForm.controls['location'].setValue(userLibrary.location.toString());
    this.libraryTranslationForm.controls['name'].setValue(userLibrary.name);
    this.libraryTranslationForm.controls['description'].setValue(userLibrary.description);
    this.libraryTranslationForm.controls['validationCode'].setValue(userLibrary.validationCode);
  }

  private ReadUserLibraryInfo(): UserLibrary {
    var userLibrary = UserLibrary.init();

    userLibrary.id = this.libraryTranslationForm.controls['libraryInfo'].value ?? '';
    userLibrary.location = Number.parseInt(this.libraryTranslationForm.controls['location'].value ?? '-1', 10);
    userLibrary.name = this.libraryTranslationForm.controls['name'].value ?? '';
    userLibrary.description = this.libraryTranslationForm.controls['description'].value ?? '';

    return userLibrary;
  }

  private ReadUserLibraryDtoInfo(): UserLibraryDto {
    var userLibrary = {} as UserLibraryDto;

    userLibrary.info = this.libraryTranslationForm.controls['libraryInfo'].value ?? '';
    userLibrary.location = Number.parseInt(this.libraryTranslationForm.controls['location'].value ?? '-1', 10);
    userLibrary.name = this.libraryTranslationForm.controls['name'].value ?? '';
    userLibrary.description = this.libraryTranslationForm.controls['description'].value ?? '';
    userLibrary.validationCode = this.libraryTranslationForm.controls['validationCode'].value ?? '';

    return userLibrary;
  }

  private SetRevertedLibraryUserInfo(libraryUser: LibraryUserRevertedDto): void {
    this.userTranslationForm.controls['userInfo'].setValue(libraryUser.info);
    this.userTranslationForm.controls['number'].setValue(libraryUser.number.toString());
    this.userTranslationForm.controls['userName'].setValue(libraryUser.userName);
    this.userTranslationForm.controls['firstName'].setValue(libraryUser.firstName);
    this.userTranslationForm.controls['lastName'].setValue(libraryUser.lastName);
    this.userTranslationForm.controls['email'].setValue(libraryUser.email);
    this.userTranslationForm.controls['validationCode'].setValue(libraryUser.validationCode);
  }

  private SetLibraryUserInfo(libraryUser: LibraryUser): void {
    this.userTranslationForm.controls['userInfo'].setValue(libraryUser.id);
    this.userTranslationForm.controls['number'].setValue(libraryUser.number.toString());
    this.userTranslationForm.controls['userName'].setValue(libraryUser.userName);
    this.userTranslationForm.controls['firstName'].setValue(libraryUser.firstName);
    this.userTranslationForm.controls['lastName'].setValue(libraryUser.lastName);
    this.userTranslationForm.controls['email'].setValue(libraryUser.email);
  }

  private ReadLibraryUserInfo(): LibraryUser {
    var user = LibraryUser.init();

    user.id = this.userTranslationForm.controls['userInfo'].value ?? '';
    user.number = Number.parseInt(this.userTranslationForm.controls['number'].value ?? '-1', 10);
    user.userName = this.userTranslationForm.controls['userName'].value ?? '';
    user.firstName = this.userTranslationForm.controls['lastName'].value ?? '';
    user.lastName = this.userTranslationForm.controls['firstName'].value ?? '';
    user.email = this.userTranslationForm.controls['email'].value ?? '';

    return user;
  }

  private ReadLibraryUserDtoInfo(): LibraryUserDto {
    var user = {} as LibraryUserDto;

    user.info = this.userTranslationForm.controls['userInfo'].value ?? '';
    user.number = Number.parseInt(this.userTranslationForm.controls['number'].value ?? '-1', 10);
    //user.userName = this.userTranslationForm.controls['userName'].value ?? '';
    user.firstName = this.userTranslationForm.controls['firstName'].value ?? '';
    user.lastName = this.userTranslationForm.controls['lastName'].value ?? '';
    user.email = this.userTranslationForm.controls['email'].value ?? '';
    user.validationCode = this.userTranslationForm.controls['validationCode'].value ?? '';

    return user;
  }

  private SetRevertedLibraryBookInfo(translatedBook: LibraryBookRevertedDto) {
    this.bookTranslationForm.controls['bookInfo'].setValue(translatedBook.info);
    this.bookTranslationForm.controls['index'].setValue(translatedBook.index.toString());
    this.bookTranslationForm.controls['title'].setValue(translatedBook.title);
    this.bookTranslationForm.controls['genre'].setValue(translatedBook.genre);
    this.bookTranslationForm.controls['author'].setValue(translatedBook.author);
    this.bookTranslationForm.controls['publisher'].setValue(translatedBook.publisher);
    this.bookTranslationForm.controls['editors'].setValue(translatedBook.editors);
    this.bookTranslationForm.controls['reviews'].setValue(translatedBook.reviews);
  }

  private SetLibraryBookInfo(translatedBook: LibraryBook) {
    this.bookTranslationForm.controls['bookInfo'].setValue(translatedBook.id);
    this.bookTranslationForm.controls['index'].setValue(translatedBook.index.toString());
    this.bookTranslationForm.controls['title'].setValue(translatedBook.title);
    this.bookTranslationForm.controls['genre'].setValue(translatedBook.genre);
    this.bookTranslationForm.controls['author'].setValue(translatedBook.author);
    this.bookTranslationForm.controls['publisher'].setValue(translatedBook.publisher);
    this.bookTranslationForm.controls['editors'].setValue(translatedBook.editors);
    this.bookTranslationForm.controls['reviews'].setValue(translatedBook.reviews);
  }

  private ReadLibraryBookInfo(): LibraryBook {
    let book = LibraryBook.init();
    book.index = Number.parseInt(this.bookTranslationForm.controls['index'].value ?? '-1', 10);;

    book.id = this.bookTranslationForm.controls['bookInfo'].value ?? '';
    book.title = this.bookTranslationForm.controls['title'].value ?? '';
    book.genre = this.bookTranslationForm.controls['genre'].value ?? '';
    book.author = this.bookTranslationForm.controls['author'].value ?? '';
    book.publisher = this.bookTranslationForm.controls['publisher'].value ?? '';
    book.editors = this.bookTranslationForm.controls['editors'].value ?? '';
    book.reviews = this.bookTranslationForm.controls['reviews'].value ?? '';

    return book;
  }

  private ReadLibraryBookDtoInfo(): LibraryBookDto {
    let book = {} as LibraryBookDto;

    book.info = this.bookTranslationForm.controls['bookInfo'].value ?? '';
    book.index = Number.parseInt(this.bookTranslationForm.controls['index'].value ?? '-1', 10);;
    book.title = this.bookTranslationForm.controls['title'].value ?? '';
    book.genre = this.bookTranslationForm.controls['genre'].value ?? '';
    book.author = this.bookTranslationForm.controls['author'].value ?? '';
    book.publisher = this.bookTranslationForm.controls['publisher'].value ?? '';
    book.editors = this.bookTranslationForm.controls['editors'].value ?? '';
    book.reviews = this.bookTranslationForm.controls['reviews'].value ?? '';

    return book;
  }

  private trimHeader(value: string): string {
    if (value.startsWith(Util.encryptHeader)) {
      return value.substring(this.headerLength);
    }

    return value;
  }
}
