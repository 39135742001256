import { Util } from "src/app/utils/utils";

export class TempLibraryBook {
  id: string = Util.emptyGuid;
  sourceId: string = Util.emptyGuid;

  index: number = -1;
  title: string = '';
  genre: string = '';
  author: string = '';
  publisher: string = '';
  isNewPublisher: boolean = false;
  publishedOn: Date = new Date(Date.now());
  editors: string = '';
  reviews: string = '';
  isArchived: boolean = false;

  createdOn: Date = new Date(Date.now());
  updatedOn: Date = new Date(Date.now());

  displayType: string = 'password';
  isEditable: boolean = false;
  isExpanded: boolean = false;

  public isValid(): boolean {
    return (this.id != "" && this.id != Util.emptyGuid)
      && Util.isValidLibraryFieldValue(this.title)
      && Util.isValidLibraryFieldValue(this.genre)
      && Util.isValidLibraryFieldValue(this.author)
      && Util.isValidLibraryFieldValue(this.publisher);
  }

  static init(): TempLibraryBook {
    return TempLibraryBook.create(-1);
  }

  private static create(index: number): TempLibraryBook {
    let book = new TempLibraryBook();
    book.index = index;

    return book;
  }
}
