import { Component, effect } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TempLibraryService } from '../../services/temp-library.service';
import { TempUserLibrary } from '../../models/temp-user-library.model';

@Component({
  selector: 'app-temp-user-library',
  templateUrl: './temp-user-library.component.html',
  styleUrls: ['./temp-user-library.component.scss']
})
export class TempUserLibraryComponent {

  libraryForm = new UntypedFormGroup({
    isConfirmed: new UntypedFormControl(''),
    location: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
  });

  constructor(private tempLibraryService: TempLibraryService) {
    effect(() => {
      const currentUserLibrary = this.tempLibraryService.currentTempLibrary();
      this.formInit(currentUserLibrary);
    });
  }

  public confirmUpdateLibrary(): void {
    this.tempLibraryService.confirmCurrentLibrary();
  }

  private formInit(tempUserLibrary: TempUserLibrary): void {
    this.libraryForm.controls['isConfirmed'].setValue(tempUserLibrary.isConfirmed);
    this.libraryForm.controls['location'].setValue(tempUserLibrary.location);
    this.libraryForm.controls['name'].setValue(tempUserLibrary.name);
    this.libraryForm.controls['description'].setValue(tempUserLibrary.description);
  }
}
