<form [formGroup]="securityPhrasesForm" (ngSubmit)="submit()">
  <fieldset class="d-flex flex-column">
    <legend>Security phrases</legend>

    <div class="col-sm-3 justify-content-center">
      <input #level1Input class="fixWidthMedium" type="password" placeholder="Level 1" tabindex="51"
        formControlName="level1" autocomplete="off" />
    </div>

    <div class="col-sm-3 justify-content-center">
      <input class="fixWidthMedium" type="password" placeholder="Level 2" tabindex="52" formControlName="level2"
        autocomplete="off" />
    </div>

    <div class="col-sm-3 justify-content-center">
      <input class="fixWidthMedium" type="password" placeholder="Level 3" tabindex="53" formControlName="level3"
        autocomplete="off" />
    </div>

  </fieldset>
</form>
