import { Injectable } from "@angular/core";

import { AES } from 'crypto-js';

import { Util } from "src/app/utils/utils";

import { UserSecurityPhrasesHelperService } from "src/app/security/security-phrases/user-security-phrases.service";
import { BookSecurityPhrasesHelperService } from "src/app/security/security-phrases/book-security-phrases.service";
import { LibrarySecurityPhrasesHelperService } from "src/app/security/security-phrases/library-security-phrases.service";
import { SecurityPhrases } from "../../security/security-phrases/security-phrases.model";

import { LibraryUser } from "../models/library-user.model";
import { UserLibrary } from "../models/user-library.model";
import { LibraryBook } from "../models/library-book.model";
import { LibraryUserRevertedDto, NewLibraryUserRevertedDto, UpdateLibraryUserRevertedDto } from "./interfaces/library-user-reverted-dto.interface";
import { NewUserLibraryRevertedDto, UpdateUserLibraryRevertedDto, UserLibraryRevertedDto } from "./interfaces/user-library-reverted-dto.interface";
import { LibraryBookRevertedDto, NewLibraryBookRevertedDto, UpdateEditorsLibraryBookRevertedDto, UpdateLibraryBookRevertedDto } from "./interfaces/library-book-reverted-dto.interface";

import { TempLibraryUser } from "../models/temp-library-user.model";
import { TempUserLibrary } from "../models/temp-user-library.model";
import { TempLibraryBook } from "../models/temp-library-book.model";
import { TempLibraryUserRevertedDto } from "./interfaces/temp-library-user-reverted-dto.interface";
import { TempUserLibraryRevertedDto } from "./interfaces/temp-user-library-reverted-dto.interface";
import { TempLibraryBookRevertedDto } from "./interfaces/temp-library-book-reverted-dto.interface";

@Injectable()
export class LibraryReversionService {
  private notRevertableContent: string = ''
  private headerValueLength = Util.encryptHeader.length;

  public revertIdInfo(text: string, key: string): string {
    return this.revertText(text, key);
  }

  public revertLibraryUser(libraryUser: LibraryUser, securityPhrases: SecurityPhrases): LibraryUserRevertedDto {
    const userSecurityPhrases = new UserSecurityPhrasesHelperService(securityPhrases);

    let revertedUser = {} as LibraryUserRevertedDto;

    revertedUser.info = this.revertIdInfo(libraryUser.id, userSecurityPhrases.GetUserIdInfoPass(libraryUser));

    revertedUser.userName = libraryUser.userName;
    revertedUser.number = libraryUser.number;
    revertedUser.email = libraryUser.email;

    revertedUser.firstName = this.revertText(libraryUser.firstName, userSecurityPhrases.GetUserPassA(libraryUser));
    revertedUser.lastName = this.revertText(libraryUser.lastName, userSecurityPhrases.GetUserPassB(libraryUser));
    revertedUser.validationCode = this.revertText(libraryUser.number.toString(), userSecurityPhrases.GetUserPassC(libraryUser));

    revertedUser.createdOn = new Date(libraryUser.createdOn);
    revertedUser.updatedOn = new Date(libraryUser.updatedOn);

    return revertedUser;
  }

  public revertUpdateLibraryUser(libraryUser: LibraryUser, securityPhrases: SecurityPhrases): UpdateLibraryUserRevertedDto {
    const userSecurityPhrases = new UserSecurityPhrasesHelperService(securityPhrases);

    let updateUser = {} as UpdateLibraryUserRevertedDto;

    updateUser.firstName = this.revertText(libraryUser.firstName, userSecurityPhrases.GetUserPassA(libraryUser));
    updateUser.lastName = this.revertText(libraryUser.lastName, userSecurityPhrases.GetUserPassB(libraryUser));

    return updateUser;
  }

  public revertNewLibraryUser(libraryUser: LibraryUser, securityPhrases: SecurityPhrases): NewLibraryUserRevertedDto {
    const userSecurityPhrases = new UserSecurityPhrasesHelperService(securityPhrases);

    let newRevertedUser = {} as NewLibraryUserRevertedDto;

    newRevertedUser.number = libraryUser.number;
    newRevertedUser.email = libraryUser.email;

    newRevertedUser.firstName = this.revertText(libraryUser.firstName, userSecurityPhrases.GetUserPassA(libraryUser));
    newRevertedUser.lastName = this.revertText(libraryUser.lastName, userSecurityPhrases.GetUserPassB(libraryUser));
    newRevertedUser.validationCode = this.revertText(libraryUser.number.toString(), userSecurityPhrases.GetUserPassC(libraryUser));

    return newRevertedUser;
  }

  public revertUserLibrary(library: UserLibrary, securityPhrases: SecurityPhrases): UserLibraryRevertedDto {
    const librarySecurityPhrases = new LibrarySecurityPhrasesHelperService(securityPhrases);

    let revertedLibrary = {} as UserLibraryRevertedDto;

    revertedLibrary.info = this.revertIdInfo(library.id, librarySecurityPhrases.GetIdInfoPass(library));

    revertedLibrary.location = library.location

    revertedLibrary.name = this.revertText(library.name, librarySecurityPhrases.GetLibraryPassA(library));
    revertedLibrary.description = this.revertText(library.description, librarySecurityPhrases.GetLibraryPassB(library));
    revertedLibrary.validationCode = this.revertText(revertedLibrary.location.toString(), librarySecurityPhrases.GetLibraryPassC(library));

    revertedLibrary.createdOn = new Date(library.createdOn);
    revertedLibrary.updatedOn = new Date(library.updatedOn);

    return revertedLibrary;
  }

  public revertUpdateUserLibrary(library: UserLibrary, securityPhrases: SecurityPhrases): UpdateUserLibraryRevertedDto {
    const librarySecurityPhrases = new LibrarySecurityPhrasesHelperService(securityPhrases);

    let revertedUpdateLibrary = {} as UpdateUserLibraryRevertedDto;

    revertedUpdateLibrary.name = this.revertText(library.name, librarySecurityPhrases.GetLibraryPassA(library));
    revertedUpdateLibrary.description = this.revertText(library.description, librarySecurityPhrases.GetLibraryPassB(library));

    return revertedUpdateLibrary;
  }

  public revertNewUserLibrary(library: UserLibrary, securityPhrases: SecurityPhrases): NewUserLibraryRevertedDto {
    const librarySecurityPhrases = new LibrarySecurityPhrasesHelperService(securityPhrases);

    let newRevertedLibrary = {} as NewUserLibraryRevertedDto;

    newRevertedLibrary.location = library.location;

    newRevertedLibrary.name = this.revertText(library.name, librarySecurityPhrases.GetLibraryPassA(library));
    newRevertedLibrary.description = this.revertText(library.description, librarySecurityPhrases.GetLibraryPassB(library));
    newRevertedLibrary.validationCode = this.revertText(library.location.toString(), librarySecurityPhrases.GetLibraryPassC(library));

    return newRevertedLibrary;
  }

  public revertLibraryBook(book: LibraryBook, securityPhrases: SecurityPhrases): LibraryBookRevertedDto {
    const bookSecurityPhrases = new BookSecurityPhrasesHelperService(securityPhrases);

    let revertedBook = {} as LibraryBookRevertedDto;

    revertedBook.info = this.revertIdInfo(book.id, bookSecurityPhrases.GetIdInfoPass(book));

    revertedBook.index = book.index;
    revertedBook.isArchived = book.isArchived;

    revertedBook.title = this.revertText(book.title, bookSecurityPhrases.GetBookPassA(book));
    revertedBook.genre = this.revertText(book.genre, bookSecurityPhrases.GetBookPassB(book));
    revertedBook.reviews = this.revertText(book.reviews, bookSecurityPhrases.GetBookPassC(book));

    revertedBook.author = this.revertText(book.author, bookSecurityPhrases.GetBookPassD(book));
    revertedBook.editors = this.getRevertedEditors(book.editors, bookSecurityPhrases.GetBookPassF(book));
    revertedBook.publisher = this.revertText(book.publisher, bookSecurityPhrases.GetBookPassF(book));

    revertedBook.isNewPublisher = book.isNewPublisher;
    revertedBook.publishedOn = book.publishedOn;

    revertedBook.createdOn = book.createdOn;
    revertedBook.updatedOn = book.updatedOn;

    return revertedBook;
  }

  public revertUpdateLibraryBook(book: LibraryBook, securityPhrases: SecurityPhrases): UpdateLibraryBookRevertedDto {
    const bookSecurityPhrases = new BookSecurityPhrasesHelperService(securityPhrases);
    let revertedUpdateBook = {} as UpdateLibraryBookRevertedDto;

    revertedUpdateBook.title = this.revertText(book.title, bookSecurityPhrases.GetBookPassA(book));
    revertedUpdateBook.genre = this.revertText(book.genre, bookSecurityPhrases.GetBookPassB(book));
    revertedUpdateBook.reviews = this.revertText(book.reviews, bookSecurityPhrases.GetBookPassC(book));

    revertedUpdateBook.author = this.revertText(book.author, bookSecurityPhrases.GetBookPassD(book));
    revertedUpdateBook.publisher = this.revertText(book.publisher, bookSecurityPhrases.GetBookPassF(book));

    revertedUpdateBook.isNewPublisher = book.isNewPublisher;

    return revertedUpdateBook;
  }

  public revertNewLibraryBook(book: LibraryBook, securityPhrases: SecurityPhrases): NewLibraryBookRevertedDto {
    const bookSecurityPhrases = new BookSecurityPhrasesHelperService(securityPhrases);

    let revertedNewBook = {} as NewLibraryBookRevertedDto;
    revertedNewBook.index = book.index;

    revertedNewBook.title = this.revertText(book.title, bookSecurityPhrases.GetBookPassA(book));
    revertedNewBook.genre = this.revertText(book.genre, bookSecurityPhrases.GetBookPassB(book));
    revertedNewBook.reviews = this.revertText(book.reviews, bookSecurityPhrases.GetBookPassC(book));

    revertedNewBook.author = this.revertText(book.author, bookSecurityPhrases.GetBookPassD(book));
    revertedNewBook.publisher = this.revertText(book.publisher, bookSecurityPhrases.GetBookPassF(book));

    return revertedNewBook;
  }

  public revertUpdateEditorsLibraryBook(book: LibraryBook, securityPhrases: SecurityPhrases): UpdateEditorsLibraryBookRevertedDto {
    const bookSecurityPhrases = new BookSecurityPhrasesHelperService(securityPhrases);

    let revertedUpdateEditorsBook = {} as UpdateEditorsLibraryBookRevertedDto;

    revertedUpdateEditorsBook.title = this.revertText(book.title, bookSecurityPhrases.GetBookPassA(book));
    revertedUpdateEditorsBook.genre = this.revertText(book.genre, bookSecurityPhrases.GetBookPassB(book));
    revertedUpdateEditorsBook.reviews = this.revertText(book.reviews, bookSecurityPhrases.GetBookPassC(book));

    revertedUpdateEditorsBook.author = this.revertText(book.author, bookSecurityPhrases.GetBookPassD(book));
    revertedUpdateEditorsBook.editors = this.getRevertedEditors(book.editors, bookSecurityPhrases.GetBookPassF(book));
    revertedUpdateEditorsBook.publisher = this.revertText(book.publisher, bookSecurityPhrases.GetBookPassF(book));

    revertedUpdateEditorsBook.isNewPublisher = book.isNewPublisher;

    return revertedUpdateEditorsBook;
  }

  public revertTempLibraryUser(libraryUser: TempLibraryUser, securityPhrases: SecurityPhrases): TempLibraryUserRevertedDto {
    const userSecurityPhrases = new UserSecurityPhrasesHelperService(securityPhrases);

    let tempUser = {} as TempLibraryUserRevertedDto;

    tempUser.number = libraryUser.number;
    tempUser.firstName = this.revertText(libraryUser.firstName, userSecurityPhrases.GetTempUserPassA(libraryUser));
    tempUser.lastName = this.revertText(libraryUser.lastName, userSecurityPhrases.GetTempUserPassB(libraryUser));

    tempUser.info = this.revertIdInfo(libraryUser.id, userSecurityPhrases.GetTempUserIdInfoPass(libraryUser));
    tempUser.sourceInfo = this.revertIdInfo(libraryUser.sourceId, userSecurityPhrases.GetTempUserIdInfoPass(libraryUser));

    tempUser.validationCode = this.revertText(libraryUser.number.toString(), userSecurityPhrases.GetTempUserPassC(libraryUser));

    return tempUser;
  }

  public revertTempUserLibrary(library: TempUserLibrary, securityPhrases: SecurityPhrases): TempUserLibraryRevertedDto {
    const librarySecurityPhrases = new LibrarySecurityPhrasesHelperService(securityPhrases);

    let revertedLibrary = {} as TempUserLibraryRevertedDto;

    revertedLibrary.location = library.location
    revertedLibrary.name = this.revertText(library.name, librarySecurityPhrases.GetTempLibraryPassA(library));
    revertedLibrary.description = this.revertText(library.description, librarySecurityPhrases.GetTempLibraryPassB(library));

    revertedLibrary.info = this.revertIdInfo(library.id, librarySecurityPhrases.GetTempIdInfoPass(library));
    revertedLibrary.sourceInfo = this.revertIdInfo(library.sourceId, librarySecurityPhrases.GetTempIdInfoPass(library));
    revertedLibrary.validationCode = this.revertText(revertedLibrary.location.toString(), librarySecurityPhrases.GetTempLibraryPassC(library));

    return revertedLibrary;
  }

  public revertTempLibraryBook(book: TempLibraryBook, securityPhrases: SecurityPhrases): TempLibraryBookRevertedDto {
    const bookSecurityPhrases = new BookSecurityPhrasesHelperService(securityPhrases);

    let revertedBook = {} as TempLibraryBookRevertedDto;

    revertedBook.info = this.revertIdInfo(book.id, bookSecurityPhrases.GetTempInfoIdPass(book));
    revertedBook.sourceInfo = this.revertIdInfo(book.sourceId, bookSecurityPhrases.GetTempInfoIdPass(book));

    revertedBook.index = book.index;

    revertedBook.title = this.revertText(book.title, bookSecurityPhrases.GetTempBookPassA(book));
    revertedBook.genre = this.revertText(book.genre, bookSecurityPhrases.GetTempBookPassB(book));
    revertedBook.reviews = this.revertText(book.reviews, bookSecurityPhrases.GetTempBookPassC(book));

    revertedBook.author = this.revertText(book.author, bookSecurityPhrases.GetTempBookPassD(book));
    revertedBook.editors = this.getRevertedEditors(book.editors, bookSecurityPhrases.GetTempBookPassF(book));
    revertedBook.publisher = this.revertText(book.publisher, bookSecurityPhrases.GetTempBookPassF(book));

    return revertedBook;
  }

  private getRevertedEditors(editorsInfo: string, key: string): string {
    let revertedEditors = new Array<string>();
    if (editorsInfo && editorsInfo.length > 0) {
      let editors = editorsInfo.split('\r\n');
      editors.forEach((editor) => {
        let trimmedEditor = editor.trim();
        if (trimmedEditor.length > 0) {
          revertedEditors.push(this.revertText(trimmedEditor, key));
        }
      });
    }

    return revertedEditors.join('\r\n');
  }

  private revertText(text: string, key: string): string {
    if (text === undefined || text === null || text.length === 0) return '';

    try {
      return this.removeHeaderValue(AES.encrypt(text, key).toString());
    }
    catch (e) {
      return this.notRevertableContent;
    }
  }

  private removeHeaderValue(revertedValue: string): string {
    if (revertedValue && revertedValue.startsWith(Util.encryptHeader)) {
      return revertedValue.substring(this.headerValueLength);
    }

    return revertedValue;
  }
}
