<ng-container *ngIf="displaySelectedLibrary()">

  <fieldset class="d-flex flex-column">

    <div class="row">
      <div class="col-sm-12">
        <span class="padding">{{selectedLibrary().description}}</span>
      </div>
    </div>

  </fieldset>

  <!-- Confirmation Modal -->
  <div class="modal fade" #confirmation id="deleteConfirmation" tabindex="-1" aria-labelledby="deleteConfirmationLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="deleteConfirmationLabel">Delete Library Confirmation</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span>Deleting the library will remove all of its content as well.</span>
          <p>Do you want to continue?</p>
          <div class="alert alert-danger" role="alert">
            <span>This request cannot be undone.</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" (click)="requestDeleteLibrary()" class="btn btn-primary" data-bs-dismiss="modal">
            <span>Delete Library</span>
          </button>
        </div>
      </div>
    </div>
  </div>

</ng-container>
