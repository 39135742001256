<div *ngIf="isLoaded" class="container">

  <div *ngIf="hasClientSessionInfo()">

    <div class="row">
      <div class="col-md-8">
        <span *ngIf="status.length > 0" class="field-validation-error">{{status}}</span>

        <div class="wrapcontact">

          <fieldset>
            <legend class="fw-body-header">{{title}}</legend>
            <form [formGroup]="contactForm" (ngSubmit)="requestSend()">
              <div class="form-group">
                <label class="fw-body-header-sm">From</label>
                <input title="Sender" class="form-control fw-body-text-sm" type="text" formControlName="sender"
                  [ngClass]="{ 'is-invalid': submitted && f['sender'].errors }" />
                <div *ngIf="submitted && f['sender'].errors" class="invalid-feedback">
                  <div *ngIf="f['sender'].errors['email']">A valid email is expected</div>
                  <div *ngIf="f['sender'].errors['required']">From is required</div>
                  <div *ngIf="f['sender'].errors['maxlength']">From is too long</div>
                </div>
              </div>

              <div class="form-group">
                <label class="fw-body-header-sm">Subject</label>
                <input title="Subject" class="form-control fw-body-text-sm" type="text" formControlName="subject"
                  [ngClass]="{ 'is-invalid': submitted && f['subject'].errors }" />
                <div *ngIf="submitted && f['subject'].errors" class="invalid-feedback">
                  <div *ngIf="f['subject'].errors['required']">Subject is required</div>
                  <div *ngIf="f['subject'].errors['maxlength']">Subject is too long</div>
                </div>
              </div>

              <div class="form-group">
                <label class="fw-body-header-sm">Message</label>
                <textarea title="Message" class="form-control fw-body-text-sm" type="text" rows="5"
                  formControlName="message" [ngClass]="{ 'is-invalid': submitted && f['message'].errors }"></textarea>
                <div *ngIf="submitted && f['message'].errors" class="invalid-feedback">
                  <div *ngIf="f['message'].errors['required']">Message is required</div>
                  <div *ngIf="f['message'].errors['minlength']">Message is too short</div>
                  <div *ngIf="f['message'].errors['maxlength']">Message is too long</div>
                </div>
              </div>

              <input class="fw-body-form-submit" [disabled]="contactForm.invalid" type="submit" value="Send" />
            </form>
          </fieldset>
        </div>

      </div>

      <div *ngIf="blogs" class="col-md-4">
        <div *ngFor="let item of blogs" class="sidebar">
          <div class="sidebar_item">
            <h2>{{item.title}}</h2>
            <h3>{{item.publishedOn | date:'MMM yyyy'}}</h3>
            <p>{{item.message}}</p>
          </div>
        </div>
      </div>
    </div><!--close content-->

  </div>
</div><!--close site_content-->
