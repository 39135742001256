/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BusyAsyncRequestInterceptor } from './services/busy-async-request-interceptor';

/** Http interceptor providers in outside-in order */
export const HttpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BusyAsyncRequestInterceptor,
    multi: true
  },
];
