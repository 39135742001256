<div *ngIf="isLoaded" class="container">
  <div class="row">
    <div class="col-md-8">
      <h2>Active Projects</h2>
      <div *ngFor="let project of projects" class="content_container">
        <h3>{{project.name}}</h3>
        <p *ngFor="let line of project.description.split('\r\n')">{{line}}</p>

        <div *ngIf="project.readMoreLink.length > 0" class="button_small">
          <a href="{{project.readMoreLink}}" target="_blank">
            <span>Go to...</span>
          </a>
        </div>
        <div *ngIf="project.readMoreLink.length == 0" (click)="gotoProject(project.id)" class="button_small">
          <span>Read more</span>
        </div>
      </div>
    </div>

    <div *ngIf="blogs" class="col-md-4">
      <div *ngFor="let item of blogs" class="sidebar">
        <div class="sidebar_item">
          <h2>{{item.title}}</h2>
          <h3>{{item.publishedOn | date:'MMM yyyy'}}</h3>
          <p>{{item.message}}</p>
        </div>
      </div>
    </div>
  </div>

</div>
