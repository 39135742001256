import { Injectable } from "@angular/core";

import { enc, AES } from 'crypto-js';
import { Util } from "src/app/utils/utils";

import { SecurityPhrases } from "../../security/security-phrases/security-phrases.model";
import { LibraryUser } from "../models/library-user.model";
import { UserLibrary } from "../models/user-library.model";
import { LibraryBook } from "../models/library-book.model";
import { LibraryUserDto } from "./interfaces/library-user-dto.interface";
import { UserLibraryDto } from "./interfaces/user-library-dto.interface";
import { LibraryBookDto } from "./interfaces/library-book-dto.interface";

import { UserSecurityPhrasesOld } from "src/app/security/security-phrases/user-security-phrases-old.service";
import { LibrarySecurityPhrasesOld } from "src/app/security/security-phrases/library-security-phrases-old.service";
import { BookSecurityPhrasesOld } from "src/app/security/security-phrases/book-security-phrases-old.service";

import { ILibraryTranslationService } from "./interfaces/library-translation-service.interface";

import { TempLibraryBook } from "../models/temp-library-book.model";
import { TempLibraryUser } from "../models/temp-library-user.model";
import { TempUserLibrary } from "../models/temp-user-library.model";

@Injectable()
export class LibraryTranslationServiceOld implements ILibraryTranslationService {
  //NOTE: The Temp library models came after the introduction of the new translation service, so this method is not required
  translateTempLibraryUser(user: LibraryUser): TempLibraryUser {
    throw new Error("Method not supported.");
  }

  //NOTE: The Temp library models came after the introduction of the new translation service, so this method is not required
  translateTempUserLibrary(library: UserLibrary): TempUserLibrary {
    throw new Error("Method not supported.");
  }

  //NOTE: The Temp library models came after the introduction of the new translation service, so this method is not required
  translateTempLibraryBook(book: LibraryBook): TempLibraryBook {
    throw new Error("Method not supported.");
  }

  public translateLibraryUserDto(userName: string, user: LibraryUserDto, securityPhrases: SecurityPhrases): LibraryUser {
    const userSecurityPhrases = new UserSecurityPhrasesOld(securityPhrases);

    let translatedUser = LibraryUser.init();
    translatedUser.number = user.number;

    translatedUser.id = this.translateIdInfo(user.info, userSecurityPhrases.GetUserDtoIdInfoPass(user));

    translatedUser.userName = userName;
    translatedUser.number = user.number;
    translatedUser.email = user.email;

    translatedUser.firstName = this.translateText(user.firstName, userSecurityPhrases.GetUserDtoPassA(user));
    translatedUser.lastName = this.translateText(user.lastName, userSecurityPhrases.GetUserDtoPassB(user));

    const translatedNumber = this.translateText(user.validationCode, userSecurityPhrases.GetUserDtoPassC(user));
    translatedUser.hasValidationCode = translatedNumber === user.number.toString();

    translatedUser.createdOn = new Date(user.createdOn);
    translatedUser.updatedOn = new Date(user.updatedOn);

    //console.log(`[TranslationService].translateLibraryUserDto('${user.number}') -> `, JSON.stringify(translatedUser));
    return translatedUser.isValid()
      ? translatedUser
      : LibraryUser.init();
  }

  public translateUserLibraryDto(library: UserLibraryDto, securityPhrases: SecurityPhrases): UserLibrary {
    const librarySecurityPhrasesOld = new LibrarySecurityPhrasesOld(securityPhrases);

    let translatedLibrary = UserLibrary.init();
    translatedLibrary.location = library.location;

    translatedLibrary.id = this.translateIdInfo(library.info, librarySecurityPhrasesOld.GetInfoIdPass(library));

    translatedLibrary.location = library.location;

    translatedLibrary.name = this.translateText(library.name, librarySecurityPhrasesOld.GetLibraryDtoPassA(library));
    translatedLibrary.description = this.translateText(library.description, librarySecurityPhrasesOld.GetLibraryDtoPassB(library));

    const translatedLocation = this.translateText(library.validationCode, librarySecurityPhrasesOld.GetLibraryDtoPassC(library));
    translatedLibrary.hasValidationCode = translatedLocation === library.location.toString();

    translatedLibrary.createdOn = library.createdOn;
    translatedLibrary.updatedOn = library.updatedOn;

    // console.log('[TranslationService].translateUserLibraryDto() -> ', JSON.stringify(translatedLibrary));
    return translatedLibrary.isValid()
      ? translatedLibrary
      : UserLibrary.init();
  }

  public translateLibraryBookDto(book: LibraryBookDto, securityPhrases: SecurityPhrases): LibraryBook {
    const bookSecurityPhrasesOld = new BookSecurityPhrasesOld(securityPhrases);

    let translatedBook = LibraryBook.init();
    translatedBook.index = book.index;

    translatedBook.isArchived = book.isArchived;
    translatedBook.displayType = 'password';
    translatedBook.isEditable = false;
    translatedBook.isExpanded = false;

    translatedBook.id = this.translateIdInfo(book.info, bookSecurityPhrasesOld.GetInfoIdPass(book));

    translatedBook.title = this.translateText(book.title, bookSecurityPhrasesOld.GetBookDtoPassA(book));
    translatedBook.genre = this.translateText(book.genre, bookSecurityPhrasesOld.GetBookDtoPassB(book));
    translatedBook.reviews = this.translateText(book.reviews, bookSecurityPhrasesOld.GetBookDtoPassC(book));

    translatedBook.author = this.translateText(book.author, bookSecurityPhrasesOld.GetBookDtoPassD(book));
    translatedBook.editors = this.getTranslatedEditors(book.editors, bookSecurityPhrasesOld.GetBookDtoPassE(book));
    translatedBook.publisher = this.translateText(book.publisher, bookSecurityPhrasesOld.GetBookDtoPassF(book));

    translatedBook.isNewPublisher = false;
    translatedBook.publishedOn = book.publishedOn;

    translatedBook.createdOn = book.createdOn;
    translatedBook.updatedOn = book.updatedOn;

    // console.log('[TranslationService].translateLibraryBookDto() -> ', JSON.stringify(translatedBook));
    return translatedBook.isValid()
      ? translatedBook
      : LibraryBook.init();
  }

  public translateIdInfo(text: string, key: string): string {
    return this.translateText(text, key);
  }

  private getTranslatedEditors(editorInfo: string, key: string): string {
    //Note: because the editors were split by ('|' or ';') before, we need to keep splitting by all old values
    let translatedEditors = new Array<string>();
    if (editorInfo && editorInfo.length > 0) {
      let editors = editorInfo.split(/(?:\||;|\r|\n|\r\n)+/);
      editors.forEach((editor) => {
        let trimmedEditor = editor.trim();
        if (trimmedEditor.length > 0) {
          translatedEditors.push(this.translateText(trimmedEditor, key));
        }
      });
    }

    return translatedEditors.join('\r\n');
  }

  private translateText(text: string, key: string): string {
    try {
      return AES.decrypt(Util.encryptHeader + text, key).toString(enc.Utf8);
    }
    catch (e) {
      return Util.untranslatableValue;
    }
  }
}
