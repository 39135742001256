<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="/">
      <img class="fw-menu-logo-image-sm" src="assets/images/logo/AppLogo.png" height="100px" alt="App Logo">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" [routerLink]="['/']" rel="no-refresh">
            <span class="padded" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
              <i class="bi bi-house-fill"></i> Home</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/experience']" rel="no-refresh">
            <span class="padded" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
              <i class="bi bi-person-workspace"></i> Experience</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/projects']" rel="no-refresh">
            <span class="padded" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
              <i class="bi bi-archive-fill"></i> Projects</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/contact']" rel="no-refresh">
            <span class="padded" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
              <i class="bi bi-envelope-at-fill"></i> Contact</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/about-me']" rel="no-refresh">
            <span class="padded" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
              <i class="bi bi-file-person-fill"></i> About Me</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/library']" rel="no-refresh">
            <span class="padded" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
              <i class="bi bi-journals"></i> Library</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
