import { LibraryUser } from "src/app/library/models/library-user.model";
import { SecurityPhrases } from "./security-phrases.model";
import { LibraryUserDto } from "src/app/library/services/interfaces/library-user-dto.interface";
import { IUserSecurityPhrases } from "./user-security-phrases.interface";
import { IUserDtoSecurityPhrases } from "./user-dto-security-phrases.interface";

export class UserSecurityPhrasesOld implements IUserSecurityPhrases, IUserDtoSecurityPhrases {
  private _securityPhrases: SecurityPhrases;

  constructor(securityPhrases: SecurityPhrases) {
    this._securityPhrases = securityPhrases.isValid() ? securityPhrases : new SecurityPhrases();
  }

  GetUserPassA(user: LibraryUser): string { return this.GetPassA(user.number); }
  GetUserPassB(user: LibraryUser): string { return this.GetPassB(user.number); }
  GetUserPassC(user: LibraryUser): string { return this.GetPassC(user.number); }

  GetUserIdInfoPass(user: LibraryUser): string { return this.GetPassInfo(user.number); }

  GetUserDtoPassA(user: LibraryUserDto): string { return this.GetPassA(user.number); }
  GetUserDtoPassB(user: LibraryUserDto): string { return this.GetPassB(user.number); }
  GetUserDtoPassC(user: LibraryUserDto): string { return this.GetPassC(user.number); }

  GetUserDtoIdInfoPass(user: LibraryUserDto): string { return this.GetPassInfo(user.number); }

  private GetPassA(number: number): string { return `${number}-|${this._securityPhrases.level1}|-`; }
  private GetPassB(number: number): string { return `${number}-[${this._securityPhrases.level2}]-`; }
  private GetPassC(number: number): string { return `${number}-{${this._securityPhrases.level3}}-`; }

  private GetPassInfo(number: number): string { return `${number}-<${this._securityPhrases.level1}>-`; }
}
