<div class="container">
  <div class="row">
    <div class="col-md-8">
      <p>These are some of the great companies that I have had the opportunity to work at over the past few
        years. Some have continued as great businesses and enterprises, while others have faded into
        history.</p>

      <div class="row">
        <div *ngFor="let position of workExperiences" class="col-md-6">
          <h3>{{position.employer.name}}</h3>
          <p><b>{{position.title}}, {{position.startDate | date:'MMM yyyy'}} -
              {{position.endDate | date:'MMM yyyy'}}</b></p>

          <blockquote>
            <p *ngFor="let line of position.employer.description?.split('\r\n')">
              <span>{{line}}</span><br />
            </p>
            <cite>{{position.employer.name}}</cite>
          </blockquote>

          <div *ngIf="position.employer.url.length > 0" class="btn button">
            <a href="{{position.employer.url}}" target="_blank">Read more</a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="blogs" class="col-md-4">
      <div *ngFor="let item of blogs" class="sidebar">
        <div class="sidebar_item">
          <h2>{{item.title}}</h2>
          <h3>{{item.publishedOn | date:'MMM yyyy'}}</h3>
          <p>{{item.message}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
