<form [hidden]="userInfoIsSet" [formGroup]="newLibraryUserForm" (ngSubmit)="updateNewUserInfo()">

  <fieldset class="d-flex flex-column">
    <legend>New Library User</legend>

    <div class="row">
      <div class="col-sm-6 justify-content-center ">
        <label for="userName">User name: </label>
        <input class="fixWidthMedium" name="userName" type="text" placeholder="UserName" tabindex="1"
          formControlName="userName" #newUserNameInput autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 justify-content-center">
        <label for="firstName">First name: </label>
        <input class="fixWidthMedium" name="firstName" type="text" placeholder="First name" tabindex="2"
          formControlName="firstName" autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 justify-content-center">
        <label for="lastName">Last name: </label>
        <input class="fixWidthMedium" name="lastName" type="text" placeholder="Last name" tabindex="3"
          formControlName="lastName" autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 justify-content-center">
        <label for="email">Email: </label>
        <input class="fixWidthMedium" name="email" type="email" placeholder="Email" tabindex="4" formControlName="email"
          autocomplete="off" />
      </div>
    </div>

    <ng-container *ngIf="!userInfoIsSet">
      <div class="row">

        <div class="col-sm-3">
          <button class="btn btn-sm btn-outline-primary" type="submit"
            [disabled]="!newLibraryUserForm.valid || !newLibraryUserForm.dirty" aria-label="submit" tabindex="5">
            <i class="bi bi-arrow-right-square-fill"></i>
          </button>
        </div>

        <div class="col-sm-3">
          <button class="btn btn-sm btn-outline-warning" type="button" (click)="cancelNewUser()" aria-label="cancel"
            tabindex="6">
            <i class="bi bi-x-square-fill"></i>
          </button>
        </div>

      </div>
    </ng-container>

  </fieldset>
</form>

<ng-container *ngIf="userInfoIsSet">
  <div class="row">

    <div class="col-sm-6">
      <app-security-phrases (onChange)="onUserSecurityPhrasesChange($event)"></app-security-phrases>
    </div>

  </div>

  <div class="row">

    <div class="col-sm-6">
      <button class="btn btn-sm btn-outline-success" aria-label="Save user" type="button" (click)="saveNewUserRegistration()"
        [disabled]="!newLibraryUserForm.valid || !securityPhrases.isValid()" tabindex="91">
        <i class="bi bi-save2"></i>
      </button>
    </div>

    <div class="col-sm-6 float-end">
      <button class="btn btn-sm btn-outline-warning" aria-label="Cancel" type="button" (click)="cancelNewUser()" tabindex="92">
        <i class="bi bi-x-square-fill"></i>
      </button>
    </div>

  </div>

</ng-container>
