import { LibraryBook } from "src/app/library/models/library-book.model";
import { SecurityPhrases } from "./security-phrases.model";
import { LibraryBookDto } from "src/app/library/services/interfaces/library-book-dto.interface";
import { IBookDtoSecurityPhrases } from "./book-dto-security-phrases.interface";
import { IBookSecurityPhrases } from "./book-security-phrases.interface";

export class BookSecurityPhrasesOld implements IBookSecurityPhrases, IBookDtoSecurityPhrases {
  private _securityPhrases: SecurityPhrases;

  constructor(securityPhrases: SecurityPhrases) {
    this._securityPhrases = securityPhrases;
  }

  GetBookPassA(book: LibraryBook): string { return this.GetPassA(book.index); }
  GetBookPassB(book: LibraryBook): string { return this.GetPassB(book.index); }
  GetBookPassC(book: LibraryBook): string { return this.GetPassC(book.index); }
  GetBookPassD(book: LibraryBook): string { return this.GetPassD(book.index); }
  GetBookPassE(book: LibraryBook): string { return this.GetPassE(book.index); }
  GetBookPassF(book: LibraryBook): string { return this.GetPassF(book.index); }

  GetIdInfoPass(book: LibraryBook): string { return this.GetPassInfoId(book.index); }

  GetBookDtoPassA(book: LibraryBookDto): string { return this.GetPassA(book.index); }
  GetBookDtoPassB(book: LibraryBookDto): string { return this.GetPassB(book.index); }
  GetBookDtoPassC(book: LibraryBookDto): string { return this.GetPassC(book.index); }
  GetBookDtoPassD(book: LibraryBookDto): string { return this.GetPassD(book.index); }
  GetBookDtoPassE(book: LibraryBookDto): string { return this.GetPassE(book.index); }
  GetBookDtoPassF(book: LibraryBookDto): string { return this.GetPassF(book.index); }

  GetInfoIdPass(book: LibraryBookDto): string { return this.GetPassInfoId(book.index); }

  private GetPassA(index: number): string { return `${index}-|${this._securityPhrases.level1}|-`; }
  private GetPassB(index: number): string { return `${index}-[${this._securityPhrases.level2}]-`; }
  private GetPassC(index: number): string { return `${index}-{${this._securityPhrases.level3}}-`; }
  private GetPassD(index: number): string { return `(${index})(-|${this._securityPhrases.level1}|-)`; }
  private GetPassE(index: number): string { return `(${index})(-[${this._securityPhrases.level2}]-)`; }
  private GetPassF(index: number): string { return `(${index})(-{${this._securityPhrases.level3}}-)`; }

  private GetPassInfoId(index: number): string { return `${index}-<${this._securityPhrases.level3}>-`; }
}
