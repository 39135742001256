import { HttpClient,  HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { HttpClientErrorHandler } from './http-client-error-handler.service';
import { UserLibrarySecurityService } from '../../../security/user-library-security.service';

import { LibraryUserDto } from '../interfaces/library-user-dto.interface';
import { TempLibraryUserRevertedDto } from '../interfaces/temp-library-user-reverted-dto.interface';
import { TempUserLibraryRevertedDto } from '../interfaces/temp-user-library-reverted-dto.interface';
import { TempLibraryBookRevertedDto } from '../interfaces/temp-library-book-reverted-dto.interface';

const defaultDtoUser = {} as LibraryUserDto;

@Injectable()
export class TempLibraryUpdateSecurityPhrasesHttpClientService {

  constructor(
    private httpClient: HttpClient,
    private httpClientErrorHandlerService: HttpClientErrorHandler,
    private librarySecurityService: UserLibrarySecurityService) {
  }

  setTempUser(userId: string, userName: string, tempUserId: string, user: TempLibraryUserRevertedDto): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('tmpUid', `${this.librarySecurityService.EncryptValue(tempUserId)}`)
    };

    return this.httpClient.post(`${environment.apiUrl}/Lib/TempLibrary/User`, user, httpOptions).pipe(
      map((response: any): any => { return response; }),
      catchError(error => this.httpClientErrorHandlerService.handleError('TempLibrary Service', error))
    );
  }

  setTempLibrary(userId: string, userName: string, libraryId: string, tempUserId: string, tempLibraryId: string, library: TempUserLibraryRevertedDto): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('tmpUid', `${this.librarySecurityService.EncryptValue(tempUserId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(libraryId)}`)
        .set('tmpUlid', `${this.librarySecurityService.EncryptValue(tempLibraryId)}`)
    };

    return this.httpClient.post(`${environment.apiUrl}/Lib/TempLibrary/Library`, library, httpOptions).pipe(
      map((response: any): any => { return response; }),
      catchError(error => this.httpClientErrorHandlerService.handleError('TempLibrary Service', error))
    );
  }

  setTempBook(userId: string, userName: string, libraryId: string, bookId: string, tempUserId: string, tempLibraryId: string, tempBookId: string, book: TempLibraryBookRevertedDto): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('tmpUid', `${this.librarySecurityService.EncryptValue(tempUserId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(libraryId)}`)
        .set('tmpUlid', `${this.librarySecurityService.EncryptValue(tempLibraryId)}`)
        .set('bid', `${this.librarySecurityService.EncryptValue(bookId)}`)
        .set('tmpBid', `${this.librarySecurityService.EncryptValue(tempBookId)}`)
      };

    return this.httpClient.post(`${environment.apiUrl}/Lib/TempLibrary/Book`, book, httpOptions).pipe(
      map((response: any): any => { return response; }),
      catchError(error => this.httpClientErrorHandlerService.handleError('TempLibrary Service', error))
    );
  }

  acceptTempUpdates(userId: string, userName: string, tempUserId: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('tmpUid', `${this.librarySecurityService.EncryptValue(tempUserId)}`)
    };

    return this.httpClient.post(`${environment.apiUrl}/Lib/TempLibrary/Accept`, null, httpOptions).pipe(
      map((response: any): any => { return response; }),
      catchError(error => this.httpClientErrorHandlerService.handleError('TempLibrary Service', error))
    );
  }

  cancelTempUpdates(userId: string, userName: string, tempUserId: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('tmpUid', `${this.librarySecurityService.EncryptValue(tempUserId)}`)
    };

    return this.httpClient.post(`${environment.apiUrl}/Lib/TempLibrary/Cancel`, null, httpOptions).pipe(
      map((response: any): any => { return response; }),
      catchError(error => this.httpClientErrorHandlerService.handleError('TempLibrary Service', error))
    );
  }
}
