<form [formGroup]="libraryBookForm" (ngSubmit)="saveNewBook()">
  <div class="list-group-item list-group-item-action container new-book">

    <div class="row">

      <div class="col-1">
        <span class="padded">
          <button title="Save" type="submit" class="btn btn-sm btn-outline-success" tabindex="51"
            [disabled]="!libraryBookForm.valid || !libraryBookForm.dirty" alt="Save book">
            <i class="bi bi-save2"></i>
          </button>
        </span>
      </div>

      <div class="col-5"></div>

      <div class="col-5"></div>

      <div class="col-1">
        <button title="Cancel" type="button" class="btn btn-sm btn-outline-warning" tabindex="52"
          (click)="cancelNewBook()" alt="Cancel create">
          <i class="bi bi-x-square-fill"></i>
        </button>
      </div>

    </div>

    <hr size="1" />

    <div class="row">

      <div class="col-6">
        <label class="mr-2" for="bookTitle">{{label.title}}</label>
        <span class="padded">
          <input title="Title" class="fixWidthMedium" id="bookTitle" type="text" formControlName="title" tabindex="1" />
        </span>
      </div>

      <div class="col-6">
        <label class="mr-2" for="bookGenre">{{label.genre}}</label>
        <span class="padded">
          <input title="Genre" class="fixWidthMedium" id="bookGenre" type="text" formControlName="genre" tabindex="2" />
        </span>
      </div>

    </div>

    <div class="row">

      <div class="col-6">
        <label class="mr-2" for="bookAuthor">{{label.author}}</label>
        <span class="padded">
          <input title="Author" class="fixWidthMedium" id="bookAuthor" type="text" formControlName="author"
            tabindex="3" />
        </span>
        <span>
          <button title="Copy" type="button" class="btn btn-sm" (click)="copyBookAuthorToClipboard()"
            alt='Copy to clipboard'>
            <i class="bi bi-clipboard-check-fill"></i>
          </button>
        </span>
      </div>

      <div class="col-6">
        <label class="mr-2" for="bookPublisher">{{label.publisher}}</label>
        <span class="padded">
          <input title="Publisher" class="fixWidthMedium" id="bookPublisher" type="{{publisherDisplayType}}"
            formControlName="publisher" tabindex="4" />
        </span>
        <span>
          <button title="ShowHide" type="button" class="btn btn-sm" (click)="toggleInputType()" alt="Toggle input type">
            <i class="bi bi-eye-fill"></i>
          </button>
        </span>
        <span>
          <button title="Copy" type="button" class="btn btn-sm" (click)="copyBookPublisherToClipboard()"
            alt='Copy to clipboard'>
            <i class="bi bi-clipboard-check-fill"></i>
          </button>
        </span>
      </div>

    </div>

    <div class="row">

      <div class="col-6">
      </div>

      <div class="col-6">
        <label class="mr-2 multiLine align-top" for="bookReviews">{{label.reviews}}</label>
        <textarea title="Reviews" class="fixWidthMedium padded" id="bookReviews" formControlName="reviews" rows="4"
          tabindex="5"></textarea>
      </div>

    </div>

  </div>
</form>
