import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutMeComponent } from './about-me/about-me.component';
import { ContactComponent } from './contact/contact.component';
import { ProjectsComponent } from './projects/projects.component';
import { WorkExperienceComponent } from './work-experience/work-experience.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { EmailConfirmationComponent } from './library/user-email-confirmation/user-email-confirmation.component';
import { TheLibraryComponent } from './library/the-library/the-library.component';
import { RootComponent } from './root/root.component';
import { ProjectComponent } from './projects/project/project.component';
import { UpdateSecurityPhrasesComponent } from './library/update-security-phrases/update-security-phrases.component';
import { TestingComponent } from './security/testing/testing.component';

const routes: Routes = [
  {
     path: '', component: RootComponent,
     children: [
       { path: '', component: DashBoardComponent }
       ,{ path: 'experience', component: WorkExperienceComponent }
       ,{ path: 'projects', component: ProjectsComponent }
       ,{ path: 'projects/project-item/:id', component: ProjectComponent }
       ,{ path: 'contact', component: ContactComponent }
       ,{ path: 'about-me', component: AboutMeComponent }
       ,{ path: 'library', component: TheLibraryComponent }
       ,{ path: 'library/update-security-phrases', component: UpdateSecurityPhrasesComponent }
       ,{ path: 'email-confirmation', component: EmailConfirmationComponent }
       ,{ path: 'library/tester', component: TestingComponent }
     ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

function getRoutes(): Routes {
  return routes;
}

@NgModule({
  imports: [RouterModule.forRoot(getRoutes())],
  exports: [RouterModule]
})
export class AppRoutingModule { }
