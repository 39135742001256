import { Util } from "src/app/utils/utils";

export class TempLibraryUser
{
  id: string = Util.emptyGuid;
  sourceId: string = Util.emptyGuid;

  userName: string = '';
  number: number = 0;
  firstName: string = '';
  lastName: string = '';
  email: string = '';

  hasValidationCode = false;

  isValid(): boolean {
    return (this.id != "" && this.id != Util.emptyGuid)
      && Util.isValidLibraryFieldValue(this.userName)
      && Util.isValidLibraryFieldValue(this.firstName)
      && Util.isValidLibraryFieldValue(this.lastName)
      && Util.isValidLibraryFieldValue(this.email)
      && this.hasValidationCode;
  }

  static init(): TempLibraryUser{
    return TempLibraryUser.create(-1);
  }

  private static create(number: number): TempLibraryUser {
    const tempLibraryUser = new TempLibraryUser();
    tempLibraryUser.number = number;

    return tempLibraryUser;
  }
}
