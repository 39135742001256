import { Component, OnInit } from '@angular/core';

import { Blog } from '../blogs/blog.model';
import { BlogsService } from '../blogs/blogs.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss']
})
export class DashBoardComponent implements OnInit {

  isLoaded: boolean = false;
  blogs = new Array<Blog>();

  constructor(
    private notificationService: NotificationService,
    private blogsService: BlogsService) {
  }

  ngOnInit(): void {
    this.blogsService.getAll().subscribe({
      next: (data) => {
        this.blogs = data;
        this.isLoaded = true;
      },
      error: (_) => {
        this.notificationService.showError('An error occurred loading the latest blogs.', 'Blogs Service');
      }
    });
  }
}


