<div class="container">

  <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="/assets/images/home_1.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="/assets/images/home_2.jpg" class="d-block w-100" alt="...">
      </div>
    </div>
  </div>

  <div *ngIf="isLoaded" class="row">

    <div class="col-sm-8">
      <div class="row">
        <h1>Welcome</h1>
        <p>This is my introduction to the world. Because I enjoy being creative and being around creative
          people, I tend
          to shy away from the "straight-laced" and lean more towards the artistic side of life. It may seem
          in places
          a bit too unorthodox, but this afterall is a new release and will, like all software projects,
          change many
          times before it is no longer needed and is replaced by the "next best" social platform.</p>
      </div>

      <div class="row">

        <div class="content_image">
          <img src="/assets/images/content_image.jpg" alt="content image" />
        </div>

        <div class="image_text">
          <p>Visit as often as you like and provide your own feedback via the Contact page. I look forward
            to
            hearing about your thoughts and opinions as long as they stay in the realm of software
            development
            or live music performance techniques.</p>
        </div>
      </div>
    </div>

    <div *ngIf="blogs" class="col-md-4">
      <div *ngFor="let item of blogs" class="sidebar">
        <div class="sidebar_item">
          <h2>{{item.title}}</h2>
          <h3>{{item.publishedOn | date:'MMM yyyy'}}</h3>
          <p>{{item.message}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
