import { Component, OnInit } from '@angular/core';

import { BlogsService } from '../blogs/blogs.service';
import { Blog } from '../blogs/blog.model';
import { Hobby } from './hobby.model';
import { HobbyService } from "./hobby.service";
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {

  hobbiesLoaded: boolean = false;
  blogsLoaded: boolean = false;

  isLoaded: boolean = false;

  statement: string;
  blogs = new Array<Blog>();
  hobbies = new Array<Hobby>();

  constructor(
    private notificationService: NotificationService,
    private blogsService: BlogsService,
    private hobbyService: HobbyService) {

    this.statement = 'I\'m a compassionate husband, a fearless father, and a persistent worker. I enjoy the prospect of learning new technologies and new ways of developing software.';
  }

  ngOnInit(): void {
    this.hobbyService.getAll().subscribe({
      next: (data) => {
        this.hobbies = data;
        this.hobbiesLoaded = true;

        this.updateIsLoadedStatus();
      },
      error: (_) => {
        this.notificationService.showError('An error occurred loading the hobbies list.', 'Hobby Service');
      }
    });

    this.blogsService.getAll().subscribe({
      next: (data) => {
        this.blogs = data;
        this.blogsLoaded = true;

        this.updateIsLoadedStatus();
      },
      error: (_) => {
        this.notificationService.showError('An error occurred loading the the latest blogs.', 'Blogs Service');
      }
    });
  }

  private updateIsLoadedStatus(): void {
    this.isLoaded = this.hobbiesLoaded && this.blogsLoaded;
  }
}
