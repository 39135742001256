import { Component, WritableSignal } from '@angular/core';

import { LibraryService } from '../services/library.service';
import { BusyAsyncRequestExecutingService } from 'src/app/services/busy-async-request-executing.service';

@Component({
  selector: 'app-the-library',
  templateUrl: './the-library.component.html',
  styleUrls: ['./the-library.component.scss'],
})
export class TheLibraryComponent {

  constructor(
    public busyAsyncRequestExecutingService: BusyAsyncRequestExecutingService,
    private libraryService: LibraryService) {

      this.userSignInRequested = this.libraryService.userSignInRequested;
      this.userSignedIn = this.libraryService.userSignedIn;
      this.hasClientSessionInfo = this.libraryService.hasClientSessionInfo;
     }

  userSignInRequested: WritableSignal<boolean>;
  hasClientSessionInfo: WritableSignal<boolean>;
  userSignedIn: WritableSignal<boolean>;

  onCreateNewUser(): void {
    this.libraryService.requestNewUserAccount();
  }

  onEditUser(): void {
    this.libraryService.requestUpdateSignedInUser();
  }

  onDeleteUser(): void {
    this.libraryService.requestDeleteSignedInUser();
  }

  isUserSignedIn(): boolean {
    return this.userSignedIn();
  }
}
