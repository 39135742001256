<div class="row">

  <div class="col-1">
    <span>
      <button *ngIf="!book.isNewBook()" title="Expand" type="button" class="btn btn-sm btn-outline-info"
        (click)="toggleBookExpansion()" alt='Toggle Expansion'>
        <span *ngIf="book.isExpanded">
          <i class="bi bi-caret-down-fill"></i>
        </span>
        <span *ngIf="!book.isExpanded">
          <i class="bi bi-caret-right-fill"></i>
        </span>
      </button>
    </span>
  </div>

  <div class="col-5">
    <h3 class="mb-1">
      <span class="item-index float-left padded">{{book.index}}</span>
      <span *ngIf="isFresh" class="badge rounded-pill bg-primary padded">
        <i class="bi bi-emoji-smile-fill"></i>
      </span>
      <span *ngIf="isStale" class="badge rounded-pill bg-warning padded">
        <i class="bi bi-emoji-neutral-fill"></i>
      </span>
      <span *ngIf="isCritical" class="badge rounded-pill bg-danger padded">
        <i class="bi bi-emoji-frown-fill"></i>
      </span>
      <span class="padded"><strong>{{book.title}}</strong></span>
    </h3>
  </div>

  <div class="col-5">
    <h4 class="mb-1">
      <span class="padded"><strong>{{book.genre}}</strong></span>
    </h4>
  </div>

  <div class="col-1">
    <button *ngIf="!book.isEditable && !book.isNewBook()" title="Delete" type="button"
      class="btn btn-sm btn-outline-danger" (click)="deleteBook()" alt="Delete book">
      <i class="bi bi-trash3-fill"></i>
    </button>
  </div>
</div>
