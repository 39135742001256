import { Component, WritableSignal, effect} from '@angular/core';

import { UserLibrary } from '../models/user-library.model';
import { LibraryService } from '../services/library.service';

@Component({
  selector: 'app-user-library',
  templateUrl: './user-library.component.html',
  styleUrls: ['./user-library.component.scss']
})
export class UserLibraryComponent {

  constructor(private libraryService: LibraryService) {
    this.userLibrarySelected = this.libraryService.userLibrarySelected;
    this.editUserLibraryRequested = this.libraryService.updateUserLibraryRequested;
    this.selectedUserLibrary = this.libraryService.selectedUserLibrary;
  }

  userLibrarySelected: WritableSignal<boolean>;
  editUserLibraryRequested: WritableSignal<boolean>;
  selectedUserLibrary: WritableSignal<UserLibrary>;
}
