  <!-- <app-user-list-item *ngIf="isUserDisplayMode" [user]="currentUser()" (onRequestEdit)="requestUserEdit()"
    (onRequestClose)="requestCloseAccount()"></app-user-list-item> -->

<ng-container *ngIf="userEditRequested()">
  <app-user-editor [user]="currentUser()" (onChanged)="saveUserChanges($event)" (onCancellation)="cancelUserEdit()"></app-user-editor>
</ng-container>

<ng-container *ngIf="userSignInRequested()">
  <div class="row">
    <div class="col-sm-6">
      <app-user-signin [userName]="userName" [securityPhrases]="signInSecurityPhrases"
        (onConfirmation)="requestUserSignin($event)" (onCancellation)="cancelUserSignin()"></app-user-signin>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="userRegistrationIsRequested()">
  <app-user-creator [user]="newLibraryUser" [securityPhrases]="newUserSecurityPhrases"
    (onConfirmation)="saveUserRegistration($event)" (onCancellation)="cancelUserRegistration()"></app-user-creator>
</ng-container>
