<div class="projected-title container">

  <h2>
    <div class="flex-container">
      <div>
        <button *ngIf="busyAsyncRequestExecutingService.executingAsyncRequest | async" class="btn btn-primary"
          type="button" disabled>
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          <span>Busy...</span>
        </button>
      </div>

      <div>
        <span class="padded">Library App</span>
      </div>

      <ng-container *ngIf="hasClientSessionInfo()">

        <ng-container *ngIf="userSignInRequested()">
          <div class="dropdown">
            <button title="New user" class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-person-gear"></i>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" [routerLink]="" (click)="onCreateNewUser()">Create new user</a></li>
            </ul>
          </div>
        </ng-container>

        <ng-container *ngIf="userSignedIn()">
          <div class="dropdown">
            <button title="User context menu" class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-person-fill-gear"></i>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" [routerLink]="" (click)="onCreateNewUser()">Create new user</a></li>
              <li><a class="dropdown-item" (click)="onEditUser()">Edit user</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" [routerLink]="" (click)="onDeleteUser()">Delete user</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" routerLink="update-security-phrases">Update security phases</a></li>
              </ul>
          </div>
        </ng-container>

      </ng-container>
    </div>
  </h2>

  <hr>

  <ng-container *ngIf="hasClientSessionInfo()">

    <app-library-user></app-library-user>

    <app-user-libraries></app-user-libraries>

    <app-user-library-control></app-user-library-control>

    <app-library-books></app-library-books>

  </ng-container>
</div>
