import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

@Injectable()
export class BusyAsyncRequestExecutingService {

    public executingAsyncRequest = new BehaviorSubject<boolean>(false);

    constructor() {}
}
