import { Component, ViewChild, WritableSignal } from '@angular/core';

import { UserLibrary } from '../models/user-library.model';
import { LibraryService } from '../services/library.service';

@Component({
  selector: 'app-user-libraries',
  templateUrl: './user-libraries.component.html',
  styleUrls: ['./user-libraries.component.scss']
})
export class UserLibrariesComponent {

  @ViewChild('librarySelector') select: any;

  constructor(private libraryService: LibraryService) {
    this.userSignedIn = this.libraryService.userSignedIn;
    this.libraries = this.libraryService.userLibraries;
    this.userLibrarySelected = this.libraryService.userLibrarySelected;
    this.newUserLibraryRequested = this.libraryService.newUserLibraryRequested;
  }

  userSignedIn: WritableSignal<boolean>;
  libraries: WritableSignal<Array<UserLibrary>>;

  newUserLibraryRequested: WritableSignal<boolean>;
  userLibrarySelected: WritableSignal<boolean>;
  selectedLibrary: UserLibrary | undefined;

  refreshLibrary(): void {
    if (this.selectedLibrary === undefined) return;

    this.libraryService.setSelectedLibrary(this.selectedLibrary);
  }

  requestNewUserLibrary(): void {
    this.libraryService.requestNewUserLibrary();
  }

  onNewLibraryCancel(): void {
    this.libraryService.cancelNewUserLibrary();
  }

  onNewLibrarySave($event: UserLibrary): void {
    let newLibrary = $event;

    newLibrary.location = this.libraryService.nextLibraryLocation();

    this.libraryService.saveNewUserLibrary(newLibrary);
  }

  requestEditUserLibrary(): void {
    this.libraryService.requestUpdateUserLibrary();
  }

  onLibraryUpdated($event: UserLibrary): void {
    let updatedLibrary = $event;

    this.libraryService.updateUserLibrary(updatedLibrary);
  }

  requestDeleteUserLibrary(): void {
    this.libraryService.requestDeleteUserLibrary()
  }

  onLibraryDelete(): void {
    this.libraryService.deleteUserLibrary();

    this.selectedLibrary = undefined;
    this.libraryService.setSelectedLibrary(UserLibrary.init());
  }
}
