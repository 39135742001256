import { UserLibrary } from "src/app/library/models/user-library.model";
import { SecurityPhrases } from "./security-phrases.model";
import { UserLibraryDto } from "src/app/library/services/interfaces/user-library-dto.interface";
import { ILibrarySecurityPhrases } from "./library-security-phrases.interface";
import { ILibraryDtoSecurityPhrases } from "./library-dto-security-phrases.interface";

export class LibrarySecurityPhrasesOld implements ILibrarySecurityPhrases, ILibraryDtoSecurityPhrases {
  private _securityPhrases: SecurityPhrases;

  constructor(securityPhrases: SecurityPhrases) {
    this._securityPhrases = securityPhrases.isValid() ? securityPhrases : new SecurityPhrases();
  }

  GetLibraryPassA(library: UserLibrary): string { return this.GetPassA(library.location); }
  GetLibraryPassB(library: UserLibrary): string { return this.GetPassB(library.location); }
  GetLibraryPassC(library: UserLibrary): string { return this.GetPassC(library.location); }

  GetIdInfoPass(library: UserLibrary): string { return this.GetPassInfo(library.location); }

  GetLibraryDtoPassA(library: UserLibraryDto): string { return this.GetPassA(library.location); }
  GetLibraryDtoPassB(library: UserLibraryDto): string { return this.GetPassB(library.location); }
  GetLibraryDtoPassC(library: UserLibraryDto): string { return this.GetPassC(library.location); }

  GetInfoIdPass(library: UserLibraryDto): string { return this.GetPassInfo(library.location); }

  private GetPassA(location: number): string { return `${location}-|${this._securityPhrases.level1}|-`; }
  private GetPassB(location: number): string { return `${location}-[${this._securityPhrases.level2}]-`; }
  private GetPassC(location: number): string { return `${location}-{${this._securityPhrases.level3}}-`; }

  private GetPassInfo(location: number): string { return `${location}-<${this._securityPhrases.level2}>-`; }
}
