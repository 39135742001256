import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ClipboardService } from 'ngx-clipboard';

import { LibraryBook } from '../../models/library-book.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-book-editor',
  templateUrl: './book-editor.component.html',
  styleUrls: ['./book-editor.component.scss']
})
export class BookEditorComponent {
  _book: LibraryBook = LibraryBook.init();
  _originalPublisher: string = '';

  constructor(private clipboardService: ClipboardService) { }

  get book(): LibraryBook {
    return this._book;
  }
  @Input() set book(book: LibraryBook) {
    this._book = book;
    this._originalPublisher = this._book.publisher;
    this.initializeLibraryBookForm();
  }

  @Output() onChanged = new EventEmitter<LibraryBook>();
  @Output() onEditorsChanged = new EventEmitter<LibraryBook>();

  label = LibraryBook.getLabels();
  isEditorsEditable: boolean = false;

  libraryBookForm = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    genre: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    author: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    publisher: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    editors: new UntypedFormControl(''),
    reviews: new UntypedFormControl('')
  });

  toggleEditBook(): void {
    this.book.isEditable = !this.book.isEditable;

    if (this.book.isEditable && this.isEditorsEditable) {
      this.isEditorsEditable = false;
    }

    // NOTE: always expand when in edit mode
    if (this.book.isEditable && !this.book.isExpanded) {
      this.book.isExpanded = true;
    }

    this.setFormControlsState();
  }

  updateBook(): void {
    this.updateBookDetails();

    this.book.isEditable = false;
    this.setFormControlsState();

    this.onChanged.emit(this.book);
  }

  enableEditorsUpdate(): void {
    this.isEditorsEditable = true;
    this.setFormControlsState();
  }

  updateEditors(): void {
    this.updateEditorsListing();

    this.isEditorsEditable = false;
    this.setFormControlsState();

    this.onEditorsChanged.emit(this.book);
  }

  cancelEditorsUpdate(): void {
    this.isEditorsEditable = false;
    this.setFormControlsState();
  }

  cancelEdit(): void {
    this.book.isEditable = false;
    this.setFormControlsState();
  }

  copyPropertyToClipboard(content: string): void {
    this.clipboardService.copyFromContent(content);
  }

  toggleInputType(): void {
    this.book.displayType = Util.togglePasswordFieldDisplay(this.book.displayType);
  }

  private updateBookDetails(): void {
    this._book.title = this.libraryBookForm.controls['title'].value;
    this._book.genre = this.libraryBookForm.controls['genre'].value;
    this._book.author = this.libraryBookForm.controls['author'].value;
    this._book.publisher = this.libraryBookForm.controls['publisher'].value;

    this.updateEditorsListing();

    this._book.reviews = this.libraryBookForm.controls['reviews'].value;
  }

  private updateEditorsListing(): void {
    this._book.editors = this.libraryBookForm.controls['editors'].value;

    this._book.isNewPublisher = this._originalPublisher !== this._book.publisher;
    if (this._book.isNewPublisher) {
      this._book.addEditor(this._originalPublisher);
      this.libraryBookForm.controls['editors'].setValue(this._book.editors);

      this._originalPublisher = this._book.publisher;
    }
  }

  private initializeLibraryBookForm(): void {
    this.libraryBookForm.controls['title'].setValue(this._book.title);
    this.libraryBookForm.controls['genre'].setValue(this._book.genre);
    this.libraryBookForm.controls['author'].setValue(this._book.author);
    this.libraryBookForm.controls['publisher'].setValue(this._book.publisher);
    this.libraryBookForm.controls['editors'].setValue(this._book.editors);
    this.libraryBookForm.controls['reviews'].setValue(this._book.reviews);

    this.setFormControlsState();
  }

  private setFormControlsState(): void {
    if (this._book.isEditable) {
      this.enableFormControls();
    } else {
      this.disableFormControls();
    }

    if (this.isEditorsEditable) {
      this.enableFormEditorControl();
    } else {
      this.disableFormEditorControl();
    }
  }

  private disableFormControls(): void {
    this.libraryBookForm.controls['title'].disable();
    this.libraryBookForm.controls['genre'].disable();
    this.libraryBookForm.controls['author'].disable();
    this.libraryBookForm.controls['publisher'].disable();
    this.libraryBookForm.controls['reviews'].disable();
  }

  private enableFormControls(): void {
    this.libraryBookForm.controls['title'].enable();
    this.libraryBookForm.controls['genre'].enable();
    this.libraryBookForm.controls['author'].enable();
    this.libraryBookForm.controls['publisher'].enable();
    this.libraryBookForm.controls['reviews'].enable();

    this.disableFormEditorControl();
  }

  private disableFormEditorControl(): void {
    this.libraryBookForm.controls['editors'].disable();
  }

  private enableFormEditorControl(): void {
    this.libraryBookForm.controls['editors'].enable();
  }
}
