<form [hidden]="hasValidUserName" [formGroup]="libraryUserNameForm" (ngSubmit)="setUserName()">

  <div class="row">
    <div class="col-12 mb-3">
      <span>Sign-In with existing account:</span>
    </div>
  </div>

  <div class="row">
    <div class="input-group col-12 mb-3">
      <input #userNameInput class="form-control" type="text" placeholder="Enter a user name" tabindex="1"
        formControlName="userName" autocomplete="off" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="submit" id="button-addon2"
        [disabled]="!libraryUserNameForm.valid || !libraryUserNameForm.dirty" aria-label="Submit" tabindex="2">Set</button>
      </div>
    </div>
  </div>

</form>

<!-- candidate for a new control -->
<ng-container *ngIf="hasValidUserName">
  <div class="row">
    <div class="col-12 mb-3">
      <span>Sign-In with existing account:</span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mb-3">
      <input title="UserName" class="form-control" type="text" [ngModel]="userName" readonly />
    </div>
  </div>

  <div class="row">
    <div class="input-group col-12 mb-3">
      <app-security-phrases [securityPhrases]="securityPhrases"
        (onChange)="onSecurityPhrasesChange($event)"></app-security-phrases>
    </div>
  </div>

  <div class="row">

    <div class="col-4">
      <button class="btn btn-sm btn-outline-primary" aria-label="Sign-In" type="button" (click)="signInUser()" [disabled]="!hasValidSecurityPhrases"
        tabindex="91">
        <i class="bi bi-arrow-right-square-fill"></i>
      </button>
    </div>

    <div class="col-4">
      <button class="btn btn-sm btn-outline-warning" aria-label="Cancel" type="button" (click)="signInCancel()" tabindex="92">
        <i class="bi bi-x-square-fill"></i>
      </button>
    </div>

  </div>

</ng-container>
