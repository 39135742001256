import { Component, OnInit } from '@angular/core';

import { Blog } from '../blogs/blog.model';
import { BlogsService } from '../blogs/blogs.service';
import { ProjectsService } from './projects.service';
import { Project } from './project.model';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  isLoaded: boolean = false;
  blogsLoaded: boolean = false;
  projectsLoaded: boolean = false;

  blogs = new Array<Blog>();
  projects = new Array<Project>();

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private blogsService: BlogsService,
    private projectsService: ProjectsService) {
  }

  ngOnInit(): void {
    this.blogsService.getAll().subscribe({
      next: (data) => {
        this.blogs = data;
        this.blogsLoaded = true;
        this.checkLoadStatus();
      },
      error: (_) => {
        this.notificationService.showError('There was an error loading the latest blogs.', 'Blog Service');
      }});

    this.projectsService.getAll().subscribe({
      next: (data) => {
        this.projects = data;
        this.projectsLoaded = true;
        this.checkLoadStatus();
      },
      error: (_) => {
        this.notificationService.showError('There was an error loading the project list.', 'Project Service');
      }});
  }

  checkLoadStatus() {
    this.isLoaded = this.blogsLoaded && this.projectsLoaded;
  }

  gotoProject(projectId: string): void {
    this.router.navigate(['projects/project-item/', projectId]);
  }
}
