import { Injectable } from "@angular/core";

import { enc, AES } from 'crypto-js';
import { v4 as uuid } from 'uuid';

import { Util } from "src/app/utils/utils";
import { SecurityPhrases } from "../../security/security-phrases/security-phrases.model";
import { UserSecurityPhrasesHelperService } from "src/app/security/security-phrases/user-security-phrases.service";
import { LibrarySecurityPhrasesHelperService } from "src/app/security/security-phrases/library-security-phrases.service";
import { BookSecurityPhrasesHelperService } from "src/app/security/security-phrases/book-security-phrases.service";
import { ILibraryTranslationService } from "./interfaces/library-translation-service.interface";

import { LibraryUser } from "../models/library-user.model";
import { UserLibrary } from "../models/user-library.model";
import { LibraryBook } from "../models/library-book.model";

import { LibraryUserDto } from "./interfaces/library-user-dto.interface";
import { UserLibraryDto } from "./interfaces/user-library-dto.interface";
import { LibraryBookDto } from "./interfaces/library-book-dto.interface";

import { TempLibraryUser } from "../models/temp-library-user.model";
import { TempLibraryBook } from "../models/temp-library-book.model";
import { TempUserLibrary } from "../models/temp-user-library.model";

@Injectable()
export class LibraryTranslationService implements ILibraryTranslationService {
  translateTempLibraryUser(user: LibraryUser): TempLibraryUser {
    let translatedUser = TempLibraryUser.init();
    translatedUser.id = uuid();
    translatedUser.sourceId = user.id;
    translatedUser.userName = user.userName;
    translatedUser.number = user.number;
    translatedUser.firstName = user.firstName;
    translatedUser.lastName = user.lastName;
    translatedUser.email = user.email;
    translatedUser.hasValidationCode = user.hasValidationCode;

    return translatedUser;
  }

  translateTempUserLibrary(library: UserLibrary): TempUserLibrary {
    let translatedLibrary = TempUserLibrary.init();

    translatedLibrary.id = uuid();
    translatedLibrary.sourceId = library.id;
    translatedLibrary.location = library.location;
    translatedLibrary.name = library.name;
    translatedLibrary.description = library.description;
    translatedLibrary.hasValidationCode = library.hasValidationCode;

    return translatedLibrary;
  }

  translateTempLibraryBook(book: LibraryBook): TempLibraryBook {
    let translatedBook = TempLibraryBook.init();

    translatedBook.id = uuid();
    translatedBook.sourceId = book.id;
    translatedBook.index = book.index;
    translatedBook.title = book.title;
    translatedBook.genre = book.genre;
    translatedBook.author = book.author;
    translatedBook.publisher = book.publisher;
    translatedBook.isNewPublisher = book.isNewPublisher;
    translatedBook.publishedOn = book.publishedOn;
    translatedBook.editors = book.editors;
    translatedBook.reviews = book.reviews;
    translatedBook.isArchived = book.isArchived;
    translatedBook.createdOn = book.createdOn;
    translatedBook.updatedOn = book.updatedOn;

    return translatedBook;
  }

  public translateLibraryUserDto(userName: string, user: LibraryUserDto, securityPhrases: SecurityPhrases): LibraryUser {
    const userSecurityPhrases = new UserSecurityPhrasesHelperService(securityPhrases);

    let translatedUser = LibraryUser.init();
    translatedUser.number =user.number;

    translatedUser.id = this.translateIdInfo(user.info, userSecurityPhrases.GetUserDtoIdInfoPass(user));

    translatedUser.userName = userName;
    translatedUser.number = user.number;
    translatedUser.email = user.email;

    translatedUser.firstName = this.translateText(user.firstName, userSecurityPhrases.GetUserDtoPassA(user));
    translatedUser.lastName = this.translateText(user.lastName, userSecurityPhrases.GetUserDtoPassB(user));

    const translatedNumber = this.translateText(user.validationCode, userSecurityPhrases.GetUserDtoPassC(user));
    translatedUser.hasValidationCode = translatedNumber === user.number.toString();

    translatedUser.createdOn = new Date(user.createdOn);
    translatedUser.updatedOn = new Date(user.updatedOn);

    //console.log(`[TranslationService].translateLibraryUserDto('${user.number}') -> `, JSON.stringify(translatedUser));
    return translatedUser.isValid()
      ? translatedUser
      : LibraryUser.init();
  }

  public translateUserLibraryDto(library: UserLibraryDto, securityPhrases: SecurityPhrases): UserLibrary {
    const librarySecurityPhrases = new LibrarySecurityPhrasesHelperService(securityPhrases);

    let translatedLibrary = UserLibrary.init();
    translatedLibrary.location = library.location;

    translatedLibrary.id = this.translateIdInfo(library.info, librarySecurityPhrases.GetInfoIdPass(library));

    translatedLibrary.location = library.location;

    translatedLibrary.name = this.translateText(library.name, librarySecurityPhrases.GetLibraryDtoPassA(library));
    translatedLibrary.description = this.translateText(library.description, librarySecurityPhrases.GetLibraryDtoPassB(library));

    const translatedLocation = this.translateText(library.validationCode, librarySecurityPhrases.GetLibraryDtoPassC(library));
    translatedLibrary.hasValidationCode = translatedLocation === library.location.toString();

    translatedLibrary.createdOn = library.createdOn;
    translatedLibrary.updatedOn = library.updatedOn;

    // console.log('[TranslationService].translateUserLibraryDto() -> ', JSON.stringify(translatedLibrary));
    return translatedLibrary.isValid()
      ? translatedLibrary
      : UserLibrary.init();
  }

  public translateLibraryBookDto(book: LibraryBookDto, securityPhrases: SecurityPhrases): LibraryBook {
    const bookSecurityPhrases = new BookSecurityPhrasesHelperService(securityPhrases);

    let translatedBook = LibraryBook.init();
    translatedBook.index = book.index;

    translatedBook.isArchived = book.isArchived;
    translatedBook.displayType = 'password';
    translatedBook.isEditable = false;
    translatedBook.isExpanded = false;

    translatedBook.id = this.translateIdInfo(book.info, bookSecurityPhrases.GetInfoIdPass(book));

    translatedBook.title = this.translateText(book.title, bookSecurityPhrases.GetBookDtoPassA(book));
    translatedBook.genre = this.translateText(book.genre, bookSecurityPhrases.GetBookDtoPassB(book));
    translatedBook.reviews = this.translateText(book.reviews, bookSecurityPhrases.GetBookDtoPassC(book));

    translatedBook.author = this.translateText(book.author, bookSecurityPhrases.GetBookDtoPassD(book));
    translatedBook.editors = this.getTranslatedEditors(book, book.editors, bookSecurityPhrases.GetBookDtoPassF(book), bookSecurityPhrases.GetBookDtoPassE(book));
    translatedBook.publisher = this.translateText(book.publisher, bookSecurityPhrases.GetBookDtoPassF(book));

    translatedBook.isNewPublisher = false;
    translatedBook.publishedOn = book.publishedOn;

    translatedBook.createdOn = book.createdOn;
    translatedBook.updatedOn = book.updatedOn;

    // console.log('[TranslationService].translateLibraryBookDto() -> ', JSON.stringify(translatedBook));
    return translatedBook.isValid()
      ? translatedBook
      : LibraryBook.init();
  }

  public translateIdInfo(text: string, key: string): string {
    return this.translateText(text, key);
  }

  private getTranslatedEditors(book: LibraryBookDto, editorInfo: string, key1: string, key2: string): string {
    //Note: because the editors were split by ('|' or ';') before, we need to keep splitting by all old values
    let translatedEditors = new Array<string>();
    if (editorInfo && editorInfo.length > 0) {
      let editors = editorInfo.split(/(?:\||;|\r|\n|\r\n)+/);
      editors.forEach((editor) => {
        let trimmedEditor = editor.trim();
        if (trimmedEditor.length > 0) {
          //Note: because the editors are actually managed by the backend, the keys need to match with publisher
          let translation = this.translateText(trimmedEditor, key1);
          if (!Util.isValidLibraryFieldValue(translation)) {
            translation = this.translateText(trimmedEditor, key2);
            console.log(`Book[${book.index}] Editors require being updated.`);
          }
          translatedEditors.push(translation);
        }
      });
    }

    return translatedEditors.join('\r\n');
  }

  private translateText(text: string, key: string): string {
    try {
      return AES.decrypt(Util.encryptHeader + text, key).toString(enc.Utf8);
    }
    catch (e) {
      return Util.untranslatableValue;
    }
  }
}
