<hr size="1" />
<form [formGroup]="libraryBookForm" (ngSubmit)="updateBook()">

  <div class="row">

    <div class="col-1">
      <span class="padded">
        <button title="Edit" *ngIf="!book.isEditable && !isEditorsEditable" type="button" class="btn btn-sm btn-outline-primary"
          (click)="toggleEditBook()" alt="Edit book">
          <i class="bi bi-pencil-square"></i>
        </button>
        <button title="Update" *ngIf="book.isEditable && !isEditorsEditable" type="submit" class="btn btn-sm btn-outline-success"
          alt="Update book">
          <i class="bi bi-save2"></i>
        </button>
      </span>
    </div>

    <div class="col-5"></div>

    <div class="col-5"></div>

    <div class="col-1">
      <button title="Cancel" *ngIf="book.isEditable" type="button" class="btn btn-sm btn-outline-warning"
        (click)="cancelEdit()" alt="Cancel update">
        <i class="bi bi-x-square-fill"></i>
      </button>
    </div>

  </div>

  <div class="row">

    <div class="col-6">
      <label class="mr-2" for="bookTitle">{{label.title}}</label>
      <span>
        <input title="Title" class="fixWidthMedium" id="bookTitle" type="text" formControlName="title" />
      </span>
    </div>

    <div class="col-6">
      <label class="mr-2" for="bookGenre">{{label.genre}}</label>
      <span>
        <input title="Genre" class="fixWidthMedium" id="bookGenre" type="text" formControlName="genre" />
      </span>
    </div>

  </div>

  <div class="row">

    <div class="col-6">
      <label class="mr-2" for="bookAuthor">{{label.author}}</label>
      <span>
        <input title="Author" class="fixWidthMedium" id="bookAuthor" type="text" formControlName="author" />
      </span>
      <span>
        <button title="Copy" type="button" class="btn btn-sm" (click)="copyPropertyToClipboard(book.author)"
          alt='Copy to clipboard'>
          <i class="bi bi-clipboard-check-fill"></i>
        </button>
      </span>
    </div>

    <div class="col-6">
      <label class="mr-2" for="bookPublisher">{{label.publisher}}</label>
      <span>
        <input title="Publisher" class="fixWidthMedium" id="bookPublisher" formControlName="publisher" type="{{book.displayType}}" />
      </span>
      <span>
        <button title="ShowHide" type="button" class="btn btn-sm" (click)="toggleInputType()" alt="Toggle input type">
          <i class="bi bi-eye-fill"></i>
        </button>
      </span>
      <span>
        <button title="Copy" type="button" class="btn btn-sm" (click)="copyPropertyToClipboard(book.publisher)"
          alt='Copy to clipboard'>
          <i class="bi bi-clipboard-check-fill"></i>
        </button>
      </span>
      <p>
        <span class="float-right text-muted">published on: {{book.publishedOn | date: 'yyyy.MM.dd HH:mm'}}</span>
      </p>
    </div>

  </div>

  <div class="row">

    <div class="col-6">
      <span class="padded wrapped align-top" *ngIf="!book.isEditable">
        <button title="EnableEditorsUpdate" *ngIf="!isEditorsEditable" type="button" class="btn btn-sm btn-primary"
          (click)="enableEditorsUpdate()" alt="Enable book editors">
          <i class="bi bi-pencil-square"></i>
        </button>
        <button title="UpdateEditors" *ngIf="isEditorsEditable" type="button" class="btn btn-sm btn-success"
          (click)="updateEditors()" alt="Update book editors">
          <i class="bi bi-save2"></i>
        </button>
      </span>
      <span class="wrapped align-top">
        <label class="mr-2 multiLine" for="bookEditors">{{label.editors}}</label>
      </span>
      <span class="padded wrapped align-top" *ngIf="!book.isEditable">
        <button title="CancelUpdateEditors" *ngIf="isEditorsEditable" type="button" class="btn btn-sm btn-warning"
        (click)="cancelEditorsUpdate()" alt="Cancel book editors update">
        <i class="bi bi-x-square-fill"></i>
      </button>
      </span>

      <textarea title="Editors" class="fixWidthMedium" id="bookEditors" formControlName="editors" rows="4"></textarea>
    </div>

    <div class="col-6">
      <label class="mr-2 multiLine align-top" for="bookReviews">{{label.reviews}}</label>
      <textarea title="Reviews" class="fixWidthMedium" id="bookReviews" formControlName="reviews" rows="4"></textarea>
    </div>

  </div>

  <div class="row">

    <div class="col-6">
      <span class="float-left text-muted">created on: {{book.createdOn | date: 'yyyy.MM.dd HH:mm'}}</span>
    </div>

    <div class="col-6">
      <span class="float-right text-muted">modified on: {{book.updatedOn | date: 'yyyy.MM.dd HH:mm'}}</span>
    </div>
  </div>
</form>
