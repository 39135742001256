<fieldset class="border rounded-3 p-3">
  <legend class="legend-sm float-none w-auto px-3">Security phrases:</legend>
  <form [formGroup]="securityPhrasesForm">
    <div class="row justify-content-md-center">

      <div class="col-md-auto">
        <label for="SecurityPhrase1">Level 1: </label>
        <span>
          <input id="SecurityPhrase1" type="{{securityPhrase1DisplayType}}" title="Level 1"
            formControlName="securityPhrases1" disable readonly />
        </span>
        <span>
          <button title="ShowHide" type="button" class="btn btn-sm" (click)="toggleSecurityPhrase1InputType()"
            alt="Toggle input type">
            <i class="bi bi-eye-fill"></i>
          </button>
        </span>

      </div>

      <div class="col-md-auto">
        <label for="SecurityPhrase2">Level 2: </label>
        <span>
          <input id="SecurityPhrase2" type="{{securityPhrase2DisplayType}}" title="Level 2"
            formControlName="securityPhrases2" disable readonly />
        </span>
        <span>
          <button title="ShowHide" type="button" class="btn btn-sm" (click)="toggleSecurityPhrase2InputType()"
            alt="Toggle input type">
            <i class="bi bi-eye-fill"></i>
          </button>
        </span>
      </div>

      <div class="col-md-auto">
        <label class="col-form-label" for="SecurityPhrase3">Level 3: </label>
        <span>
          <input id="SecurityPhrase3" type="{{securityPhrase3DisplayType}}" title="Level 3"
            formControlName="securityPhrases3" disable readonly />
        </span>
        <span>
          <button title="ShowHide" type="button" class="btn btn-sm" (click)="toggleSecurityPhrase3InputType()"
            alt="Toggle input type">
            <i class="bi bi-eye-fill"></i>
          </button>
        </span>
      </div>
    </div>
  </form>
</fieldset>
