import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';

import { NotificationService } from 'src/app/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class HttpClientErrorHandler {

  constructor(private notificationService: NotificationService) { }

  handleError(serviceName: string, error: any): Observable<any> {
    //TODO: Trap specific errors to show a more useful message
    console.log(`[HttpClientErrorHandler] ${serviceName} request error: `, JSON.stringify(error));

    //Display a generic error message
    this.notificationService.showError('Request could not be completed successfully.', serviceName);
    return EMPTY;
  }
}
