import { Component, WritableSignal, effect } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { UserLibrary } from '../../models/user-library.model';
import { LibraryService } from '../../services/library.service';

@Component({
  selector: 'app-library-editor',
  templateUrl: './library-editor.component.html',
  styleUrls: ['./library-editor.component.scss']
})
export class LibraryEditorComponent {

  constructor(private libraryService: LibraryService) {
    this.libraryForm.controls['location'].disable();
    this.selectedLibrary = this.libraryService.selectedUserLibrary;
    this.userLibrarySelected = this.libraryService.userLibrarySelected;

    effect(() => {
      const selectedLibrary = this.selectedLibrary();
      this.updateFormControls(selectedLibrary);
    });
  }

  selectedLibrary: WritableSignal<UserLibrary>;
  userLibrarySelected: WritableSignal<boolean>;

  libraryForm = new UntypedFormGroup({
    location: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    description: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
  });

  updateLibrary(): void {
    const currentLibrary = this.selectedLibrary();
    currentLibrary.name = this.libraryForm.controls['name'].value;
    currentLibrary.description = this.libraryForm.controls['description'].value;

    this.libraryService.updateUserLibrary(currentLibrary);
  }

  cancelEdit(): void {
    this.libraryService.cancelUpdateUserLibrary();
  }

  private updateFormControls(selectedLibrary: UserLibrary): void {
    this.libraryForm.controls['location'].setValue(selectedLibrary.location);
    this.libraryForm.controls['name'].setValue(selectedLibrary.name);
    this.libraryForm.controls['description'].setValue(selectedLibrary.description);
  }
}
