<div class="projected-title container">
  <ng-container *ngIf="!hasTempClientSessionInfo()">
    <p>There is a problem getting the temp client session info!</p>
  </ng-container>

  <ng-container *ngIf="hasTempClientSessionInfo()">

    <ng-container *ngIf="!userSignedIn()">
      <div class="row">
        <div class="col-sm-6">
          <p>You must first sign-in before attempting to change you security phrases.</p>
          <a href="" routerLink="/library">
            <button title="Sign-in" class="btn btn-primary" type="button">
              <i class="bi bi-person-fill-gear"></i> Sign-In
            </button>
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="userSignedIn()">
      <ng-container *ngIf="!hasValidTempSecurityPhrases()">
        <div class="row">

          <div class="col-sm-3">
            <app-security-phrases [securityPhrases]="securityPhrases"
              (onChange)="onUserSecurityPhrasesChange($event)"></app-security-phrases>
          </div>

        </div>

        <div class="row">

          <div class="col-sm-1">
            <button class="btn btn-sm btn-outline-success" aria-label="Save user" type="button"
              (click)="setUpdateSecurityPhrases()" [disabled]="hasInvalidSecurityPhrases" tabindex="91">
              <i class="bi bi-save2"></i>
            </button>
          </div>

          <div class="col-sm-2 float-end">
            <a href="" routerLink="/library">
              <button class="btn btn-sm btn-outline-warning" aria-label="Cancel" type="button"
                (click)="cancelUpdateSecurityPhrases()" tabindex="92">
                <i class="bi bi-x-square-fill"></i>
              </button>
            </a>
          </div>

        </div>
      </ng-container>

      <ng-container *ngIf="hasValidTempSecurityPhrases()">
        <app-temp-security-phrases></app-temp-security-phrases>
      </ng-container>

      <ng-container *ngIf="hasValidTempSecurityPhrases()">

        <ng-container *ngIf="!hasTempUser()">
          <div class="row">

            <div class="col-sm-3">
              <button class="btn btn-sm btn-outline-success" aria-label="Start Update" type="button"
                (click)="startSecurityPhraseUpdate()" tabindex="93">
                <i class="bi bi-save2"></i> Start Update
              </button>
            </div>

          </div>
        </ng-container>

        <ng-container *ngIf="hasTempUser()">
          <div class="row">

            <div class="col-sm-4">
              <div class="row">
                <app-temp-library-user></app-temp-library-user>
              </div>

              <div class="row">
                <ng-container *ngIf="hasCurrentTempLibrary()">
                  <app-temp-user-library></app-temp-user-library>
                </ng-container>
              </div>

            </div>

            <div class="col-sm-8">

              <ng-container *ngIf="hasTempLibraries()">

                <div class="row">
                  <div class="col-md-2">
                    <button *ngIf="busyAsyncRequestExecutingService.executingAsyncRequest | async"
                      class="btn btn-primary" type="button" disabled>
                      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      <span>Busy...</span>
                    </button>
                  </div>

                  <div class="col-md-4">
                    <select title="LibrarySelector" id="librarySelector" class="form-select form-control"
                      [(ngModel)]="selectedLibrary" (change)="refreshLibrary()">
                      <option disabled default [ngValue]="undefined">Select a library</option>
                      <option *ngFor="let library of tempLibraries()" [ngValue]="library">
                        <span>[{{library.location}}]</span>
                        <span>{{library.name}}</span>
                      </option>
                    </select>
                  </div>

                  <div class="col-sm-3">
                    <button title="Accept-Update" class="btn btn-outline-primary" type="button" [disabled]="!tempUserConfirmed() || !allTempLibrariesConfirmed()" (click)="AcceptUpdate()">
                      <i class="bi bi-save2"></i> Accept
                    </button>
                  </div>

                  <div class="col-sm-3">
                    <button title="Cancel-Update" class="btn btn-outline-warning" type="button" (click)="CancelUpdate()">
                      <i class="bi bi-x-square-fill"></i> Cancel
                    </button>
                  </div>
                </div>

                <ng-container *ngIf="hasTempLibraryBooks()">
                  <hr size="1" />

                  <div class="book-listing overflow-scroll">
                    <div class="list-group">
                      <app-temp-library-book *ngFor="let book of tempLibraryBooks(); let i = index;" [book]="book"
                        [index]="i"></app-temp-library-book>
                    </div>
                  </div>

                </ng-container>

              </ng-container>

            </div>
          </div>
        </ng-container>

      </ng-container>

    </ng-container>

  </ng-container>
</div>
