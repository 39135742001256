import { Util } from "src/app/utils/utils";

export class UserLibrary {
  id: string = Util.emptyGuid;

  location: number = -1;
  name: string = '';
  description: string = '';
  hasValidationCode: boolean = false;

  createdOn: Date = new Date(Date.now());
  updatedOn: Date = new Date(Date.now());

  isValid(): boolean {
    return (this.id != "" && this.id != Util.emptyGuid)
      && this.location > 0
      && this.name.length > 0
      && this.description.length > 0
      && this.hasValidationCode;
  }

  static init(): UserLibrary {
    return this.create(-1);
  }

  private static create(location: number): UserLibrary {
    let library = new UserLibrary();

    library.location = location;
    return library;
  }
}
