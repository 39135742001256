<div class="projected-title container">

  <div class="row">
    <h3><span>Translation</span></h3>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="row">
        <div class="col-12">
          <app-security-phrases [securityPhrases]="securityPhrases"
            (onChange)="onSecurityPhrasesChange($event)"></app-security-phrases>
        </div>
      </div>

      <div class="row">
        <form [formGroup]="libraryBooksTranslationForm">
          <div class="col-12">
            <textarea title="Books" class="form-control fw-body-text-sm" type="text" rows="5"
              formControlName="books"></textarea>
          </div>

            <div class="row">
              <div class="col-4 mb-2">
                <button type="button" class="btn btn-primary" (click)="translateLibraryBooks()"> Translate </button>
              </div>
              <div class="col-4 mb-2">
                <button type="button" class="btn btn-primary" (click)="revertLibraryBooks()"> Revert </button>
              </div>
            </div>
        </form>
      </div>
    </div>

    <div class="col-sm-8">

      <mat-tab-group>
        <mat-tab label="User">
          <form [formGroup]="userTranslationForm">
            <div class="row">
              <h3>User</h3>
            </div>
            <div class="row">
              <div class="col-3 mb-2">
                <label for="textNumber" class="form-label">Number:</label>
                <input class="form-control text-raw" type="text" id="textNumber" formControlName="number" />
              </div>
              <div class="col-3 mb-2">
                <label for="textUserName" class="form-label">UserName:</label>
                <input class="form-control text-raw" type="text" id="textUserName" formControlName="userName" />
              </div>
              <div class="col-3 mb-2">
                <label for="textFirstName" class="form-label">FirstName:</label>
                <input class="form-control" type="text" id="textFirstName" formControlName="firstName" />
              </div>
            </div>
            <div class="row">
              <div class="col-3 mb-2">
                <label for="textLastName" class="form-label">LastName:</label>
                <input class="form-control" type="text" id="textLastName" formControlName="lastName" />
              </div>
              <div class="col-3 mb-2">
                <label for="textEmail" class="form-label">Email:</label>
                <input class="form-control text-raw" type="text" id="textEmail" formControlName="email" />
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-2">
                <label for="textUserInfo" class="form-label">Info:</label>
                <input class="form-control" type="text" id="textUserInfo" formControlName="userInfo" />
              </div>
              <div class="col-6 mb-2">
                <label for="textUserValidationCode" class="form-label">Code:</label>
                <input class="form-control" type="text" id="textUserValidationCode" formControlName="validationCode" />
              </div>
            </div>
            <hr>

            <div class="row">
              <div class="col-4 mb-2">
                <button type="button" class="btn btn-primary" (click)="translateUser()"> Translate </button>
              </div>
              <div class="col-4 mb-2">
                <button type="button" class="btn btn-primary" (click)="revertUser()"> Revert </button>
              </div>
            </div>
          </form>

        </mat-tab>

        <mat-tab label="Library">
          <form [formGroup]="libraryTranslationForm">

            <div class="row">
              <h3>Library</h3>
            </div>
            <div class="row">
              <div class="col-3 mb-2">
                <label for="textLocation" class="form-label">Location:</label>
                <input class="form-control text-raw" type="text" id="textLocation" formControlName="location" />
              </div>
              <div class="col-3 mb-2">
                <label for="textName" class="form-label">Name:</label>
                <input class="form-control" type="text" id="textName" formControlName="name" />
              </div>
              <div class="col-3 mb-2">
                <label for="textDescription" class="form-label">Description:</label>
                <input class="form-control" type="text" id="textDescription" formControlName="description" />
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-2">
                <label for="textLibraryInfo" class="form-label">Info:</label>
                <input class="form-control" type="text" id="textLibraryInfo" formControlName="libraryInfo" />
              </div>
              <div class="col-6 mb-2">
                <label for="textLibraryValidationCode" class="form-label">Code:</label>
                <input class="form-control" type="text" id="textLibraryValidationCode" formControlName="validationCode" />
              </div>
            </div>
            <hr>

            <div class="row">
              <div class="col-4 mb-2">
                <button type="button" class="btn btn-primary" (click)="translateLibrary()"> Translate </button>
              </div>
              <div class="col-4 mb-2">
                <button type="button" class="btn btn-primary" (click)="revertLibrary()"> Revert </button>
              </div>
            </div>
          </form>

        </mat-tab>

        <mat-tab label="Book">
          <form [formGroup]="bookTranslationForm">

            <div class="row">
              <h3>Book</h3>
            </div>
            <div class="row">
              <div class="col-3 mb-2">
                <label for="textIndex" class="form-label">Index:</label>
                <input class="form-control text-raw" type="text" id="textIndex" formControlName="index" />
              </div>
              <div class="col-3 mb-2">
                <label for="textTitle" class="form-label">Title:</label>
                <input class="form-control" type="text" id="textTitle" formControlName="title" />
              </div>
              <div class="col-3 mb-2">
                <label for="textGenre" class="form-label">Genre:</label>
                <input class="form-control" type="text" id="textGenre" formControlName="genre" />
              </div>
            </div>
            <div class="row">
              <div class="col-3 mb-2">
                <label for="textAuthor" class="form-label">Author:</label>
                <input class="form-control" type="text" id="textAuthor" formControlName="author" />
              </div>
              <div class="col-3 mb-2">
                <label for="textPublisher" class="form-label">Publisher:</label>
                <input class="form-control" type="text" id="textPublisher" formControlName="publisher" />
              </div>
              <div class="col-3 mb-2">
                <label for="textEditors" class="form-label">Editors:</label>
                <input class="form-control" type="text" id="textEditors" formControlName="editors" />
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-2">
                <label for="textBookInfo" class="form-label">Info:</label>
                <input class="form-control" type="text" id="textBookInfo" formControlName="bookInfo" />
              </div>

              <div class="col-6 mb-2">
                <label for="textReviews" class="form-label">Reviews:</label>
                <input class="form-control" type="text" id="textReviews" formControlName="reviews" />
              </div>
            </div>
            <hr>

            <div class="row">
              <div class="col-4 mb-2">
                <button type="button" class="btn btn-primary" (click)="translateBook()"> Translate </button>
              </div>
              <div class="col-4 mb-2">
                <button type="button" class="btn btn-primary" (click)="revertBook()"> Revert </button>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>

    </div>
  </div>


  <div class="row">
    <h2><span>Testing</span></h2>
    <hr>
  </div>

  <div class="row">
    <h3><span>Encryption/Decryption</span></h3>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <form [formGroup]="testForm" (ngSubmit)="encrypt()">
        <div class="mb-3">
          <label for="cryptoKey" class="form-label">Cipher Key:</label>
          <input type="text" class="form-control" id="cryptoKey" formControlName="cryptoKey" />
        </div>
        <div class="mb-3">
          <label for="cryptoIv" class="form-label">Cipher Iv:</label>
          <input type="text" class="form-control" id="cryptoIv" formControlName="cryptoIv" />
        </div>
        <hr>
        <div class="mb-3">
          <label for="textToEncrypt" class="form-label">Text to encrypt:</label>
          <input type="text" class="form-control" id="textToEncrypt" formControlName="textToEncrypt" />
        </div>
        <div class="mb-3">
          <label for="textToDecrypt" class="form-label">Text to decrypt:</label>
          <input type="text" class="form-control" id="textToDecrypt" formControlName="textToDecrypt" />
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
    <div class="col-sm-4">
      <div>Encrypted: {{ encryptedText }}<br />Decrypted: {{ decryptedText }}</div>
    </div>
    <hr>
  </div>

</div>
