
export class SecurityPhrases {
  level1: string = '';
  level2: string = '';
  level3: string = '';

  isValid(): boolean {
    return this.level1.length > 0
      && this.level2.length > 0
      && this.level3.length > 0;
  }

  static create(level1: string, level2: string, level3: string): SecurityPhrases {
    const phrases = new SecurityPhrases();
    phrases.level1 = level1;
    phrases.level2 = level2;
    phrases.level3 = level3;
    return phrases;
  }

  static init() {
    return this.create('','','');
  }
}
