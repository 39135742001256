import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SecurityPhrases } from 'src/app/security/security-phrases/security-phrases.model';
import { LibraryUser } from '../../models/library-user.model';

@Component({
  selector: 'app-user-creator',
  templateUrl: './user-creator.component.html',
  styleUrls: ['./user-creator.component.scss']
})
export class UserCreatorComponent {
  private _user: LibraryUser = LibraryUser.init();
  private _securityPhrases: SecurityPhrases = SecurityPhrases.init();

  constructor() {
  }

  get user(): LibraryUser {
    return this._user;
  }
  @Input() set user(user: LibraryUser) {
    this._user = user;
  }

  get securityPhrases(): SecurityPhrases {
    return this._securityPhrases;
  }
  @Input() set securityPhrases(securityPhrases: SecurityPhrases) {
    this._securityPhrases = securityPhrases;
  }

  @Output() onConfirmation = new EventEmitter<{user: LibraryUser, securityPhrases: SecurityPhrases}>();
  @Output() onCancellation = new EventEmitter();

  userInfoIsSet: boolean = false;
  hasValidSecurityPhrases: boolean = false;

  newLibraryUserForm = new UntypedFormGroup({
    userName: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.minLength(4)]),
  });

  updateNewUserInfo(): void {
    this.updateNewUserDetails();
    this.userInfoIsSet = true;
  }

  saveNewUserRegistration(): void {
    this.onConfirmation.emit({user: this._user, securityPhrases: this._securityPhrases});
    this.resetAll();
  }

  cancelNewUser(): void {
    this.onCancellation.emit();
    this.resetAll();
  }

  onUserSecurityPhrasesChange(securityPhrases: SecurityPhrases): any {
    this._securityPhrases = securityPhrases;
    this.hasValidSecurityPhrases = securityPhrases.isValid();
  }

  private resetAll(): void {
    this.user = LibraryUser.init();
    this.securityPhrases = SecurityPhrases.init();

    this.resetNewUserForm();
  }

  private updateNewUserDetails(): void {
    this._user.userName = this.newLibraryUserForm.controls["userName"].value;
    this._user.firstName = this.newLibraryUserForm.controls["firstName"].value;
    this._user.lastName = this.newLibraryUserForm.controls["lastName"].value;
    this._user.email = this.newLibraryUserForm.controls["email"].value;
  }

  private resetNewUserForm(): void {
    this.newLibraryUserForm.controls["userName"].setValue('');
    this.newLibraryUserForm.controls["firstName"].setValue('');
    this.newLibraryUserForm.controls["lastName"].setValue('');
    this.newLibraryUserForm.controls["email"].setValue('');
  }
}
