import { Component, WritableSignal, computed, effect } from '@angular/core';

import { UserLibrary } from '../../models/user-library.model';
import { LibraryService } from '../../services/library.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/utils/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-library-list-item',
  templateUrl: './library-list-item.component.html',
  styleUrls: ['./library-list-item.component.scss']
})
export class LibraryListItemComponent {

  constructor(
    private dialog: MatDialog,
    private libraryService: LibraryService) {
    this.selectedLibrary = this.libraryService.selectedUserLibrary;
    this.userLibrarySelected = this.libraryService.userLibrarySelected;
    this.editUserLibraryRequested = this.libraryService.updateUserLibraryRequested;
    this.newUserLibraryRequested = this.libraryService.newUserLibraryRequested;

    effect(() => {
      if (this.libraryService.deleteUserLibraryRequested()) {
        this.requestConfirmation();
      }
    })
  }

  selectedLibrary: WritableSignal<UserLibrary>;
  editUserLibraryRequested: WritableSignal<boolean>;
  newUserLibraryRequested: WritableSignal<boolean>;
  userLibrarySelected: WritableSignal<boolean>;

  displaySelectedLibrary = computed(() => {
    return this.userLibrarySelected() && !this.editUserLibraryRequested() && !this.newUserLibraryRequested();
  });

  requestEditLibrary(): void {
    this.libraryService.requestUpdateUserLibrary();

  }

  requestDeleteLibrary(): void {
    this.libraryService.requestDeleteUserLibrary();
  }

  requestConfirmation(): void {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete the current user library? This action cannot be undone.'
      }
    });

    dialogRef.afterClosed().subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          this.deleteUserLibrary();
        } else {
          this.libraryService.cancelDeleteUserLibrary();
        }
      },
      error: (_) => { }
    });
  }

  private deleteUserLibrary(): void {
    this.libraryService.deleteUserLibrary();
  }
}
