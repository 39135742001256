import { Component, ElementRef, ViewChild, WritableSignal, effect } from '@angular/core';

import { SecurityPhrases } from 'src/app/security/security-phrases/security-phrases.model';
import { LibraryUser } from '../models/library-user.model';
import { LibraryService } from '../services/library.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/utils/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-library-user',
  templateUrl: './library-user.component.html',
  styleUrls: ['./library-user.component.scss']
})
export class LibraryUserComponent {

  newUserNameElement!: ElementRef<HTMLInputElement>;

  @ViewChild('newUserNameInput') set inputElRef(elRef: ElementRef<HTMLInputElement>) {
    if (elRef) {
      this.newUserNameElement = elRef;
      this.newUserNameElement.nativeElement.focus();
    }
  }

  constructor(
    private dialog: MatDialog,
    private libraryService: LibraryService) {
    this.userSignInRequested = this.libraryService.userSignInRequested;
    this.userRegistrationIsRequested = this.libraryService.newUserAccountRequested;
    this.userEditRequested = this.libraryService.updateSignedInUserRequested;

    this.userSignedIn = this.libraryService.userSignedIn;
    this.currentUser = this.libraryService.signedInUser;

    effect(() => {
      const deleteLibraryRequested = this.libraryService.deleteSignedInUserRequested();
      if (deleteLibraryRequested) {
        //console.log('[LibraryListItem].deleteUserLibrary --> TODO: This needs to trigger the confirmation dialog!!');
        this.requestCloseAccount();
      }
    });
  }

  //User actions
  userSignInRequested: WritableSignal<boolean>;
  userRegistrationIsRequested: WritableSignal<boolean>;
  userEditRequested: WritableSignal<boolean>;

  userSignedIn: WritableSignal<boolean>;
  currentUser: WritableSignal<LibraryUser>;

  userName: string = '';
  signInSecurityPhrases: SecurityPhrases = SecurityPhrases.init();

  newLibraryUser: LibraryUser = LibraryUser.init();
  newUserSecurityPhrases: SecurityPhrases = SecurityPhrases.init();

  isUserDisplayMode: boolean = true;
  isUserEditMode: boolean = false;

  requestUserSignin($event: { userName: string, securityPhrases: SecurityPhrases }): void {
    this.libraryService.signInUser($event.userName, $event.securityPhrases);
  }

  requestUserRegistration(): void {
    this.libraryService.requestNewUserAccount();
  }

  requestUserEdit(): void {
    this.isUserDisplayMode = false;
    this.isUserEditMode = true;
  }

  cancelUserSignin(): void {
    this.userName = '';
    this.signInSecurityPhrases = SecurityPhrases.init();
  }

  cancelUserRegistration(): void {
    this.libraryService.cancelNewUserAccount();

    this.newLibraryUser = LibraryUser.init();
    this.newUserSecurityPhrases = SecurityPhrases.init();
  }

  cancelUserEdit(): void {
    this.libraryService.cancelUpdateSignedInUser();

    this.isUserEditMode = false;
    this.isUserDisplayMode = true;
  }

  saveUserRegistration($event: { user: LibraryUser, securityPhrases: SecurityPhrases }): void {
    const securityPhrases = $event.securityPhrases;
    let newUser = $event.user;
    newUser.number = this.libraryService.nextUserNumber();

    this.libraryService.saveNewUserAccount(newUser, securityPhrases);

    this.cancelUserRegistration();
  }

  saveUserChanges($event: LibraryUser): void {
    this.libraryService.updateSignedInUser($event);

    this.cancelUserEdit();
  }

  requestCloseAccount(): void {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete your user account? This action cannot be undone.'
      }
    });

    dialogRef.afterClosed().subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          this.libraryService.deleteSignedInUser();
        } else {
          this.libraryService.cancelDeleteSignedInUser();
        }
      },
      error: (_) => { }
    });
  }
}
