<ng-container *ngIf="isLibrarySelected()">
  <div class="row">

    <div class="col-2">
      <ng-container *ngIf="!isFiltered()">
        <button class="btn btn-outline-primary btn-sm" type="button" (click)="toggleShowNewBook()"
          tabindex="15" aria-label="New Book">

          <span *ngIf="newLibraryBookRequested()">
            <i class="bi bi-caret-down-fill"></i>
          </span>
          <span *ngIf="!newLibraryBookRequested()">
            <i class="bi bi-caret-right-fill"></i>
          </span>
          <span>+</span>
        </button>
      </ng-container>
    </div>

    <div class="col-6">

      <div class="float-right">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="global search" [(ngModel)]="searchText"
            aria-label="global search" (keydown.enter)="filterBookshelf()" aria-describedby="button-addon2">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="filterBookshelf()" id="button-addon2"
              aria-label="Search">
              <i class="bi bi-binoculars-fill"></i>
            </button>
          </div>
        </div>
      </div>

    </div>

    <div class="col-4">

      <div class="input-group mb-3">
        <select title="BookGenres" id="genreSelector" class="form-select form-control" [(ngModel)]="genreText"
          (change)="filterBookGenre()">
          <option value="" selected>Select a genre</option>
          <option *ngFor="let genre of bookGenres()">{{genre}}</option>
        </select>
      </div>

    </div>

  </div>

  <app-book-creator *ngIf="newLibraryBookRequested()"></app-book-creator>
</ng-container>
