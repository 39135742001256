import { Component } from '@angular/core';

import { ClipboardService } from 'ngx-clipboard';

import { LibraryBook } from '../../models/library-book.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Util } from 'src/app/utils/utils';
import { LibraryService } from '../../services/library.service';

@Component({
  selector: 'app-book-creator',
  templateUrl: './book-creator.component.html',
  styleUrls: ['./book-creator.component.scss']
})
export class BookCreatorComponent {

  private _newLibraryBook: LibraryBook;

  constructor(
    private libraryService: LibraryService,
    private clipboardService: ClipboardService) {
      this._newLibraryBook = LibraryBook.init();
      this.updateBookForm();

      this.publisherDisplayType = 'password';
    }

  label = LibraryBook.getLabels();

  libraryBookForm = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    genre: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    author: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    publisher: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    reviews: new UntypedFormControl('')
  });

  publisherDisplayType: string;

  saveNewBook(): void {
    this.updateBookDetails();

    this._newLibraryBook.index = this.libraryService.nextBookIndex();
    this.libraryService.saveNewLibraryBook(this._newLibraryBook);
  }

  cancelNewBook(): void {
    this.libraryService.cancelNewLibraryBook();
  }

  copyBookAuthorToClipboard(): void {
    this.clipboardService.copyFromContent(this.libraryBookForm.controls['author'].value);
  }

  copyBookPublisherToClipboard(): void {
    this.clipboardService.copyFromContent(this.libraryBookForm.controls['publisher'].value);
  }

  toggleInputType(): void {
    this.publisherDisplayType = Util.togglePasswordFieldDisplay(this.publisherDisplayType);
  }

  private updateBookDetails(): void {
    this._newLibraryBook.title = this.libraryBookForm.controls['title'].value;
    this._newLibraryBook.genre = this.libraryBookForm.controls['genre'].value;
    this._newLibraryBook.author = this.libraryBookForm.controls['author'].value;
    this._newLibraryBook.publisher = this.libraryBookForm.controls['publisher'].value;
    this._newLibraryBook.reviews = this.libraryBookForm.controls['reviews'].value;
  }

  private updateBookForm(): void {
    this.libraryBookForm.controls['title'].setValue(this._newLibraryBook.title);
    this.libraryBookForm.controls['genre'].setValue(this._newLibraryBook.genre);
    this.libraryBookForm.controls['author'].setValue(this._newLibraryBook.author);
    this.libraryBookForm.controls['publisher'].setValue(this._newLibraryBook.publisher);
    this.libraryBookForm.controls['reviews'].setValue(this._newLibraryBook.reviews);
  }
}
