import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';

//import { ReCaptchaService } from './re-captcha.service';

import { TranslateService } from '@ngx-translate/core';
import { ContactEmailModel } from './contact/contact-email.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mTrilby';
  registerForm!: FormGroup;
  submitted = false;
  reCAPTCHAToken: string = "";
  tokenVisible: boolean = false;
  registrationInfo!: ContactEmailModel;

  constructor(
    translate: TranslateService,
    private recaptchaV3Service: ReCaptchaV3Service) {

    translate.setDefaultLang('en');
  }

  ngOnInit() {
      this.registerForm = new FormGroup({
          UserName: new FormControl(),
          UserEmailId: new FormControl(),
          password: new FormControl(),
          confirmPassword: new FormControl(),
      })
  }

  onSubmit() {
      this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
          this.tokenVisible = true;
          this.reCAPTCHAToken = `Token [${token}] generated`;
      });
  }
}
