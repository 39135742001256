import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { Util } from 'src/app/utils/utils';
import { HttpClientErrorHandler } from './http-client-error-handler.service';
import { UserLibrarySecurityService } from '../../../security/user-library-security.service';

import { RecordIdInfo } from '../../models/record-id-info.model';
import { LibraryBookDto } from '../interfaces/library-book-dto.interface';
import {
  LibraryBookRevertedDto,
  NewLibraryBookRevertedDto,
  UpdateEditorsLibraryBookRevertedDto,
  UpdateLibraryBookRevertedDto
} from "../interfaces/library-book-reverted-dto.interface";

@Injectable()
export class LibraryBookHttpClientService {

  constructor(
    private httpClient: HttpClient,
    private httpClientErrorHandlerService: HttpClientErrorHandler,
    private librarySecurityService: UserLibrarySecurityService) { }

  getActiveBooks(csi: string, userId: string, libraryId: string): Observable<Array<LibraryBookDto>> {
    if (csi.length === 0 || userId === Util.emptyGuid || libraryId === Util.emptyGuid) return of(new Array<LibraryBookDto>());

    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${csi}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(libraryId)}`)
    };

    return this.httpClient.get<LibraryBookDto[]>(`${environment.apiUrl}/Lib/LibraryBooks/Active`, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryBook Service', error))
    );
  }

  addBook(userId: string, libraryId: string, book: NewLibraryBookRevertedDto): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(libraryId)}`)
    };

    return this.httpClient.post(`${environment.apiUrl}/Lib/LibraryBooks/New`, book, httpOptions).pipe(
      map((response: any): any => { return response; }),
      map((bookInfo: RecordIdInfo): string => { return this.librarySecurityService.DecryptValue(bookInfo.idInfo); }),
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryBook Service', error))
    );
  }

  resetBook(userId: string, libraryId: string, bookId: string, book: LibraryBookRevertedDto): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(libraryId)}`)
        .set('bid', `${this.librarySecurityService.EncryptValue(bookId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/LibraryBooks/Reset`, book, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryBook Service', error))
    );
  }

  updateLibraryBookInfo(userId: string, libraryId: string, bookId: string, updatteBookIdInfo: RecordIdInfo): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(libraryId)}`)
        .set('bid', `${this.librarySecurityService.EncryptValue(bookId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/LibraryBooks/Info`, updatteBookIdInfo, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryBook Service', JSON.stringify(error)))
    );
  }

  updateBook(userId: string, libraryId: string, bookId: string, book: UpdateLibraryBookRevertedDto): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(libraryId)}`)
        .set('bid', `${this.librarySecurityService.EncryptValue(bookId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/LibraryBooks/Update`, book, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryBook Service', error))
    );
  }

  updateBookEditors(userId: string, libraryId: string, bookId: string, book: UpdateEditorsLibraryBookRevertedDto): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(libraryId)}`)
        .set('bid', `${this.librarySecurityService.EncryptValue(bookId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/LibraryBooks/UpdateEditors`, book, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryBook Service', error))
    );
  }

  deleteLibraryBook(userId: string, libraryId: string, bookId: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(libraryId)}`)
        .set('bid', `${this.librarySecurityService.EncryptValue(bookId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/LibraryBooks/Delete`, null, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('LibraryBook Service', error)));
  }
}
