import { Injectable, signal } from '@angular/core';
import { v4 as uuid } from 'uuid';

import { LibraryBookHttpClientService } from './http-client-services/library-book.http-client-service';
import { LibraryClientSessionHttpClientService } from './http-client-services/library-client-session.http-client-service';
import { LibraryUserHttpClientService } from './http-client-services/library-user.http-client-service';
import { UserEmailConfirmationHttpClientService } from './http-client-services/user-email-confirmation.http-client-service';
import { UserLibraryHttpClientService } from './http-client-services/user-library.http-client-service';
import { UserLibrarySecurityService } from 'src/app/security/user-library-security.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LibraryTranslationService } from './library-translation.service';
import { LibraryTranslationServiceOld } from "./library-translation-service-old";
import { BookSecurityPhrasesHelperService } from 'src/app/security/security-phrases/book-security-phrases.service';
import { LibraryReversionService } from './library-reversion.service';
import { UserSecurityPhrasesHelperService } from 'src/app/security/security-phrases/user-security-phrases.service';
import { LibrarySecurityPhrasesHelperService } from 'src/app/security/security-phrases/library-security-phrases.service';

import { ClientSessionInfo } from '../models/client-session.model';
import { SecurityPhrases } from 'src/app/security/security-phrases/security-phrases.model';
import { UpdateRecordIdInfo } from '../models/record-id-Info-update.model';

import { LibraryUser } from '../models/library-user.model';
import { LibraryUserDto } from './interfaces/library-user-dto.interface';
import { UserLibrary } from '../models/user-library.model';
import { UserLibraryDto } from './interfaces/user-library-dto.interface';
import { LibraryBook } from '../models/library-book.model';
import { LibraryBookDto } from './interfaces/library-book-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  constructor(
    libraryClientService: LibraryClientSessionHttpClientService,
    private notificationService: NotificationService,
    private librarySecurityService: UserLibrarySecurityService,
    private libraryUserService: LibraryUserHttpClientService,
    private userLibraryService: UserLibraryHttpClientService,
    private userEmailConfirmationService: UserEmailConfirmationHttpClientService,
    private libraryBookService: LibraryBookHttpClientService) {
    this.uniqueId = uuid();

    //Call, for now, once per browser session
    libraryClientService.createClientSession(this.librarySecurityService.CreateClientSessionId()).subscribe({
      next: (sessionInfo: ClientSessionInfo) => {
        this._clientSessionInfo.set(sessionInfo);
        this.librarySecurityService.UpdateClientSessionInfo(sessionInfo);

        this.triggerNextUserNumber();

        this.hasClientSessionInfo.set(true);

        this.requestUserSignIn();
      },
      error: _ => {
        notificationService.showError('The client session could not be started.\r\nIf this issue persists, contact the web administrator.', 'Fatal Error')
      }
    });
  }

  private actionStack: Array<Function> = [];
  public uniqueId: string;

  //Client Session state
  public hasClientSessionInfo = signal<boolean>(false);
  private _clientSessionInfo = signal<ClientSessionInfo>(ClientSessionInfo.init());

  //Library User action requests
  public userSignInRequested = signal<boolean>(true); // Initial action
  public newUserAccountRequested = signal<boolean>(false);
  public updateSignedInUserRequested = signal<boolean>(false);
  public deleteSignedInUserRequested = signal<boolean>(false);

  //Library User state
  public nextUserNumber = signal<number>(1);
  public userSignInName = signal<string>("");
  public userSecurityPhrases = signal<SecurityPhrases>(SecurityPhrases.init());
  public userSignedIn = signal<boolean>(false);
  public signedInUser = signal<LibraryUser>(LibraryUser.init());
  public newLibraryUser = signal<LibraryUser>(LibraryUser.init());

  //User Libraries action requests
  public newUserLibraryRequested = signal<boolean>(false);
  public updateUserLibraryRequested = signal<boolean>(false);
  public deleteUserLibraryRequested = signal<boolean>(false);

  //User Libraries state
  public nextLibraryLocation = signal<number>(1);
  public userLibraries = signal<Array<UserLibrary>>([]);
  public userLibrarySelected = signal<boolean>(false);
  public selectedUserLibrary = signal<UserLibrary>(UserLibrary.init());
  public newUserLibrary = signal<UserLibrary>(UserLibrary.init());

  //User Library Books action requests
  public newLibraryBookRequested = signal<boolean>(false);
  public filterLibraryBooksRequested = signal<boolean>(false);

  //User Library Books state
  public nextBookIndex = signal<number>(1);
  public filterText = signal<string>('');
  public filterGenre = signal<string>('');
  public userLibraryBooks = signal<Array<LibraryBook>>([]);
  public filteredLibraryBooks = signal<Array<LibraryBook>>([]);
  public selectedLibraryGenres = signal<Array<string>>([]);
  public newLibraryBook = signal<LibraryBook>(LibraryBook.init());
  public selectedLibraryBook = signal<LibraryBook>(LibraryBook.init());

  private requestUserSignIn(): void {
    this.rollbackCurrentAction();
    this.userSignInRequested.set(true);

    var cancelRequest = () => this.cancelUserSignIn();
    this.actionStack.push(cancelRequest);
  }

  private cancelUserSignIn(): void {
    this.userSignInRequested.set(false);
  }

  signInUser(userName: string, securityPhrases: SecurityPhrases): void {
    if (userName.length === 0 || !securityPhrases.isValid()) return;

    this.userSignInName.set(userName);
    this.userSecurityPhrases.set(securityPhrases);

    this.userSignInName.set(userName);

    const messageTitle = "Request Error";
    const errorMessage = "Not able to load your user details.  Please try again.";

    const libraryReversionService = new LibraryReversionService();
    this.libraryUserService.getLibraryUser(this.librarySecurityService.GetClientSessionId(), userName).subscribe({
      next: (userDto: LibraryUserDto) => {
        if (userDto === undefined || userDto === null) return;


        let user = new LibraryTranslationService().translateLibraryUserDto(this.userSignInName(), userDto, securityPhrases);
        if (!user.isValid()) {
          // Try old convert & if it is valid then revert it with the new and then save it
          const olduser = new LibraryTranslationServiceOld().translateLibraryUserDto(this.userSignInName(), userDto, securityPhrases);
          if (olduser.isValid()) {
            user = olduser;

            //Revert with new security phrases and save it
            const revertedUser = libraryReversionService.revertLibraryUser(olduser, securityPhrases);
            this.libraryUserService.resetUser(this.userSignInName(), olduser.id, revertedUser).subscribe({
              next: (_) => { },
              error: (_) => { }
            });
          }
        }

        this.signedInUser.set(user);

        if (!user.isValid()) return;

        this.userSignedIn.set(true);
        this.cancelUserSignIn();

        this.getUserLibraries();
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  requestNewUserAccount(): void {
    this.rollbackCurrentAction();

    //Reset the current library display
    this.resetSelectedLibraryState();
    this.resetUserLibrariesState();
    this.resetLibraryUserState();

    this.newUserAccountRequested.set(true);

    var cancelRequest = () => this.newUserAccountRequested.set(false);
    this.actionStack.push(cancelRequest);
  }

  cancelNewUserAccount(): void {
    this.newUserAccountRequested.set(false);

    this.requestUserSignIn();
  }

  saveNewUserAccount(libraryUser: LibraryUser, securityPhrases: SecurityPhrases): void {
    const messageTitle = "User Account";
    const successMessage = "A confirmation mail was sent to the provided email. \r\nPlease confirm your email addess before continuing.";
    const errorMessage = "An error occurred preventing the creation of your user account. \r\nIf this error persist, contact the web administrator.";

    const libraryReversionService = new LibraryReversionService();
    const newUser = libraryReversionService.revertNewLibraryUser(libraryUser, securityPhrases);



    this.libraryUserService.addNewUser(libraryUser.userName, newUser).subscribe({
      next: (userId: any) => {
        libraryUser.id = userId;
        libraryUser.hasValidationCode = true;

        const userIdInfoUpdate = new UpdateRecordIdInfo();
        userIdInfoUpdate.idInfo = libraryReversionService.revertIdInfo(libraryUser.id, new UserSecurityPhrasesHelperService(securityPhrases).GetUserIdInfoPass(libraryUser));

        this.libraryUserService.updateUserInfo(libraryUser.id, userIdInfoUpdate).subscribe({
          next: (_) => { },
          error: (_) => {
            this.notificationService.showError(`UserInfo: ${errorMessage}`, messageTitle);
      }});

        const confirmationCode = this.librarySecurityService.CreateConfirmationCode(libraryUser.userName);
        this.userEmailConfirmationService.requestUserEmailConfirmation(libraryUser, confirmationCode).subscribe({
          next: (_) => { },
          error: (_) => {
            this.notificationService.showError(`EmailConfirmation: ${errorMessage}`, messageTitle);
      }});

        this.triggerNextUserNumber();
        this.cancelUpdateSignedInUser();

        this.notificationService.showSuccess(successMessage, messageTitle);
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  requestUpdateSignedInUser(): void {
    this.rollbackCurrentAction();
    this.updateSignedInUserRequested.set(true);

    var cancelRequest = () => this.cancelUpdateSignedInUser();
    this.actionStack.push(cancelRequest);
  }

  cancelUpdateSignedInUser(): void {
    this.updateSignedInUserRequested.set(false);
  }

  updateSignedInUser(libraryUser: LibraryUser): void {
    const messageTitle = "Update User";
    const errorMessage = "An error occurred preventing updating your user info. \r\nIf this error persist, contact the web administrator.";

    const libraryReversionService = new LibraryReversionService();
    let updateUser = libraryReversionService.revertUpdateLibraryUser(libraryUser, this.userSecurityPhrases());

    this.libraryUserService.updateUser(libraryUser.id, updateUser).subscribe({
      next: _ => {
        this.signedInUser.set(libraryUser);
      },
      error: _ => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  requestDeleteSignedInUser(): void {
    this.rollbackCurrentAction();
    this.deleteSignedInUserRequested.set(true);

    var cancelRequest = () => this.cancelDeleteSignedInUser();
    this.actionStack.push(cancelRequest);
  }

  cancelDeleteSignedInUser(): void {
    this.deleteSignedInUserRequested.set(false);
  }

  deleteSignedInUser(): void {
    const messageTitle = "Close User Account";
    const errorMessage = "An error occurred preventing closing your user account. \r\nIf this error persist, contact the web administrator.";

    const currentUser = this.signedInUser();
    this.libraryUserService.closeUserAccount(currentUser.id, currentUser.userName).subscribe({
      next: (_) => {
        window.location.reload();
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  setSelectedLibrary(selectedLibrary: UserLibrary): void {
    this.rollbackCurrentAction();
    this.resetSelectedLibraryState();

    const currentLibrary = this.userLibraries().find(x => x.location === selectedLibrary.location) ?? UserLibrary.init();

    this.selectedUserLibrary.set(currentLibrary);

    const isValid = currentLibrary.isValid();
    this.userLibrarySelected.set(isValid);

    if (isValid) this.getLibraryBooks();
  }

  requestNewUserLibrary(): void {
    this.rollbackCurrentAction();
    this.newUserLibraryRequested.set(true);

    var cancelRequest = () => this.cancelNewUserLibrary();
    this.actionStack.push(cancelRequest);
  }

  cancelNewUserLibrary(): void {
    this.newUserLibraryRequested.set(false);
  }

  saveNewUserLibrary(userLibrary: UserLibrary): void {
    const messageTitle = "Create Library";
    const successMessage = "Library successfully created.";
    const errorMessage = "An error occurred preventing creating the new library. \r\nIf this error persist, contact the web administrator.";

    const userSecurityPhrases = this.userSecurityPhrases();
    const libraryReversionService = new LibraryReversionService();

    let newLibrary = libraryReversionService.revertNewUserLibrary(userLibrary, userSecurityPhrases);

    const currentUser = this.signedInUser();

    this.userLibraryService.addNewLibrary(currentUser.id, newLibrary).subscribe({
      next: (newLibraryId: string) => {
        userLibrary.id = newLibraryId;
        userLibrary.hasValidationCode = true;

        const userLibraryIdInfoUpdate = new UpdateRecordIdInfo();
        userLibraryIdInfoUpdate.idInfo = libraryReversionService.revertIdInfo(userLibrary.id, new LibrarySecurityPhrasesHelperService(userSecurityPhrases).GetIdInfoPass(userLibrary));

        this.userLibraryService.updateLibraryInfo(currentUser.id, userLibrary.id, userLibraryIdInfoUpdate).subscribe({
          next: (_) => {
            this.userLibraries.mutate((libraries) => libraries.push(userLibrary));
          },
          error: (_) => {
            this.notificationService.showError(`LibraryInfo: ${errorMessage}`, messageTitle);
      }});

        this.newUserLibraryRequested.set(false);
        this.notificationService.showSuccess(successMessage, messageTitle);

        return newLibraryId;
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  requestUpdateUserLibrary(): void {
    this.rollbackCurrentAction();
    this.updateUserLibraryRequested.set(true);

    var cancelRequest = () => this.cancelUpdateUserLibrary();
    this.actionStack.push(cancelRequest);
  }

  cancelUpdateUserLibrary(): void {
    this.updateUserLibraryRequested.set(false);
  }

  updateUserLibrary(library: UserLibrary): void {
    const messageTitle = "Update Library";
    const errorMessage = "An error occurred preventing updating the library. \r\nIf this error persist, contact the web administrator.";
    const libraryReversionService = new LibraryReversionService();

    let revertedUpdateLibrary = libraryReversionService.revertUpdateUserLibrary(library, this.userSecurityPhrases());

    const currentUser = this.signedInUser();
    this.userLibraryService.updateLibrary(currentUser.id, library.id, revertedUpdateLibrary).subscribe({
      next: (result) => {
        this.cancelUpdateUserLibrary();

        return result;
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  requestDeleteUserLibrary(): void {
    this.rollbackCurrentAction();
    this.deleteUserLibraryRequested.set(true);

    var cancelRequest = () => this.cancelDeleteUserLibrary();
    this.actionStack.push(cancelRequest);
  }

  cancelDeleteUserLibrary(): void {
    this.deleteUserLibraryRequested.set(false);
  }

  deleteUserLibrary(): void {
    const messageTitle = "Delete Library";
    const errorMessage = "An error occurred preventing deleting the library. \r\nIf this error persist, contact the web administrator.";

    const currentUser = this.signedInUser();
    this.userLibraryService.deleteLibrary(currentUser.id, this.selectedUserLibrary().id).subscribe({
      next: result => {
        this.cancelDeleteUserLibrary();
        this.resetSelectedLibraryState();
        this.resetUserLibrariesState();

        this.getUserLibraries();
        return result
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  requestNewLibraryBook(): void {
    this.rollbackCurrentAction();
    this.newLibraryBookRequested.set(true);

    var cancelRequest = () => this.cancelNewLibraryBook();
    this.actionStack.push(cancelRequest);
  }

  cancelNewLibraryBook(): void {
    this.newLibraryBookRequested.set(false);
  }

  saveNewLibraryBook(book: LibraryBook): void {
    const messageTitle = "Create Library Book";
    const successMessage = "Library book successfully created.";
    const errorMessage = "An error occurred preventing the creation of the new library book. \r\nIf this error persist, contact the web administrator.";

    const userSecurityPhrases = this.userSecurityPhrases();
    const libraryReversionService = new LibraryReversionService();

    let newBook = libraryReversionService.revertNewLibraryBook(book, userSecurityPhrases)

    const currentUser = this.signedInUser();
    this.libraryBookService.addBook(currentUser.id, this.selectedUserLibrary().id, newBook).subscribe({
      next: (bookId) => {
        book.id = bookId;

        const libraryBookIdInfoUpdate = new UpdateRecordIdInfo();
        libraryBookIdInfoUpdate.idInfo = libraryReversionService.revertIdInfo(book.id, new BookSecurityPhrasesHelperService(userSecurityPhrases).GetIdInfoPass(book));

        this.libraryBookService.updateLibraryBookInfo(currentUser.id, this.selectedUserLibrary().id, book.id, libraryBookIdInfoUpdate).subscribe({
          next: (_) => {
            this.userLibraryBooks.mutate((books) => books.push(book));
            this.filterNewLibraryBook(book);

            this.setSelectedLibraryBookGenres();

            this.notificationService.showSuccess(successMessage, messageTitle);

            this.nextBookIndex.set(book.index + 1);
            this.newLibraryBookRequested.set(false);
          },
          error: (_) => {
            this.notificationService.showError(`BookInfo: ${errorMessage}`, messageTitle);
      }});
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  updateLibraryBook(book: LibraryBook): void {
    const messageTitle = "Update Library Book";
    const errorMessage = "An error occurred preventing updating the library book. \r\nIf this error persist, contact the web administrator.";

    const libraryReversionService = new LibraryReversionService();
    let updateBook = libraryReversionService.revertUpdateLibraryBook(book, this.userSecurityPhrases());

    const currentUser = this.signedInUser();
    this.libraryBookService.updateBook(currentUser.id, this.selectedUserLibrary().id, book.id, updateBook).subscribe({
      next: (_) => {
        this.setSelectedLibraryBookGenres();
        this.filterUpdatedLibraryBook(book);
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  updateLibraryBookEditors(book: LibraryBook): void {
    const messageTitle = "Update Library Book Editors";
    const errorMessage = "An error occurred preventing updating the library book editors. \r\nIf this error persist, contact the web administrator.";

    const libraryReversionService = new LibraryReversionService();
    let updateEditorsBook = libraryReversionService.revertUpdateEditorsLibraryBook(book, this.userSecurityPhrases());

    const currentUser = this.signedInUser();
    this.libraryBookService.updateBookEditors(currentUser.id, this.selectedUserLibrary().id, book.id, updateEditorsBook).subscribe({
      next: (_) => {
        //TODO: Amend Books collection
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  deleteLibraryBook(book: LibraryBook): void {
    const messageTitle = "Delete Library Book";
    const errorMessage = "An error occurred preventing deleting the library book. \r\nIf this error persist, contact the web administrator.";

    const currentUser = this.signedInUser();
    this.libraryBookService.deleteLibraryBook(currentUser.id, this.selectedUserLibrary().id, book.id).subscribe({
      next: (_) => {
        this.userLibraryBooks.mutate((books) => {
          const bookIndex = books.indexOf(book);
          books.splice(bookIndex, 1);
        })
        this.setSelectedLibraryBookGenres();
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    });
  }

  setBookGenreFilter(genreFilter: string): void {
    this.filterGenre.set(genreFilter);

    this.filterLibraryBooks();
  }

  setBookTextFilter(searchText: string): void {
    this.filterText.set(searchText);

    this.filterLibraryBooks()
  }

  private getUserLibraries(): void {
    const messageTitle = "Request Error";
    const errorMessage = "Not able to load your user details.  Please try again.";

    const currentUser = this.signedInUser();
    const libraryReversionService = new LibraryReversionService();

    this.userLibraryService.getLibraries(this.librarySecurityService.GetClientSessionId(), currentUser.id).subscribe({
      next: (userLibrariesDto: Array<UserLibraryDto>) => {
        const currentLibraries = new Array<UserLibrary>();

        let maxLocation = 0;
        userLibrariesDto.forEach((dtoLibrary) => {
          maxLocation = Math.max(maxLocation, dtoLibrary.location);

          let library = new LibraryTranslationService().translateUserLibraryDto(dtoLibrary, this.userSecurityPhrases());

          if (library.isValid()) {
            currentLibraries.push(library);
          } else {
            const oldLibrary = new LibraryTranslationServiceOld().translateUserLibraryDto(dtoLibrary, this.userSecurityPhrases());

            if (oldLibrary.isValid()) {
              library = oldLibrary;
              currentLibraries.push(library);

              let revertedLibrary = libraryReversionService.revertUserLibrary(oldLibrary, this.userSecurityPhrases());

              const currentUser = this.signedInUser();
              this.userLibraryService.resetLibrary(currentUser.id, library.id, revertedLibrary).subscribe({
                next: (_) => { },
                error: (_) => { }
              });
            } else {
              console.log('[LibraryService].userLibraries$ (invalid library):', dtoLibrary.location);
            }
          }
        });

        this.nextLibraryLocation.set(maxLocation + 1);
        this.userLibraries.set(currentLibraries);
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    })
  };

  private getLibraryBooks() {
    const messageTitle = "Request Error";
    const errorMessage = "Not able to load your user details.  Please try again.";

    const currentUser = this.signedInUser();
    const currentLibrary = this.selectedUserLibrary();

    this.libraryBookService.getActiveBooks(this.librarySecurityService.GetClientSessionId(), currentUser.id, currentLibrary.id).subscribe({
      next: (libraryBooksDto: Array<LibraryBookDto>) => {
        libraryBooksDto.sort((a, b) => a.index > b.index ? 1 : -1);

        let maxIndex = 0;

        const libraryReversionService = new LibraryReversionService();
        const currentLibraryBooks = new Array<LibraryBook>();

        libraryBooksDto.forEach((dtoBook) => {
          maxIndex = Math.max(maxIndex, dtoBook.index);

          const book = new LibraryTranslationService().translateLibraryBookDto(dtoBook, this.userSecurityPhrases());

          if (book.isValid()) {
            currentLibraryBooks.push(book);
          } else {
            const oldBook = new LibraryTranslationServiceOld().translateLibraryBookDto(dtoBook, this.userSecurityPhrases());

            if (oldBook.isValid()) {
              currentLibraryBooks.push(oldBook);

              let revertedBook = libraryReversionService.revertLibraryBook(book, this.userSecurityPhrases());

              const currentUser = this.signedInUser();
              this.libraryBookService.resetBook(currentUser.id, this.selectedUserLibrary().id, oldBook.id, revertedBook).subscribe({
                next: (_) => { },
                error: (_) => { }});
            } else {
              console.log('[LibraryService].libraryBooks$ (invalid book):', dtoBook.index);
            }
          }
        });

        this.nextBookIndex.set(maxIndex + 1);

        this.userLibraryBooks.set(currentLibraryBooks);

        this.filteredLibraryBooks.set(currentLibraryBooks);
        this.setSelectedLibraryBookGenres();
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    })
  }

  private setSelectedLibraryBookGenres(): void {
    let genres = new Set<string>();

    const books = this.userLibraryBooks();
    books.forEach((book) => {
      const bookGenre = book.genre.toLowerCase();
      genres.add(bookGenre);
    });

    this.selectedLibraryGenres.set(Array.from(genres).sort((a, b) => a > b ? 1 : -1));
  }

  private filterLibraryBooks(): void {
    this.filterLibraryBooksRequested.set(true);

    const filteredLibraryBooks = new Array<LibraryBook>();
    const filterText = this.filterText();
    const filterGenre = this.filterGenre();

    const filteringCleared = this.bookFilterIsEmpty(filterText, filterGenre);
    if (filteringCleared) {
      this.filteredLibraryBooks.set(this.userLibraryBooks());
      this.filterLibraryBooksRequested.set(false);

      return;
    }

    this.userLibraryBooks().forEach((book) => {
      if (this.bookMatchesFilter(book, filterText, filterGenre)) {
        filteredLibraryBooks.push(book);
      }
    });

    this.filteredLibraryBooks.set(filteredLibraryBooks);
  }

  private filterNewLibraryBook(book: LibraryBook): void {
    if (!this.filterLibraryBooksRequested()) return;

    const filterText = this.filterText();
    const filterGenre = this.filterGenre();

    const filteringCleared = this.bookFilterIsEmpty(filterText, filterGenre);
    if (filteringCleared || this.bookMatchesFilter(book, filterText, filterGenre)) {
      this.filteredLibraryBooks.mutate((books) => books.push(book));
    }
  }

  private filterUpdatedLibraryBook(book: LibraryBook): void {
    if (!this.filterLibraryBooksRequested()) return;

    const filterText = this.filterText();
    const filterGenre = this.filterGenre();

    const filteringCleared = this.bookFilterIsEmpty(filterText, filterGenre);
    if (filteringCleared) return;

    if (!this.bookMatchesFilter(book, filterText, filterGenre)) {
      this.filteredLibraryBooks.mutate((books) => {
        const bookIndex = books.indexOf(book);
        if (bookIndex < 0) return;

        books.splice(bookIndex, 1);
      });
    }
  }

  private triggerNextUserNumber(): void {
    const messageTitle = "Get Library User Number";
    const errorMessage = "An error occurred preventing getting the next library user number. \r\nIf this error persist, contact the web administrator.";

    this.libraryUserService.getNextUserNumber(this.librarySecurityService.GetClientSessionId()).subscribe({
      next: (number: number) => {
        this.nextUserNumber.set(number);
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }
    })
  }

  private bookFilterIsEmpty(filterText: string, filterGenre: string): boolean {
    const hasTextFilter = this.hasContent(filterText);
    const hasGenreFilter = this.hasContent(filterGenre);

    return !(hasTextFilter || hasGenreFilter);
  }

  private bookMatchesFilter(book: LibraryBook, filterText: string, filterGenre: string): boolean {

    if (this.hasContent(filterText)) {
      const actualSearchText = filterText.toUpperCase();
      if (book.title.toUpperCase().indexOf(actualSearchText) >= 0) {
        return true;
      }

      if (book.genre.toUpperCase().indexOf(actualSearchText) >= 0) {
        return true;
      }

      if (book.author.toUpperCase().indexOf(actualSearchText) >= 0) {
        return true;
      }

      if (book.publisher.toUpperCase().indexOf(actualSearchText) >= 0) {
        return true;
      }

      if (book.reviews.toUpperCase().indexOf(actualSearchText) >= 0) {
        return true;
      }
    }

    if (this.hasContent(filterGenre)) {
      return book.genre.toUpperCase() === filterGenre.toUpperCase();
    }

    return false;
  }

  private hasContent(text: string): boolean {
    return (text !== undefined && text !== null && text.length > 0);
  }

  private resetSelectedLibraryState() {
    this.filteredLibraryBooks.set([]);
    this.userLibraryBooks.set([]);
    this.selectedLibraryGenres.set([]);
  }

  private resetUserLibrariesState() {
    this.selectedUserLibrary.set(UserLibrary.init());
    this.userLibrarySelected.set(false);
    this.userLibraries.set([]);
  }

  private resetLibraryUserState() {
    this.signedInUser.set(LibraryUser.init());
    this.userSecurityPhrases.set(SecurityPhrases.init());
    this.userSignInName.set("");
    this.userSignedIn.set(false);
  }

  private rollbackCurrentAction(): void {
    if (this.actionStack.length === 0) return;

    while (this.actionStack.length > 0) {
      var func = this.actionStack.pop();
      if (func === undefined || func === null) continue;

      func();
    }
  }
}
