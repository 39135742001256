import { Component } from '@angular/core';
import { TempLibraryService } from '../../services/temp-library.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-temp-security-phrases',
  templateUrl: './temp-security-phrases.component.html',
  styleUrls: ['./temp-security-phrases.component.scss']
})
export class TempSecurityPhrasesComponent {

  securityPhrase1DisplayType = "password";
  securityPhrase2DisplayType = "password";
  securityPhrase3DisplayType = "password";

  securityPhrasesForm = new UntypedFormGroup({
    securityPhrases1: new UntypedFormControl(''),
    securityPhrases2: new UntypedFormControl(''),
    securityPhrases3: new UntypedFormControl(''),
  });

  constructor(tempLibraryService: TempLibraryService) {

    this.securityPhrasesForm.controls["securityPhrases1"].setValue(tempLibraryService.userSecurityPhrases().level1);
    this.securityPhrasesForm.controls["securityPhrases2"].setValue(tempLibraryService.userSecurityPhrases().level2);
    this.securityPhrasesForm.controls["securityPhrases3"].setValue(tempLibraryService.userSecurityPhrases().level3);
  }

  toggleSecurityPhrase1InputType(): void { this.securityPhrase1DisplayType = Util.togglePasswordFieldDisplay(this.securityPhrase1DisplayType); }
  toggleSecurityPhrase2InputType(): void { this.securityPhrase2DisplayType = Util.togglePasswordFieldDisplay(this.securityPhrase2DisplayType); }
  toggleSecurityPhrase3InputType(): void { this.securityPhrase3DisplayType = Util.togglePasswordFieldDisplay(this.securityPhrase3DisplayType); }
}
