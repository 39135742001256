import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { UserLibrarySecurityService } from '../../../security/user-library-security.service';
import { HttpClientErrorHandler } from './http-client-error-handler.service';

import { SendUserEmailConfirmationMessage } from '../../models/send-user-email-confirmation-message.model';
import { EmailConfirmationMessage } from "../../models/email-confirmation-message.model";

import { LibraryUser } from '../../models/library-user.model';

@Injectable()
export class UserEmailConfirmationHttpClientService {
  constructor(
    private httpClient: HttpClient,
    private httpClientErrorHandlerService: HttpClientErrorHandler,
    private librarySecurityService: UserLibrarySecurityService) {
  }

  requestUserEmailConfirmation(user: LibraryUser, confirmationCode: string): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('sid', `${this.librarySecurityService.GetMailServiceId()}`)
        .set('usr', `${this.librarySecurityService.EncryptValue(user.userName)}`)
        .set('uid', `${this.librarySecurityService.EncryptValue(user.id)}`)
    };

    const message = new SendUserEmailConfirmationMessage();

    message.code = confirmationCode;
    message.messageBody = `<html><head><title>User email confirmation</title><style> .btn { -webkit-border-radius: 4px; -moz-border-radius: 4px; margin: 3px; border-radius: 4px; border: solid 1px #20538D; text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4); -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2); -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2); box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2); background: #4479BA; color: #FFF; padding: 8px 12px; text-decoration: none; } </style></head><body><h2>Email confirmation message</h2><p><span>Hello ${user.firstName} ${user.lastName}, </span><br><span>this message is provided as a way to confirm your new library account.</span><br><br><span>Click on the confirmation button below to complete your user registration:</span><br></p><a class=\"btn\" href=\"${environment.baseUrl}/home/email-confirmation?code=${confirmationCode}\" target=\"_blank\"> Confirm </a></body></html>`;
    message.isHtmlFormatted = true;

    return this.httpClient.post(`${environment.apiUrl}/Lib/UserEmailConfirmation/Send`, message, httpOptions)
      .pipe(
        map((response: any): any => { return response; }),
        catchError(error => this.httpClientErrorHandlerService.handleError('UserEmailConfirmation Service', error))
      );
  }

  confirmUserEmail(userName: string, confirmationCode: string): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.CreateClientSessionId()}` })
      .set('sid', `${this.librarySecurityService.GetMailServiceId()}`)
      .set('usr', `${this.librarySecurityService.EncryptValue(userName)}`)
    };

    const message = new EmailConfirmationMessage();
    message.code = confirmationCode;

    return this.httpClient.post(`${environment.apiUrl}/Lib/UserEmailConfirmation/Confirm`, message, httpOptions)
      .pipe(
        map((response: any): any => { return response; }),
        catchError(error => this.httpClientErrorHandlerService.handleError('UserEmailConfirmation Service', error))
      );
  }
}
