import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { UserLibrary } from '../../models/user-library.model';
import { LibraryService } from '../../services/library.service';

@Component({
  selector: 'app-library-creator',
  templateUrl: './library-creator.component.html',
  styleUrls: ['./library-creator.component.scss']
})
export class LibraryCreatorComponent {

  constructor(private libraryService: LibraryService) { }

  libraryForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(7)]),
    description: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
  });

  saveLibrary(): void {
    let newLibrary = UserLibrary.init();
    newLibrary.location = this.libraryService.nextLibraryLocation();
    newLibrary.name = this.libraryForm.controls["name"].value;
    newLibrary.description = this.libraryForm.controls["description"].value;

    this.libraryService.saveNewUserLibrary(newLibrary);
  }

  cancelEdit(): void {
    this.libraryService.cancelNewUserLibrary();
  }
}
