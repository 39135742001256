import { Router } from '@angular/router';
import { Component, WritableSignal, effect } from '@angular/core';

import { LibraryService } from '../services/library.service';
import { BusyAsyncRequestExecutingService } from 'src/app/services/busy-async-request-executing.service';
import { SecurityPhrases } from 'src/app/security/security-phrases/security-phrases.model';
import { TempLibraryService } from '../services/temp-library.service';
import { TempLibraryUser } from '../models/temp-library-user.model';
import { TempUserLibrary } from '../models/temp-user-library.model';
import { TempLibraryBook } from '../models/temp-library-book.model';

@Component({
  selector: 'app-update-security-phrases',
  templateUrl: './update-security-phrases.component.html',
  styleUrls: ['./update-security-phrases.component.scss']
})
export class UpdateSecurityPhrasesComponent {

  userSignedIn: WritableSignal<boolean>;
  securityPhrases: SecurityPhrases = new SecurityPhrases();
  hasInvalidSecurityPhrases: boolean = true;

  hasTempClientSessionInfo: WritableSignal<boolean>;
  hasValidTempSecurityPhrases: WritableSignal<boolean>;

  hasTempUser: WritableSignal<boolean>;
  tempUserConfirmed: WritableSignal<boolean>;
  tempUser: WritableSignal<TempLibraryUser>;

  hasTempLibraries: WritableSignal<boolean>;
  allTempLibrariesConfirmed: WritableSignal<boolean>;
  tempLibraries: WritableSignal<Array<TempUserLibrary>>;

  hasCurrentTempLibrary: WritableSignal<boolean>;
  currentTempLibrary: WritableSignal<TempUserLibrary>;

  hasTempLibraryBooks: WritableSignal<boolean>;
  tempLibraryBooks: WritableSignal<Array<TempLibraryBook>>;

  selectedLibrary: TempUserLibrary | undefined;

  constructor(
    public busyAsyncRequestExecutingService: BusyAsyncRequestExecutingService,
    private router: Router,
    private libraryService: LibraryService,
    private tempLibraryService: TempLibraryService) {
    this.userSignedIn = this.libraryService.userSignedIn;

    this.hasTempClientSessionInfo = this.tempLibraryService.hasTempClientSessionInfo;
    this.hasValidTempSecurityPhrases = this.tempLibraryService.hasValidTempSecurityPhrases;

    this.hasTempUser = this.tempLibraryService.hasTempUser;
    this.tempUserConfirmed = this.tempLibraryService.tempUserConfirmed;
    this.tempUser = this.tempLibraryService.tempLibraryUser;

    this.hasTempLibraries = this.tempLibraryService.hasTempLibraries;
    this.allTempLibrariesConfirmed = this.tempLibraryService.allTempLibrariesConfirmed;
    this.tempLibraries = this.tempLibraryService.tempUserLibraries;

    this.hasCurrentTempLibrary = this.tempLibraryService.hasCurrentTempLibrary;
    this.currentTempLibrary = this.tempLibraryService.currentTempLibrary;

    this.hasTempLibraryBooks = this.tempLibraryService.hasTempLibraryBooks;
    this.tempLibraryBooks = this.tempLibraryService.tempLibraryBooks;

    effect(() => {
      var accepted = this.tempLibraryService.hasAcceptUpdateCompleted();
      if (accepted) {
        this.router.navigate(['/library'])
          .then(() => { window.location.reload(); });
      }
    });

    effect(() => {
      var cancelled = this.tempLibraryService.hasCancelUpdateCompleted();
      if (cancelled) {
        this.router.navigate(['/library'])
          .then(() => { window.location.reload(); });
      }
    });
  }

  isUserSignedIn(): boolean {
    return this.userSignedIn();
  }

  onUserSecurityPhrasesChange(securityPhrases: SecurityPhrases): void {
    this.securityPhrases = securityPhrases;

    this.hasInvalidSecurityPhrases = !this.securityPhrases.isValid();
  }

  refreshLibrary(): void {
    if (this.selectedLibrary == undefined) return;

    this.tempLibraryService.SetCurrentTempUserLibrary(this.selectedLibrary);
  }

  startSecurityPhraseUpdate(): void {
    this.tempLibraryService.StartUpdate();
  }

  setUpdateSecurityPhrases(): void {
    this.tempLibraryService.SetSecurityPhrases(this.securityPhrases);
  }

  cancelUpdateSecurityPhrases(): void {
    this.securityPhrases = new SecurityPhrases();
    this.hasInvalidSecurityPhrases = !this.securityPhrases.isValid();
  }

  AcceptUpdate(): void {
    this.tempLibraryService.AcceptUpdate();
  }

  CancelUpdate(): void {
    this.tempLibraryService.CancelUpdate();
  }
}
