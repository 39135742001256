<div class="projected-title container">
  <h2>Library user email confirmation</h2>

  <form [formGroup]="confirmationForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-sm-3">
        <label for="userName">User name: </label>
        <input required id="userName" type="input" formControlName="userName" [ngModel]="userName">

        <input required id="confirmationCode" type="hidden" formControlName="confirmationCode" [ngModel]="code">
      </div>

      <div class="col-sm-3 justify-content-center ">
        <button class="btn btn-primary btn-sm" type="submit" tabindex="99" [disabled]="!confirmationForm.valid">
          Submit</button>
      </div>
    </div>
  </form>
</div>
