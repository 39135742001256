import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { Util } from 'src/app/utils/utils';
import { HttpClientErrorHandler } from './http-client-error-handler.service';
import { UserLibrarySecurityService } from '../../../security/user-library-security.service';

import { RecordIdInfo } from '../../models/record-id-info.model';
import { UpdateRecordIdInfo } from '../../models/record-id-Info-update.model';
import { UserLibraryDto } from '../interfaces/user-library-dto.interface';
import {
  NewUserLibraryRevertedDto,
  UpdateUserLibraryRevertedDto,
  UserLibraryRevertedDto
} from '../interfaces/user-library-reverted-dto.interface';

@Injectable()
export class UserLibraryHttpClientService {

  constructor(
    private httpClient: HttpClient,
    private httpClientErrorHandlerService: HttpClientErrorHandler,
    private librarySecurityService: UserLibrarySecurityService) {
  }

  getLibraries(csi: string, userId: string): Observable<Array<UserLibraryDto>> {
    if (csi.length === 0 || userId.length === 0 || userId === Util.emptyGuid) return of(new Array<UserLibraryDto>());

    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${csi}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
    };

    return this.httpClient.get<UserLibraryDto[]>(`${environment.apiUrl}/Lib/UserLibraries/All`, httpOptions)
      .pipe(
        catchError(error => this.httpClientErrorHandlerService.handleError('UserLibrary Service', JSON.stringify(error)))
      );
  }

  addNewLibrary(userId: string, userLibrary: NewUserLibraryRevertedDto): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
    };

    return this.httpClient.post(`${environment.apiUrl}/Lib/UserLibraries/New`, userLibrary, httpOptions).pipe(
      map((response: any): any => { return response; }),
      map((libraryIdInfo: RecordIdInfo): string => { return this.librarySecurityService.DecryptValue(libraryIdInfo.idInfo); }),
      catchError(error => this.httpClientErrorHandlerService.handleError('UserLibrary Service', error))
    );
  }

  resetLibrary(userId: string, userLibraryId: string, userLibrary: UserLibraryRevertedDto): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(userLibraryId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/UserLibraries/Reset`, userLibrary, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('UserLibrary Service', error))
    );
  }

  updateLibrary(userId: string, userLibraryId: string, userLibrary: UpdateUserLibraryRevertedDto): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(userLibraryId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/UserLibraries/Update`, userLibrary, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('UserLibrary Service', error))
    );
  }

  updateLibraryInfo(userId: string, userLibraryId: string, libraryInfoUpdate: UpdateRecordIdInfo): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(userLibraryId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/UserLibraries/Info`, libraryInfoUpdate, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('UserLibrary Service', error))
    );
  }

  deleteLibrary(userId: string, userLibraryId: string): Observable<string> {
    let httpOptions = {
      headers: new HttpHeaders({ 'csi': `${this.librarySecurityService.GetClientSessionId()}` })
        .set('uid', `${this.librarySecurityService.EncryptValue(userId)}`)
        .set('ulid', `${this.librarySecurityService.EncryptValue(userLibraryId)}`)
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/Lib/UserLibraries/Delete`, null, httpOptions).pipe(
      catchError(error => this.httpClientErrorHandlerService.handleError('UserLibrary Service', error))
    );
  }
}
