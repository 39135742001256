<form [formGroup]="libraryUserForm" (ngSubmit)="updateEditUser()">

  <fieldset class="d-flex flex-column">
    <legend>Update Library User</legend>

    <div class="row">
      <div class="col-sm-6">
        <label for="newUserName">User name: </label>
        <input class="fixWidthMedium" type="text" placeholder="UserName" tabindex="1" formControlName="userName" disable
          autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label for="newUserFirstName">First name: </label>
        <input class="fixWidthMedium" type="text" placeholder="First name" tabindex="2" formControlName="firstName"
          autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label for="newUserLastName">Last name: </label>
        <input class="fixWidthMedium" type="text" placeholder="Last name" tabindex="3" formControlName="lastName"
          autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label for="newUserEmail">eMail: </label>
        <input class="fixWidthMedium" type="email" placeholder="Email" tabindex="4" formControlName="email" disable
          autocomplete="off" />
      </div>
    </div>

    <div class="row">

      <div class="col-sm-3">
        <button class="btn btn-sm btn-outline-success" aria-label="Save user" type="submit"
          [disabled]="!libraryUserForm.valid || !libraryUserForm.dirty" tabindex="91">
          <i class="bi bi-save2"></i>
        </button>
      </div>

      <div class="col-sm-3">
        <button class="btn btn-sm btn-outline-warning" aria-label="Cancel" type="button" (click)="cancelEditUser()"
          tabindex="92">
          <i class="bi bi-x-square-fill"></i>
        </button>
      </div>

    </div>

  </fieldset>

</form>
