import { Component, WritableSignal } from '@angular/core';

import { LibraryService } from '../services/library.service';
import { LibraryBook } from '../models/library-book.model';

@Component({
  selector: 'app-library-books',
  templateUrl: './library-books.component.html',
  styleUrls: ['./library-books.component.scss']
})
export class LibraryBooksComponent {

  constructor(private libraryService: LibraryService) {
    this.books = this.libraryService.filteredLibraryBooks;
  }

  books: WritableSignal<Array<LibraryBook>>;

  updateLibraryBook($event: LibraryBook): void {
    this.libraryService.updateLibraryBook($event);
  }

  deleteBook($event: LibraryBook): void {
    this.libraryService.deleteLibraryBook($event);
  }

  updateLibraryBookEditors($event: LibraryBook): void {
    this.libraryService.updateLibraryBookEditors($event);
  }
}
