import { Component, Input } from '@angular/core';
import { TempLibraryBook } from '../../models/temp-library-book.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-temp-library-book',
  templateUrl: './temp-library-book.component.html',
  styleUrls: ['./temp-library-book.component.scss']
})
export class TempLibraryBookComponent {
  _index: number = -1;
  _book: TempLibraryBook = TempLibraryBook.init();

  libraryBookForm = new UntypedFormGroup({
    title: new UntypedFormControl(''),
    genre: new UntypedFormControl(''),
    author: new UntypedFormControl(''),
    publisher: new UntypedFormControl(''),
    editors: new UntypedFormControl(''),
    reviews: new UntypedFormControl('')
  });

  constructor() { }

  bookIndex: number = 0;

  get index(): number {
    return this._index;
  }
  @Input() set index(value: number) {
    this._index = value;

    this.bookIndex = value + 1;
  }

  get book(): TempLibraryBook {
    return this._book;
  }
  @Input() set book(book: TempLibraryBook) {
    this._book = book;

    this.libraryBookForm.controls['title'].setValue(this._book.title);
    this.libraryBookForm.controls['genre'].setValue(this._book.genre);
    this.libraryBookForm.controls['author'].setValue(this._book.author);
    this.libraryBookForm.controls['publisher'].setValue(this._book.publisher);
    this.libraryBookForm.controls['editors'].setValue(this._book.editors);
    this.libraryBookForm.controls['reviews'].setValue(this._book.reviews);
  }
}
