import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { LibraryUser } from '../../models/library-user.model';

@Component({
  selector: 'app-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss']
})
export class UserEditorComponent {
  private _user: LibraryUser = LibraryUser.init();

  constructor() {
    this.libraryUserForm.controls['userName'].disable();
    this.libraryUserForm.controls['email'].disable();
  }

  libraryUserForm = new UntypedFormGroup({
    userName: new UntypedFormControl(''),
    firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    email: new UntypedFormControl('')
  });

  get user(): LibraryUser {
    return this._user;
  }
  @Input() set user(user: LibraryUser) {
    this._user = user;
    this.updateLibraryUserForm();
  }

  @Output() onChanged = new EventEmitter<LibraryUser>();
  @Output() onCancellation = new EventEmitter();

  updateEditUser(): void {
    this.updateLibraryUserDetails();

    this.onChanged.emit(this._user);
  }

  cancelEditUser(): void {
    this.onCancellation.emit();
  }

  private updateLibraryUserDetails() {
    this._user.firstName = this.libraryUserForm.controls["firstName"].value;
    this._user.lastName = this.libraryUserForm.controls["lastName"].value;
  }

  private updateLibraryUserForm() {
    this.libraryUserForm.controls["userName"].setValue(this._user.userName);
    this.libraryUserForm.controls["firstName"].setValue(this._user.firstName);
    this.libraryUserForm.controls["lastName"].setValue(this._user.lastName);
    this.libraryUserForm.controls["email"].setValue(this._user.email);
  }
}
