<div *ngIf="isLoaded" class="container">
  <div class="content">
    <div class="row">
      <div class="col-sm-8">
        <div class="row">

          <div class="col-sm-5">
            <img src="/assets/images/author_photo.jpg" height="185" alt="content image" />
          </div>

          <div class="col-sm-7 statement-text">
            <p>{{statement}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <h2>Hobbies / Passions</h2>
          </div>
        </div>

        <div class="row">
          <div *ngFor="let hobby of hobbies" class="col-sm-6">
            <h3>{{hobby.name}}</h3>
            <p>{{hobby.description}}</p>
          </div>
        </div>
      </div>

      <div *ngIf="blogs" class="col-sm-4 align-self-start">
        <div *ngFor="let item of blogs" class="sidebar">
          <div class="sidebar_item">
            <h2>{{item.title}}</h2>
            <h3>{{item.publishedOn | date:'MMM yyyy'}}</h3>
            <p>{{item.message}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
