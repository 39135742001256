import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { WorkExperience } from 'src/app/work-experience/work-experience.model';

@Injectable()
export class WorkExperiencesService {

  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<WorkExperience[]> {
    return this.httpClient.get(`${environment.apiUrl}/WorkExperiences`).pipe(
      map((response: any): any => { return response; }),
      map((workExperiences: WorkExperience[]): WorkExperience[] => {
        workExperiences.sort((a, b) => a.startDate < b.startDate ? 1 : -1)
        return workExperiences;
      }),);
  }
}
