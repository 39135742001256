import { Component, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SecurityPhrases } from 'src/app/security/security-phrases/security-phrases.model';

@Component({
  selector: 'app-security-phrases',
  templateUrl: './security-phrases.component.html',
  styleUrls: ['./security-phrases.component.scss']
})
export class SecurityPhrasesComponent {

  private _securityPhrases = new SecurityPhrases();
  private _level1InputElement!: ElementRef<HTMLInputElement>;

  constructor() {
    this.securityPhrasesForm.controls["level1"].valueChanges.subscribe({ next: (_) => this.level1Set()});
    this.securityPhrasesForm.controls["level2"].valueChanges.subscribe({ next: (_) => this.level2Set()});
    this.securityPhrasesForm.controls["level3"].valueChanges.subscribe({ next: (_) => this.level3Set()});
  }

  get securityPhrases(): SecurityPhrases {
    return this._securityPhrases;
  }
  @Input() set securityPhrases(value: SecurityPhrases) {
    this._securityPhrases = value.isValid()
        ? value
        : new SecurityPhrases();

    this.securityPhrasesForm.controls["level1"].setValue(this._securityPhrases.level1);
    this.securityPhrasesForm.controls["level2"].setValue(this._securityPhrases.level2);
    this.securityPhrasesForm.controls["level3"].setValue(this._securityPhrases.level3);
  }

  @Output() onChange = new EventEmitter<SecurityPhrases>();

  @ViewChild('level1Input') set inputElRef(elRef: ElementRef<HTMLInputElement>) {
    if (elRef) {
      this._level1InputElement = elRef;
      this._level1InputElement.nativeElement.focus();
    }
  }

  securityPhrasesForm = new UntypedFormGroup({
    level1: new UntypedFormControl('', [Validators.required, Validators.minLength(7)]),
    level2: new UntypedFormControl('', [Validators.required, Validators.minLength(7)]),
    level3: new UntypedFormControl('', [Validators.required, Validators.minLength(7)]),
  });

  level1Set() {
    this._securityPhrases.level1 = this.securityPhrasesForm.controls["level1"].value;
    this.submit();
  }

  level2Set() {
    this._securityPhrases.level2 = this.securityPhrasesForm.controls["level2"].value;
    this.submit();
  }

  level3Set() {
    this._securityPhrases.level3 = this.securityPhrasesForm.controls["level3"].value;
    this.submit();
  }

  //TODO: should add debounce to the changes made to the fields so that this is not called for every
  //      keystroke once the minimum required length is reached by all fields
  submit() {
    if (!this._securityPhrases.isValid() || !this.securityPhrasesForm.valid) return;

    this.onChange.emit(this._securityPhrases);
  }
}
