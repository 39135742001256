import { Util } from "src/app/utils/utils";

export class LibraryBook {
  id: string = Util.emptyGuid;

  index: number = -1;
  title: string = '';
  genre: string = '';
  author: string = '';
  publisher: string = '';
  isNewPublisher: boolean = false;
  publishedOn: Date = new Date(Date.now());
  editors: string = '';
  reviews: string = '';
  isArchived: boolean = false;

  createdOn: Date = new Date(Date.now());
  updatedOn: Date = new Date(Date.now());

  displayType: string = 'password';
  isEditable: boolean = false;
  isExpanded: boolean = false;

  public addEditor(editor: string): void {
    this.editors = editor + '\r\n' + this.editors;
  }

  public isNewBook(): boolean {
    return this.id === Util.emptyGuid;
  }

  public isValid(): boolean {
    return (this.id != "" && this.id != Util.emptyGuid)
      && Util.isValidLibraryFieldValue(this.title)
      && Util.isValidLibraryFieldValue(this.genre)
      && Util.isValidLibraryFieldValue(this.author)
      && Util.isValidLibraryFieldValue(this.publisher);
  }

  static init(): LibraryBook {
    return LibraryBook.create(-1);
  }

  //TODO: Is this really necessary?
  static getLabels() {
    return { id: 'Id', title: 'Title', genre: 'Genre', author: 'Author', publisher: 'Publisher', editors: 'Editors', reviews: 'Reviews' };
  }

  private static create(index: number): LibraryBook {
    let book = new LibraryBook();
    book.index = index;

    return book;
  }
}
