import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { BusyAsyncRequestExecutingService } from "./busy-async-request-executing.service";

@Injectable()
export class BusyAsyncRequestInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private busyAsyncRequestExecutingService: BusyAsyncRequestExecutingService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    this.requests.splice(i, 1);

    this.busyAsyncRequestExecutingService.executingAsyncRequest.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);

    this.busyAsyncRequestExecutingService.executingAsyncRequest.next(true);
    return new Observable((observer: any) => {
     next.handle(req).subscribe({
          next: (event) => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          error: (err) => { this.removeRequest(req); observer.error(err); }})
        .add(() => { this.removeRequest(req); observer.complete(); });
   });
  }
}
