<form [formGroup]="libraryUserForm" class="form-inline">

  <fieldset class="border rounded-3 p-3">
    <legend class="legend-sm float-none w-auto px-3">Update Library User</legend>

    <div class="row">
      <label class="col-sm-3 col-form-label" for="userName">User name: </label>
      <div class="col-sm-9">
        <input id="userName" type="text" placeholder="UserName" tabindex="1" formControlName="userName" disable readonly
          autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <label class="col-sm-3 col-form-label" for="firstName">First name: </label>
      <div class="col-sm-9">
        <input id="firstName" type="text" placeholder="First name" tabindex="2" formControlName="firstName"
          disable readonly autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <label class="col-sm-3 col-form-label" for="lastName">Last name: </label>
      <div class="col-sm-9">
        <input id="lastName" type="text" placeholder="Last name" tabindex="3" formControlName="lastName"
          disable readonly autocomplete="off" />
      </div>
    </div>

    <div class="row">
      <label class="col-sm-3 col-form-label" for="email">eMail: </label>
      <div class="col-sm-9">
        <input id="email" type="email" placeholder="Email" tabindex="4" formControlName="email" disable
          readonly autocomplete="off" />
      </div>
    </div>

  </fieldset>

</form>
