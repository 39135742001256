import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { LibraryBookComponent } from './library/library-book/library-book.component';
import { ContactComponent } from './contact/contact.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { EmailConfirmationComponent } from './library/user-email-confirmation/user-email-confirmation.component';
import { ProjectsComponent } from './projects/projects.component';
import { SecurityPhrasesComponent } from './security/security-phrases/security-phrases.component';
import { WorkExperienceComponent } from './work-experience/work-experience.component';

import { BlogsService } from './blogs/blogs.service';
import { LibraryTranslationService } from './library/services/library-translation.service';
import { LibraryUserHttpClientService } from './library/services/http-client-services/library-user.http-client-service';
import { ProjectsService } from './projects/projects.service';
import { UserEmailConfirmationHttpClientService } from './library/services/http-client-services/user-email-confirmation.http-client-service';
import { LibraryBookHttpClientService } from './library/services/http-client-services/library-book.http-client-service';
import { UserLibraryHttpClientService } from "./library/services/http-client-services/user-library.http-client-service";
import { LibraryClientSessionHttpClientService } from "./library/services/http-client-services/library-client-session.http-client-service";
import { WorkExperiencesService } from './work-experience/work-experiences.service';
import { ContactMailService } from './contact/contact-mail.service';
import { HobbyService } from './about-me/hobby.service';
import { LibraryReversionService } from './library/services/library-reversion.service';

import { LibraryUserComponent } from './library/library-user/library-user.component';
import { UserLibraryComponent } from './library/user-library/user-library.component';
import { UserLibrariesComponent } from './library/user-libraries/user-libraries.component';
import { UserLibraryControlComponent } from './library/user-library-control/user-library-control.component';
import { LibraryBooksComponent } from './library/library-books/library-books.component';
import { TheLibraryComponent } from './library/the-library/the-library.component';
import { UserSigninComponent } from './library/library-user/user-signin/user-signin.component';
import { UserCreatorComponent } from './library/library-user/user-creator/user-creator.component';
import { UserEditorComponent } from './library/library-user/user-editor/user-editor.component';
import { LibraryCreatorComponent } from './library/user-library/library-creator/library-creator.component';
import { LibraryEditorComponent } from './library/user-library/library-editor/library-editor.component';
import { BookListItemComponent } from './library/library-book/book-list-item/book-list-item.component';
import { BookCreatorComponent } from './library/library-book/book-creator/book-creator.component';
import { BookEditorComponent } from './library/library-book/book-editor/book-editor.component';
import { UserListItemComponent } from './library/library-user/user-list-item/user-list-item.component';
import { LibraryListItemComponent } from './library/user-library/library-list-item/library-list-item.component';
import { RootComponent } from './root/root.component';
import { MenuComponent } from './root/menu/menu.component';
import { BusyAsyncRequestExecutingService } from './services/busy-async-request-executing.service';
import { HttpInterceptorProviders } from './http-Interceptors';
import { ProjectComponent } from './projects/project/project.component';
import { ConfirmationDialog } from './utils/confirmation-dialog/confirmation-dialog.component';
import { UpdateSecurityPhrasesComponent } from './library/update-security-phrases/update-security-phrases.component';
import { TestingComponent } from './security/testing/testing.component';
import { TempLibraryBookComponent } from './library/update-security-phrases/temp-library-book/temp-library-book.component';
import { TempLibraryUserComponent } from './library/update-security-phrases/temp-library-user/temp-library-user.component';
import { TempUserLibraryComponent } from './library/update-security-phrases/temp-user-library/temp-user-library.component';
import { TempSecurityPhrasesComponent } from './library/update-security-phrases/temp-security-phrases/temp-security-phrases.component';
import { TempLibraryUpdateSecurityPhrasesHttpClientService } from './library/services/http-client-services/temp-library-update-security-phrases.http-client-service';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    AboutMeComponent,
    LibraryBookComponent,
    ContactComponent,
    DashBoardComponent,
    EmailConfirmationComponent,
    ProjectsComponent,
    SecurityPhrasesComponent,
    WorkExperienceComponent,
    LibraryUserComponent,
    UserLibraryComponent,
    UserLibrariesComponent,
    UserLibraryControlComponent,
    LibraryBooksComponent,
    TheLibraryComponent,
    UserSigninComponent,
    UserCreatorComponent,
    UserEditorComponent,
    LibraryCreatorComponent,
    LibraryEditorComponent,
    BookListItemComponent,
    BookCreatorComponent,
    BookEditorComponent,
    UserListItemComponent,
    LibraryListItemComponent,
    RootComponent,
    MenuComponent,
    ProjectComponent,
    ConfirmationDialog,
    UpdateSecurityPhrasesComponent,
    TestingComponent,
    TempLibraryBookComponent,
    TempLibraryUserComponent,
    TempUserLibraryComponent,
    TempSecurityPhrasesComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    ClipboardModule,
    RecaptchaV3Module
  ],
  providers: [
    HttpInterceptorProviders,
    CookieService,
    BusyAsyncRequestExecutingService,
    ContactMailService,
    ProjectsService,
    BlogsService,
    HobbyService,
    LibraryReversionService,
    LibraryTranslationService,
    WorkExperiencesService,
    LibraryClientSessionHttpClientService,
    UserEmailConfirmationHttpClientService,
    LibraryUserHttpClientService,
    UserLibraryHttpClientService,
    LibraryBookHttpClientService,
    TempLibraryUpdateSecurityPhrasesHttpClientService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
