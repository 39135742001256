import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { LibraryClientSessionHttpClientService } from '../services/http-client-services/library-client-session.http-client-service';
import { UserEmailConfirmationHttpClientService } from '../services/http-client-services/user-email-confirmation.http-client-service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserLibrarySecurityService } from 'src/app/security/user-library-security.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './user-email-confirmation.component.html',
  styleUrls: ['./user-email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {
  code: string = "";
  userName: string = "";
  isLoaded: boolean = false;

  //TODO: Both formcontrol and ngModel are used in the form where only 1 is needed! (use only the controlform one)
  confirmationForm = new UntypedFormGroup({
    userName: new UntypedFormControl('',),
    confirmationCode: new UntypedFormControl('')
  });

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private clientLibraryService: LibraryClientSessionHttpClientService,
    private librarySecurityService: UserLibrarySecurityService,
    private userEmailConfirmationService: UserEmailConfirmationHttpClientService) { }

  ngOnInit(): void {
    this.code = this.route.snapshot.queryParams['code'];
    this.clientLibraryService.createClientSession(this.librarySecurityService.CreateClientSessionId()).subscribe({
      next: (_) => {
        this.isLoaded = true;
      },
      error: (_) => {
        this.notificationService.showError('The client session could not be started.\r\nIf this issue persists, contact the web administrator.', 'Fatal Error');
      }});
  }

  submit(): void {
    const messageTitle = "User Email Confirmation";
    const successMessage = "Confirmation code submitted. \r\nReturn to the library to start checking out books.";
    const errorMessage = "An error occurred while attempting to confirm you email account. \r\nIf the error persists, contact the web administrator.";

    this.userEmailConfirmationService.confirmUserEmail(this.confirmationForm.controls['userName'].value, this.code).subscribe({
      next: () => {
        this.notificationService.showInfo(successMessage, messageTitle);
      },
      error: (_) => {
        this.notificationService.showError(errorMessage, messageTitle);
      }})
      .add(() => {
        this.code = '';
        this.confirmationForm.reset();
      });
  }
}
